import React, { useMemo } from 'react';

import {
    Card,
    CardBody,
} from "reactstrap"

import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import { useGetHostVolumes } from 'GalliumAPIHooks/HostVolume/HostVolumeHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import TableContainer from 'Components/Gallium/TableContainerNew';
import { VolumeAttached, VolumeName, VolumeSize, VolumeActions } from 'Components/Gallium/Volumes/VolumeColumns';


type HypervisorVolumesProps = {
  hypervisorDetail: HostDetail;
};

const HypervisorVolumes: React.FC<HypervisorVolumesProps> = ({hypervisorDetail}) => {
    const {t} = useTranslation()

    const {data, error, isLoading} = useGetHostVolumes(hypervisorDetail.slug)

    // Table Configuration
    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <VolumeName {...cellProps} />;
                },
            },
            
            {
                header: "Attached",
                accessorKey: "attached",
                enableColumnFilter: false,
                id: "attached",
                className: "col-3",
                cell: (cellProps) => {
                    return <VolumeAttached {...cellProps} />;
                },
            },
            {
                header: "Size",
                accessorKey: "sizeTotalMb",
                enableColumnFilter: false,
                // The ID is required when the output isnt a string and this is used for sorting as well
                id: "sizeTotalMb",
                className: "col-4",
                cell: (cellProps) => {
                    return <VolumeSize {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-1 text-end",
                cell: (cellProps) => {
                    return <VolumeActions {...cellProps} />;
                },
            },
            
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'sizeTotalMb',
                desc: true, 
            },
        ],[]
    );


    return (

        <GalliumPageColumn>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (

                <Card id="volumelist">
                    <CardBody className="pt-0">
                        
                        <TableContainer
                            columns={columns}
                            data={data?.volumes}
                            isGlobalFilter={true}
                            customPageSize={8}
                            sorting={sorting}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a Virtual Disk'
                        />
                    </CardBody>
                </Card>

            )}

        </GalliumPageColumn>
    )
};

export default HypervisorVolumes;