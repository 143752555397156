import React from 'react';

import {
    Button,
} from "reactstrap"

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useRunBackupProfileNow } from 'GalliumAPIHooks/Backup/BackupHooks';
import { toast } from 'react-toastify';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';

interface RunBackupProfileButtonProps {
    profileSlug: string
}

const RunBackupProfileButton: React.FC<RunBackupProfileButtonProps> = ({ profileSlug }) => {
    const { t } = useTranslation()
    const { trigger, isMutating } = useRunBackupProfileNow(profileSlug)


    const handleFail = () => {
        toast.error(t('virtualMachine.backup.RunBackupNowFailed'))
    }

    const handleSuccess = () => {
        toast.info(t('virtualMachine.backup.RunBackupNowSuccess'))
    }

    const handleButtonClick = () => {
        const options = {
            onError() {
                handleFail()
            },
            onSuccess() {
                handleSuccess()
            }
        }
        trigger(null, options);
    }

    if (isMutating) return (
        <GalliumSpinnerButton color="primary" />
    )

    return (
        <Button color="soft-primary" className='mb-0' onClick={(handleButtonClick)}>
            <FontAwesomeIcon icon={byPrefixAndName.fak["light-boxes-stacked-circle-play"]} className='ms-1 me-2'/>
            {t("backupProfiles.runNowButton")}
        </Button>
    )
};

export default RunBackupProfileButton;