import React from 'react';

import { Field } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';

const Basics = () => {

    return (
        <React.Fragment>
            <GalliumFormHeader icon="info" title="Identification">

                    <Field
                        name="name"
                        label="VM Name"
                        id="name"
                        width={4}
                        className="form-control"
                        placeholder="Name for this VM"
                        type="text"
                        component={GalliumInput}
                    />

                    <Field
                        name="description"
                        label="Description"
                        id="description"
                        className="form-control"
                        placeholder="Optional - Description of this VM"
                        type="textarea"
                        component={GalliumInput}
                    />                    

            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default Basics;
