import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import React, { SetStateAction, useState } from "react";
import { Button, Modal, ModalBody, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import { ConfigureVmDiskAction, GalliumApiErrorResponse, ReconfigureVmRequest, VirtualMachineDetail, VmDisk } from "generated";
import { useTranslation } from "react-i18next";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FormikProvider, useFormik } from "formik";

interface DetachModalProps {
    showModal: boolean,
    setShowModal: React.Dispatch<SetStateAction<boolean>>,
    disk: VmDisk,
    virtualMachine: VirtualMachineDetail
}

const DetachModal: React.FC<DetachModalProps> = ({ showModal, setShowModal, disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const closeModal = () => {
        detachDiskFormik.resetForm()
        setShowModal(false)
    }

    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleDetachDiskFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleDetachDiskSuccess = () => {
        closeModal()
    }

    const handleDetachDiskRequest = (requestObj: ReconfigureVmRequest) => {
        const options = {
            onError(error: object) {
                handleDetachDiskFail(error)
            },
            onSuccess() {
                handleDetachDiskSuccess()
            }
        }
        setErrorObject(undefined)
        
        trigger(requestObj, options);
    }

    const detachDiskFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
        },
        onSubmit: () => {
            const requestObj: ReconfigureVmRequest = {
                "disks": [
                    {
                        action: ConfigureVmDiskAction.DETACH,
                        diskSlug: disk.volumeSlug
                    }
                ]
            };
            handleDetachDiskRequest(requestObj);
        }
    });

    return (
        <FormikProvider value={detachDiskFormik}>
            <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-slash"]} className='mt-3 fs-36 text-danger'/>
                        <ErrorAlert errorObj={errorObject}/>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>{t('translation:virtualMachine:diskDetachTitle')}</h4>
                            <p className="text-muted mx-4 mb-0">
                                {t('translation:virtualMachine:diskDetachText')}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={closeModal}
                        >
                            {t('translation:common:cancel')}
                        </button>
                        <GalliumSubmitButton formik={detachDiskFormik} color="danger" spinner={isMutating}>
                            {t('translation:virtualMachine:diskDetachTitle')}
                        </GalliumSubmitButton>
                    </div>
                </ModalBody>
            </Modal>
        </FormikProvider>
    );
};

interface DiskDetachButtonProps {
    disk: VmDisk
    virtualMachine: VirtualMachineDetail
}

const DiskDetachButton: React.FC<DiskDetachButtonProps> = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    return (
        <React.Fragment>
            <DetachModal
                showModal={showModal}
                setShowModal={setShowModal}
                disk={disk}
                virtualMachine={virtualMachine}
            />
            {virtualMachine.availableActions.modifyHardware ?(
                <Button color="soft-danger" className='mt-1 btn-sm btn-block' onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-slash"]} className='me-1'/>
                    {t('translation:virtualMachine:diskDetach')}
                </Button>
            ):(
                <React.Fragment>
                    <Button color="soft-secondary" id={`expandDetachButton-${disk.volumeSlug}`} className='mt-1 btn-sm btn-block'>
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-slash"]} className='me-1'/>
                        {t('translation:virtualMachine:diskDetach')}
                    </Button>
                    <Tooltip
                        isOpen={isBtntooltipOpen}
                        placement="left"
                        target={`expandDetachButton-${disk.volumeSlug}`}
                        toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                        <div>{t('translation:common:warningActionUnavailable')}</div>
                    </Tooltip>
                </React.Fragment>
            )}


        </React.Fragment>
    )
};

export default DiskDetachButton;