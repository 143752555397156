//@ts-nocheck
// Issue caused 
import React, { useContext } from "react";
import { ModalContext } from "./MFAModal";
import { Button, ListGroup, ListGroupItem, ModalBody, ModalHeader } from "reactstrap";

import { Col } from 'reactstrap';
import WarningIcon from "assets/lordicon/warning.json"
import LordIcon from "Components/Gallium/LordIcon";

const RecoveryCodes = () => {
    const { response, setResponse, closeModal } = useContext(ModalContext);

    return (
        <React.Fragment>
            <ModalHeader className="p-3 bg-light" >
                Recovery Codes
            </ModalHeader>

            <ModalBody >
                <div className="modal-body text-center p-2">
                    <LordIcon icon={WarningIcon} />
                    <div className="mt-1 pt-1">
                        <h4>Action Required</h4>
                        <p className="text-muted"> Make sure you save these codes somewhere safe. They are not recoverable once you close this window.</p>
                    </div>
                </div>

                <ListGroup>
                    {response.codes.map((code, index) => (
                        <ListGroupItem key={index} className="text-muted"><code className="fs-14 ms-2">{code}</code></ListGroupItem>
                    ))}
                </ListGroup>

                <Col lg={12} className="text-end mt-3">
                    <Button color="warning" onClick={()=>{closeModal()}}>
                    Done
                    </Button>
                </Col>
                
            </ModalBody>
        </React.Fragment>
    );
};

export default RecoveryCodes