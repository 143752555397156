import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
    Card,
    CardBody,
    CardHeader
} from "reactstrap"

import { map, round, orderBy } from "lodash"

import { useGetHostMetrics } from 'GalliumAPIHooks/Host/HostHooks';
import { HostMetric } from 'generated';
import MetricChartCPU from './MetricsCPU';
import MetricChartMemory from './MetricsMemory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


const HypervisorMetrics = () => {
    const hypervisorId = useParams().id;
    const { data: hypervisorMetrics } = useGetHostMetrics(hypervisorId, 15000);

    // Transform Raw Data for CPU and Memory Chart on update
    const [sortedCPUData, setSortedCPUData] = useState([]);
    const [sortedMemData, setSortedMemData] = useState([]);
    const [chartCPUData, setChartCPUData] = useState([]);
    const [chartMemData, setChartMemData] = useState([]);
    

    useEffect(() => {
        // Handle CPU Metrics by first transforming them into the format we expect in the chart
        const transformCPUData = (rawCPUData: Array<HostMetric>) => {
            const transformedData = map(rawCPUData, (datapoint) => ({
                x: datapoint.timestamp,
                y: round(datapoint.value as number, 2)
            }));
            const sortedData = orderBy(transformedData, ['x'], ['asc']);
            return sortedData;
        };

        const transformedCPUData = transformCPUData(hypervisorMetrics?.load_1 || []);

        // Now filter out duplicate metric entries on each update
        const filteredCPUData = [...sortedCPUData, ...transformedCPUData].filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (v) => v.x === value.x && v.y === value.y
                )
        );

        setSortedCPUData(filteredCPUData)

        setChartCPUData(
            [{
                name: "CPU Load",
                data: filteredCPUData
            }]
        )

        // Handle memory metrics
        const transformMemData = (rawMemData: Array<HostMetric>) => {
            const transformedData = map(rawMemData, (datapoint) => ({
                x: datapoint.timestamp,
                y: round(datapoint.value as number / 1024, 0)
            }));
            const sortedData = orderBy(transformedData, ['x'], ['asc']);
            return sortedData;
        };

        const transformedMemData = transformMemData(hypervisorMetrics?.mem_used || []);

        // Now filter out duplicate metric entries on each update
        const filteredMemoryData = [...sortedMemData, ...transformedMemData].filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (v) => v.x === value.x && v.y === value.y
                )
        );

        setSortedMemData(filteredMemoryData);

        setChartMemData(
            [{
                name: "Memory Usage",
                data: filteredMemoryData
            }]
        )

    }, [hypervisorMetrics]);

    return (
        <React.Fragment>
            <Card className='mb-2'>
                <CardHeader className=''>
                    <h4 className="card-title mb-0">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["chart-line"]} className='fa-fw me-2'/>
                        Performance Metrics (Last Hour)
                    </h4>
                </CardHeader>
                <CardBody className='pt-1 p-0'>
                    <MetricChartCPU dataSeries={chartCPUData} />
                    <MetricChartMemory dataSeries={chartMemData} />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default HypervisorMetrics;