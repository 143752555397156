import React, { useState } from 'react';

import { Label, Card, CardBody } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, OrgInfoResponse, OrgUpdateRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useUpdateOrg } from 'GalliumAPIHooks/Org/OrgHooks';
import { useTranslation } from 'react-i18next';
import { LocalDataTimeString } from 'Components/Gallium/GalliumTimeHelpers';

interface SupportAccessFormProps {
  org: OrgInfoResponse;
}

const SupportAccessForm: React.FC<SupportAccessFormProps> = ({ org }) => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useUpdateOrg()

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleSupportAccessFail = (error: GalliumApiErrorResponse) => {
        addUserFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleSupportAccessSuccess = () => {
        toast.success("Updated")
    }

    const handleSupportAccessRequest = (values: OrgUpdateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleSupportAccessFail(error)
            },
            onSuccess() {
                handleSupportAccessSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addUserFormik = useFormik({
        initialValues: {
            supportAccessEnabled: org.supportPin ? true : false,
        },
        validationSchema: Yup.object({
            supportAccessEnabled: Yup.boolean()
        }),
        onSubmit: (values) => {
            handleSupportAccessRequest(values)
        },
    });



    return (    
        <FormikProvider value={addUserFormik}>
            <Card>
                <CardBody>
                    <ErrorAlert errorObj={errorObject} />
                    <GalliumFormHeader icon="help-circle" title={t("supportAccess.formTitle")}>
                        <div className="form-check form-switch form-switch-lg mb-2 mt-3" dir="ltr">
                            <Field
                                id="supportAccessEnabled" 
                                name="supportAccessEnabled" 
                                type="checkbox" 
                                className="form-check-input"  
                            />
                            <Label className="form-check-label" htmlFor="supportAccessEnabled">{t("supportAccess.action")}</Label>
                        </div>
                        <p className="text-muted">{t("supportAccess.explanation")}</p>
                    </GalliumFormHeader>
                    {org.supportPin ? (
                        <GalliumFormHeader icon="key" title={t("supportAccess.formTitle")}>
                            <p className='mb-1'>{t("supportAccess.supportPin")}<code>{org.supportPin}</code></p>
                            <p>{t("supportAccess.supportPinExpires")}{LocalDataTimeString(org.supportPinExpiry)}</p>
                        </GalliumFormHeader>
                    ):(null)}
                    <GalliumSubmitButton formik={addUserFormik} spinner={isMutating} color="success" className="float-end">
                        {t("common.update")}
                    </GalliumSubmitButton>

                </CardBody>
            </Card>

        </FormikProvider>
    );
};

export default SupportAccessForm;