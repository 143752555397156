import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";

import { useImportSSHKey } from 'GalliumAPIHooks/SSH/SSHHooks';
import { toast } from 'react-toastify';
import { GalliumApiErrorResponse } from 'generated';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';

const ImportSSHKeys = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useImportSSHKey()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleAddSSHKeyFail = (error: GalliumApiErrorResponse) => {
        importKeysFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddSSHKeySuccess = () => {
        toast.success("SSH Keys Imported")
        navigate("/inventory/sshkeys");
    }

    const handleAddSSHKeyRequest = (gh_username: string) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddSSHKeyFail(error)
            },
            onSuccess() {
                handleAddSSHKeySuccess()
            }
        }
        setErrorObject(undefined)
        trigger(gh_username, options);
    }

    const importKeysFormik = useFormik({
        initialValues: {
            gh_username: '',
        },
        validationSchema: Yup.object({
            gh_username: Yup.string().required("Please Enter a Username"),

        }),
        onSubmit: (values) => {
            handleAddSSHKeyRequest(values["gh_username"]);
        },
    });

    document.title = "Import SSH Keys | Gallium";
    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("sshKey.title"),
            link: "/inventory/sshkeys"
        },
        {
            name: t("sshKey.import"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("sshKey.import")} crumbs={crumbs} />
                    <FormikProvider value={importKeysFormik}>
                        <Card>
                            <CardBody>
                                <ErrorAlert errorObj={errorObject}/>
                                <GalliumFormHeader icon="github" title="Source">
                                    <div className="mb-3 col-md-6">
                                        <Field
                                            label="Github Username"
                                            id="gh_username"
                                            name="gh_username"
                                            className="form-control"
                                            placeholder="Enter Username to import keys from"
                                            type="text"
                                            component={GalliumInput}
                                        />
                                    </div>
                                </GalliumFormHeader>
                                <GalliumSubmitButton formik={importKeysFormik} spinner={isMutating} color="success" className="mt-2 float-end">
                                    Import
                                </GalliumSubmitButton>
                            </CardBody>
                        </Card>

                        <Col md={6} className="ps-0">
                            <Link to="/inventory/sshkeys">
                                <button className="btn btn-link text-decoration-none btn-label">
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                    Back to SSH Keys
                                </button>
                            </Link> 
                        </Col>

                    </FormikProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ImportSSHKeys;