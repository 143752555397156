import React from 'react';


import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import ActiveIncidentList from 'pages/Monitoring/List/List';

type HypervisorIncidentsProps = {
  hypervisorDetail: HostDetail;
};

const HypervisorIncidents: React.FC<HypervisorIncidentsProps> = ({hypervisorDetail}) => {
    return (

        <GalliumPageColumn>
            
            <ActiveIncidentList hypervisorSlug={hypervisorDetail.slug}/>

        </GalliumPageColumn>
    )
};

export default HypervisorIncidents;