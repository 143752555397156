import React from "react";
import { Link } from "react-router-dom";

const Name = (cell) => {
    return (
        <React.Fragment>
            <Link to={cell.row.original.slug} className="fw-medium link-primary">{cell.getValue()}</Link>
        </React.Fragment>
    );
};

const VirtualMachines = (cell) => {
    return (
        <React.Fragment>
            <span className="badge border border-primary text-primary me-2">{cell.row.original.vmCountTotal} Deployed</span>
            {cell.row.original.online ? (
                <span className="badge border border-success text-success">{cell.row.original.vmCountRunning} Running</span>
            ):(null)}
        </React.Fragment>
    );
};

export { Name, VirtualMachines};
