import React, { useState } from 'react';

import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";


const MetricChartCPU = ({ dataSeries }) => {

    const BasicAreaChartsColors = getChartColorsArray('["--vz-primary"]');

    const cpuChartOptions = {
        chart: {
            id: 'cpuChart',
            group: 'performance',
            height: 150,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
        },
        title: {
            text: 'CPU Load',
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: false,
                datetimeUTC: false
            },
            range: 3600000
        },
        yaxis: {
            tickAmount: 2,
            type: 'numeric',
        },
        legend: {
            horizontalAlign: 'left'
        },
        tooltip: {
            followCursor: false,
            x: {
                format: "dd MMM h:mm tt",
                show: false
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.0,
                opacityTo: 0.0,
            }
        },
        colors: BasicAreaChartsColors
        
        
    }

    const [options] = useState(cpuChartOptions);

    return (
        <ReactApexChart
            dir="ltr"
            options={options}
            type="area"
            series={dataSeries || []}
            height="150"
            className="apex-charts"
        />
    );
};

export default MetricChartCPU