import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import {
    Container,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useTranslation } from 'react-i18next';

import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import VirtualMachineStatusHeader from './StatusHeader';
import withRouter from 'Components/Common/withRouter';
import VirtualMachineSubmitted from './Submitted';
import { VirtualMachineTasksBadge } from './Tasks';

const VirtualMachineContainer = (props) => {
    const vmSlug = useParams().vmSlug;
    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(vmSlug, 1500)
    const {t} = useTranslation()

    const VerticalNavTab = ({item}) => {
        const isActive = window.location.pathname === item.link;
        return (
            <React.Fragment>
                <li className={isActive ? "subnav-link active" : "subnav-link"}>
                    <NavLink 
                        to={item.link}
                    >
                        <div className="d-flex align-items-center">
                            <div className={`flex-shrink-0 align-self-center me-2 ms-0`}>
                                <FontAwesomeIcon icon={byPrefixAndName.fal[item.icon]} className='me-2 fa-fw'/>

                                
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-truncate mb-0">{item.title}</p>
                            </div>
                            {item.badge &&
                                <div className="flex-shrink-0" id={"badge" + item.id}>
                                    {item.badge}
                                </div>
                            }
                        </div>
                    </NavLink>
                </li>
            </React.Fragment>
        )
    }

    const Divider = ({item}) => {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center px-4 mb-2 mt-2">
                    <div className="flex-grow-1">
                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            {item.title}
                        </h4>
                    </div>             
                </div>
            </React.Fragment>
        )
    }

    const vmTaskBadge = <VirtualMachineTasksBadge vmSlug={vmSlug} />;

    const verticalNav = [
        {
            "tab": "DASHBOARD", 
            "type": "Tab",
            "title": t("virtualMachine.nav.DASHBOARD.title"), 
            "icon": t("virtualMachine.nav.DASHBOARD.icon"),
            "link": `/vm/${vmSlug}`
        },
        { 
            "type":"Divider", 
            "title":"Resources"
        },
        {
            "tab": "HARDWARE", 
            "type": "Tab",
            "title": t("virtualMachine.nav.HARDWARE.title"), 
            "icon": t("virtualMachine.nav.HARDWARE.icon"),
            "link": `/vm/${vmSlug}/hardware`
        },
        {
            "tab": "STORAGE", 
            "type": "Tab",
            "title": t("virtualMachine.nav.STORAGE.title"), 
            "icon": t("virtualMachine.nav.STORAGE.icon"),
            "link": `/vm/${vmSlug}/storage`
        },
        {
            "tab": "NETWORK", 
            "type": "Tab",
            "title": t("virtualMachine.nav.NETWORK.title"), 
            "icon": t("virtualMachine.nav.NETWORK.icon"),
            "link": `/vm/${vmSlug}/network`
        },
        { 
            "type":"Divider", 
            "title":"Monitoring"
        },
        {
            "tab": "TASKS", 
            "type": "Tab",
            "title": t("virtualMachine.nav.TASKS.title"), 
            "icon": t("virtualMachine.nav.TASKS.icon"),
            "link": `/vm/${vmSlug}/tasks`,
            "badge": vmTaskBadge
        },
        { 
            "type":"Divider", 
            "title":"Virtual Machine"
        },
        {
            "tab": "BACKUP", 
            "type": "Tab",
            "title": t("virtualMachine.nav.BACKUP.title"), 
            "icon": t("virtualMachine.nav.BACKUP.icon"),
            "link": `/vm/${vmSlug}/backup`
        },
        {
            "tab": "ACTIONS", 
            "type": "Tab",
            "title": t("virtualMachine.nav.ACTIONS.title"), 
            "icon": t("virtualMachine.nav.ACTIONS.icon"),
            "link": `/vm/${vmSlug}/actions`
        },
        {
            "tab": "SETTINGS", 
            "type": "Tab",
            "title": t("virtualMachine.nav.SETTINGS.title"), 
            "icon": t("virtualMachine.nav.SETTINGS.icon"),
            "link": `/vm/${vmSlug}/settings`
        }
    ] 

    document.title = `${virtualMachine?.name ?? "Virtual Machine"} | Gallium`;


    return (
        <React.Fragment>
            <div className="page-content">
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    virtualMachine.status === "submitted" || virtualMachine.status === "pending" ? (
                        <VirtualMachineSubmitted command={virtualMachine.inProgressCommand}/>
                    ):(
                        <Container fluid>
                            <div className="vertical-nav-wrapper d-md-flex gap-0 mx-n4 mt-n4 p-0">
                                <div className="nav-left-container border-end">
                                    <VirtualMachineStatusHeader data={virtualMachine} />
                                    <div className="nav-left-items-container pt-0 flex-grow-1">
                                        <ul className="list-unstyled left-nav-list" id="navList">
                                            {(verticalNav).map((chat, index) => (
                                                chat.type === "Divider" ? (
                                                    <Divider item={chat} key={index} />
                                                ):(
                                                    <VerticalNavTab item={chat} key={index} />
                                                )
                                                
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="w-100">
                                    {props.children}
                                </div>
                            </div>
                        </Container>
                    )
                )}
            </div>
        </React.Fragment >
    )
}

export default withRouter(VirtualMachineContainer) ;