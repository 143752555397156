import React, { useState } from "react";

import classnames from "classnames";

import Loader from 'Components/Gallium/Loader';

import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

//redux
import { useUserProfileRequest } from "GalliumAPIHooks/User/UserProfileHooks";
import StatusBadge from "Components/Gallium/StatusBadge";
import ChangePassword from "./ChangePassword";
import MFATable from "./MFATable";
import MFAState from "./MFAState";
import PendingInvitesTable from "./PendingInvitesTable";

const UserProfile = () => {
    const { data, error: userProfileError, isLoading } = useUserProfileRequest();
    
    const [activeTab, setActiveTab] = useState("2");

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const getInitials = (input: string) => {
        const words = input.split(' ');
        const firstWord = words[0];
        const lastWord = words[words.length - 1];

        const initials = `${firstWord.charAt(0)}${lastWord.charAt(0)}`;

        return initials.toUpperCase();
    }

    document.title = "Profile | Gallium";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (<Loader />):(
                        <>
                            <Row>
                                <Col lg="12">

                                    <Card>
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="mx-3">
                                                    <div className="avatar-md">
                                                        <div className="avatar-title rounded-circle bg-primary-subtle text-primary fs-24">
                                                            {getInitials(data.name)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 align-self-center">
                                                    <div className="text-muted">
                                                        <h5>{data.name || "Unknown"}</h5>
                                                        <p className="mb-1">Email: {data.email || "Unknown"}</p>
                                                        <p className="mb-0">MFA Enabled: <StatusBadge status={data.mfaEnabled}/></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>      
                                <Col lg={12}>
                                    <Card>
                                        <CardHeader>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                role="tablist">
                                                <NavItem>
                                                    <NavLink to="#"
                                                        className={classnames({ active: activeTab === "2" })}
                                                        onClick={() => {
                                                            tabChange("2");
                                                        }}
                                                        type="button">
                                                        <i className="far fa-user"></i>
                                                Change Password
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink to="#"
                                                        className={classnames({ active: activeTab === "4" })}
                                                        onClick={() => {
                                                            tabChange("4");
                                                        }}
                                                        type="button">
                                                        <i className="far fa-envelope"></i>
                                                MFA
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink to="#"
                                                        className={classnames({ active: activeTab === "PENDING-INVITES" })}
                                                        onClick={() => {
                                                            tabChange("PENDING-INVITES");
                                                        }}
                                                        type="button">
                                                        <i className="far fa-envelope"></i>
                                                        Pending Invites
                                                    </NavLink>
                                                </NavItem>                                            
                                            </Nav>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <TabContent activeTab={activeTab}>
                                        

                                                <TabPane tabId="2">
                                                    <ChangePassword />
                                                </TabPane>

                                        

                                                <TabPane tabId="4">
                                        
                                                    <MFAState />
                                                    <MFATable />

                                                </TabPane>
                                                <TabPane tabId="PENDING-INVITES">

                                                    <PendingInvitesTable />

                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>)}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserProfile;
