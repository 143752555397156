import React from "react";

const ColHeaderCenter = (string: string) => {
    return (
        <React.Fragment>
            <div className="text-center">
                {string}
            </div>
        </React.Fragment>
    );
};

export default ColHeaderCenter