import React, { useState } from 'react';

import {
    Col,
    Row,
    Button,
    Card,
    CardHeader,
    CardBody,
} from "reactstrap"

import ArchiveModal from './ActionArchiveModal';
import { HostDetail } from 'generated';
import { useTranslation } from 'react-i18next';

interface HypervisorActionArchiveProps {
    hypervisor: HostDetail
}

const HypervisorActionArchive: React.FC<HypervisorActionArchiveProps> = ({ hypervisor }) => {
    const {t} = useTranslation()
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    
    return (
        <React.Fragment>
            <ArchiveModal
                showModal={showArchiveModal}
                closeModal={() => {
                    setShowArchiveModal(false)
                }}
                hypervisor={hypervisor}
            />
            <Card className='mb-2'>
                <CardHeader >
                    <h4 className="card-title mb-0">{t("hypervisor.actions.managementAction")}</h4>
                </CardHeader>
                <CardBody >
                    <Row>
                        <Col md={8}>
                            <h6 className="mb-0">{t("hypervisor.actions.archive")}</h6>
                            <p className="text-muted mb-0">{t("hypervisor.actions.archiveDetail")}</p>
                        </Col>
                        <Col md={4} className='text-end'>
                            <Button color="danger" onClick={() => setShowArchiveModal(true)}>{t("hypervisor.actions.archive")}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </React.Fragment>
    )
};

export default HypervisorActionArchive;