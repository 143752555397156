import { CreateVmRequest, GalliumApiErrorResponse, GalliumApiSuccessResponse, HardwareInfoResponse, HostDetail, HostMetricResponse, HostPowerStateRequest, HostSensorService, HostService, HostSummary, HostUpdateRequest, SensorListResponse, ServiceGatewayHostInfoResponse, UpdateRequestType } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'
import { JoinHostRequest } from "../../generated/models/JoinHostRequest";
import { JoinHostResponse } from "../../generated/models/JoinHostResponse";

export const useGetAllHosts = (pollInterval?: number) => {
    const getAllHosts = () => HostService.getAllHosts()
    
    const { data, error, isLoading } = useSWR<Array<HostSummary>>(`hosts`, getAllHosts, {
        refreshInterval: pollInterval || 0,
    });

    return { data, error, isLoading };
};

export const useGetHost = (hostSlug: string, pollInterval?: number) => {
    const getHost = () => HostService.getHost(hostSlug)
    
    const { data, error, isLoading } = useSWR<HostDetail>(`hosts/${hostSlug}`, getHost, {
        refreshInterval: pollInterval || 0,
    });

    return { data, error, isLoading };
};

export const useGetHostHardware = (hostSlug: string) => {
    const getHostHardware = () => HostService.getHostHardware(hostSlug)

    const { data, error, isLoading } = useSWR<HardwareInfoResponse>(`hosts/${hostSlug}/hardware`, getHostHardware);

    return { data, error, isLoading };
};

export const useGetHostSensorList = (hostSlug: string) => {
    const getHostSensorList = () => HostSensorService.getHostSensorList(hostSlug)

    const { data, error, isLoading } = useSWR<SensorListResponse>(`hosts/${hostSlug}/sensors`, getHostSensorList);

    return { data, error, isLoading };
};

export const useGetHostMetrics= (hostSlug: string, pollInterval?: number) => {
    const getHostMetrics = () => HostService.getHostMetrics(hostSlug)

    const { data, error, isLoading } = useSWR<HostMetricResponse>(`hosts/${hostSlug}/metrics`, getHostMetrics, {
        refreshInterval: pollInterval || 0,
    });

    return { data, error, isLoading };
};

export const useHostPowerStateChange = (hostSlug: string) => {
    const hostPowerStateChange = (key: string, {arg}: {arg: HostPowerStateRequest}) => HostService.hostPowerStateChange(hostSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, HostPowerStateRequest>(`hosts/${hostSlug}`, hostPowerStateChange);

    return { trigger, error, isMutating };
};

export const useArchiveHost = (hostSlug: string) => {
    const archiveHost = (key: null) => HostService.archiveHost(hostSlug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`hosts/${hostSlug}`, archiveHost);

    return { trigger, error, isMutating };
};

export const useJoinHost = () => {
    const joinHost = (key: null, {arg}: {arg: JoinHostRequest}) => HostService.joinHost(arg)

    const { trigger, error, isMutating } = useSWRMutation<JoinHostResponse, GalliumApiErrorResponse, string, JoinHostRequest>(`hosts`, joinHost);

    return { trigger, error, isMutating };
};

export const useGetHostServiceGatewayInfo = (hostSlug:string) => {
    const getHostServiceGatewayInfo = () => HostService.getHostServiceGatewayInfo(hostSlug)

    const { data, error, isLoading } = useSWR<ServiceGatewayHostInfoResponse>(`hosts/${hostSlug}/sg`, getHostServiceGatewayInfo);

    return { data, error, isLoading };
};

export interface UpdateRequest {
        requestType: UpdateRequestType
        reboot: boolean;
    }


export const useUpdateHostOs = (hostSlug:string) => {
    
    const updateHostOs = (key: null, { arg: { requestType, reboot } }: { arg: UpdateRequest }) =>
        HostService.updateHostOs(hostSlug, requestType, reboot);

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, UpdateRequest>(`hosts/${hostSlug}`, updateHostOs);

    return { trigger, error, isMutating };
};

export const useUpdateHost = (hostSlug:string) => {
    
    const updateHost = (key: null, { arg }: { arg: HostUpdateRequest }) => HostService.updateHost(hostSlug, arg);

    const { trigger, error, isMutating } = useSWRMutation<HostDetail, GalliumApiErrorResponse, string, HostUpdateRequest>(`hosts/${hostSlug}`, updateHost);

    return { trigger, error, isMutating };
};

export const useNewVm = (hostSlug: string) => {

    const newVm = (key: string, {arg}: {arg: CreateVmRequest}) => HostService.newVm(hostSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, CreateVmRequest>(`vm`, newVm);

    return { trigger, error, isMutating };
};