/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommandDetail } from '../models/CommandDetail';
import type { CommandListResponse } from '../models/CommandListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommandsService {

    /**
     * List commands
     * @param hostSlug The host to list commands for
     * @param vmSLug The VM to list commands for
     * @param completed Filter for completed commands
     * @returns CommandListResponse OK
     * @throws ApiError
     */
    public static listCommands(
        hostSlug?: string,
        vmSLug?: string,
        completed?: boolean,
    ): CancelablePromise<CommandListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cmd',
            query: {
                'hostSlug': hostSlug,
                'vmSLug': vmSLug,
                'completed': completed,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get details for a specific command
     * @param id The id of the command to look up
     * @returns CommandDetail OK
     * @throws ApiError
     */
    public static getCommandDetails(
        id: string,
    ): CancelablePromise<CommandDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cmd/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
