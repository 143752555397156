import React from 'react';

import HypervisorActionPower from './ActionPower';
import HypervisorActionArchive from './ActionArchive';
import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';

type HypervisorActionsProps = {
  hypervisor: HostDetail;
};

const HypervisorActions: React.FC<HypervisorActionsProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (

        <GalliumPageColumn>
            
            <HypervisorActionPower hypervisor={hypervisor} />

            <HypervisorActionArchive hypervisor={hypervisor} />

        </GalliumPageColumn>
    )
};

export default HypervisorActions;