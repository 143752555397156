import { CreateOrgRequest, CreateOrgResponse, GalliumApiErrorResponse, GalliumApiSuccessResponse, OrgUpdateRequest } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'
import { OrgService } from "../../generated/services/OrgService";
import { OrgInfoResponse } from "../../generated/models/OrgInfoResponse";
import { InviteUserRequest } from "../../generated/models/InviteUserRequest";

export const useGetOrgInfo = () => {
    const getOrgInfo = () => OrgService.getOrgInfo()
    
    const { data, error, isLoading } = useSWR<OrgInfoResponse>(`org`, getOrgInfo);

    return { data, error, isLoading };
};

export const useInviteUserToOrg = () => {
    const inviteUserToOrg = (key: null, {arg}:{arg:InviteUserRequest}) => OrgService.inviteUserToOrg(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, InviteUserRequest>(`org`, inviteUserToOrg);

    return { trigger, error, isMutating };
};

export const useUpdateOrg = () => {
    const updateOrg = (key: null, {arg}:{arg:OrgUpdateRequest}) => OrgService.updateOrg(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<OrgInfoResponse, GalliumApiErrorResponse, string, OrgUpdateRequest>(`org`, updateOrg);

    return { trigger, error, isMutating };
};

export const useCreateNewOrg = () => {
    const createNewOrg = (key: null, {arg}:{arg:CreateOrgRequest}) => OrgService.createNewOrg(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<CreateOrgResponse, GalliumApiErrorResponse, string, CreateOrgRequest>(`org`, createNewOrg);

    return { trigger, error, isMutating };
};



export const useDeleteUserFromOrg = () => {
    const deleteUserFromOrg = (key: null, {arg}:{arg:string}) => OrgService.deleteUserFromOrg(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, string>(`org`, deleteUserFromOrg);

    return { trigger, error, isMutating };
};
