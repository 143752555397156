/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { HostNetworkCreateRequest } from '../models/HostNetworkCreateRequest';
import type { HostNetworkUpdateRequest } from '../models/HostNetworkUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HostNetworkService {

    /**
     * Create a new network on the specified host
     * @param hostSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse The command to create the network was issued.
     * @throws ApiError
     */
    public static createHostNetwork(
        hostSlug: string,
        requestBody: HostNetworkCreateRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/network/create',
            path: {
                'hostSlug': hostSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Updates a network on the specified host. On all networks, this can be used to change the network name.  On bridge networks, this can be used to change the MTU.
     * @param hostSlug
     * @param netSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse The command to update the network was issued.
     * @throws ApiError
     */
    public static updateHostNetwork(
        hostSlug: string,
        netSlug: string,
        requestBody: HostNetworkUpdateRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hosts/{hostSlug}/network/{netSlug}',
            path: {
                'hostSlug': hostSlug,
                'netSlug': netSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Deletes a network on the specified host. Currently only VLAN networks can be deleted.
     * @param hostSlug
     * @param netSlug
     * @returns GalliumApiSuccessResponse The command to update the network was issued.
     * @throws ApiError
     */
    public static deleteHostNetwork(
        hostSlug: string,
        netSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/hosts/{hostSlug}/network/{netSlug}',
            path: {
                'hostSlug': hostSlug,
                'netSlug': netSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
