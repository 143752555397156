import React from 'react';

import { HostDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';

type HypervisorLicenseCardProps = {
  hypervisor: HostDetail;
};

const HypervisorLicenseCard: React.FC<HypervisorLicenseCardProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (
        <Card>
            <CardHeader >
                <h4 className="card-title mb-0">
                    <FontAwesomeIcon icon={byPrefixAndName.fal["file-certificate"]} className='fa-fw me-2'/>
                    {t("hypervisor.licenseInfo.cardTitle")}
                </h4>
            </CardHeader>
            <CardBody className='pb-0 pt-2'>
                <dl className="row mb-0">
                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.licenseInfo.license")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.licenseType")}</dt>
                            <dd className="col-sm-8 text-end">{t("hypervisor.licenseInfo.edgeFree")}</dd>
                        </dl>
                    </dd>
                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.licenseInfo.workloads")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.totalVMs")}</dt>
                            <dd className="col-sm-8 text-end">{hypervisor?.vmCountTotal} / {t("hypervisor.licenseInfo.unlimited")}</dd>
                        </dl>
                    </dd>
                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.licenseInfo.resources")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.storagePools")}</dt>
                            <dd className="col-sm-8 text-end">{hypervisor?.storagePools.length} / 1</dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.memoryAllocated")}</dt>
                            <dd className="col-sm-8 text-end">{formatDiskSize(hypervisor?.memAllocatedStartupMb)} / {t("hypervisor.licenseInfo.unlimited")}</dd>
                        </dl>
                    </dd>
                    <dt className="col-sm-3">{t("hypervisor.licenseInfo.features")}</dt>
                    <dd className="col-sm-9">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.sendBackups")}</dt>
                            <dd className="col-sm-8 text-end">{t("hypervisor.licenseInfo.notIncluded")}</dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.receiveBackups")}</dt>
                            <dd className="col-sm-8 text-end">{t("hypervisor.licenseInfo.notIncluded")}</dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.licenseInfo.devicePassthrough")}</dt>
                            <dd className="col-sm-8 text-end">{t("hypervisor.licenseInfo.notIncluded")}</dd>
                        </dl>
                    </dd>
                </dl>
            </CardBody>
        </Card>

                
    )
};

export default HypervisorLicenseCard;