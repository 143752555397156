/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HostVolumeHistoryEventType {
    CREATE = 'CREATE',
    ATTACH = 'ATTACH',
    DETACH = 'DETACH',
    DESTROY = 'DESTROY',
    IMPORT = 'IMPORT',
}
