export function formatDiskSize(sizeInMb: number): string {
    const oneGB = 1024; // 1 GB in MB
    const oneTB = 1024 * oneGB; // 1 TB in MB

    if (sizeInMb === null ) {
        return "Unknown"
    } else if (sizeInMb < oneGB) {
        return `${sizeInMb} MB`;
    } else if (sizeInMb < oneTB) {
        return `${Math.round(sizeInMb / oneGB)} GB`;
    } else {
        return `${Math.round(sizeInMb / oneTB)} TB`;
    }
}