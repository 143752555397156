import { DownloadInfoResponse, DownloadsService } from "generated";
import useSWR from "swr";


export const useGetDownloads = () => {
    const getDownloads = () => DownloadsService.getDownloads()
    
    const { data, error, isLoading } = useSWR<DownloadInfoResponse>(`download`, getDownloads );

    return { data, error, isLoading };
};
 