/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptOrgInviteRequest } from '../models/AcceptOrgInviteRequest';
import type { AcceptOrgInviteResponse } from '../models/AcceptOrgInviteResponse';
import type { ConfirmMfaRequest } from '../models/ConfirmMfaRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { ConfirmPasswordResetResponse } from '../models/ConfirmPasswordResetResponse';
import type { CreateMfaRequestBase } from '../models/CreateMfaRequestBase';
import type { CreateMfaResponseBase } from '../models/CreateMfaResponseBase';
import type { CurrentUser } from '../models/CurrentUser';
import type { FlagCreateRequest } from '../models/FlagCreateRequest';
import type { FlagResponse } from '../models/FlagResponse';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { GalliumLoginRequest } from '../models/GalliumLoginRequest';
import type { GalliumLoginResponse } from '../models/GalliumLoginResponse';
import type { GalliumRegisterRequest } from '../models/GalliumRegisterRequest';
import type { GalliumTokenRequest } from '../models/GalliumTokenRequest';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { PendingInvitesResponse } from '../models/PendingInvitesResponse';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { ValidatePasswordResetRequest } from '../models/ValidatePasswordResetRequest';
import type { ValidatePasswordResetResponse } from '../models/ValidatePasswordResetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Set a user/org flag
     * @param requestBody
     * @returns FlagResponse OK
     * @throws ApiError
     */
    public static setFlag(
        requestBody: FlagCreateRequest,
    ): CancelablePromise<FlagResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/flag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get the currently-set user and org level flags
     * @returns FlagResponse OK
     * @throws ApiError
     */
    public static getFlags(): CancelablePromise<FlagResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flag',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Login
     * @param requestBody
     * @returns GalliumLoginResponse OK
     * @throws ApiError
     */
    public static login(
        requestBody: GalliumLoginRequest,
    ): CancelablePromise<GalliumLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Confirm a password reset
     * @param requestBody
     * @returns ConfirmPasswordResetResponse OK
     * @throws ApiError
     */
    public static passwordResetConfirm(
        requestBody: ConfirmPasswordResetRequest,
    ): CancelablePromise<ConfirmPasswordResetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/passwordreset/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Request a password reset
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static passwordResetRequest(
        requestBody: PasswordResetRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/passwordreset/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Validate a password reset and determine what fields are needed in the confirm request
     * @param requestBody
     * @returns ValidatePasswordResetResponse OK
     * @throws ApiError
     */
    public static passwordResetValidate(
        requestBody: ValidatePasswordResetRequest,
    ): CancelablePromise<ValidatePasswordResetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/passwordreset/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Register a new organisation and user
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static register(
        requestBody: GalliumRegisterRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a fresh access token using the refresh token.
     * @param requestBody
     * @returns GalliumLoginResponse OK
     * @throws ApiError
     */
    public static refreshAccessToken(
        requestBody: GalliumTokenRequest,
    ): CancelablePromise<GalliumLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get currently logged in user
     * @returns CurrentUser OK
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<CurrentUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update the currently logged in user
     * @param requestBody
     * @returns CurrentUser OK
     * @throws ApiError
     */
    public static updateUser(
        requestBody: UpdateUserRequest,
    ): CancelablePromise<CurrentUser> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Accept a pending invitation to an org
     * @param requestBody
     * @returns AcceptOrgInviteResponse OK
     * @throws ApiError
     */
    public static acceptOrgInvite(
        requestBody: AcceptOrgInviteRequest,
    ): CancelablePromise<AcceptOrgInviteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/invites/accept',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get any pending org invites for the currently logged in user
     * @returns PendingInvitesResponse OK
     * @throws ApiError
     */
    public static getPendingOrgInvitations(): CancelablePromise<PendingInvitesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/invites/pending',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Add an MFA method to the current user
     * @param requestBody
     * @returns CreateMfaResponseBase OK
     * @throws ApiError
     */
    public static createMfaMethod(
        requestBody: CreateMfaRequestBase,
    ): CancelablePromise<CreateMfaResponseBase> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/mfa/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete an MFA method
     * @param mfaSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static deleteMfaMethod(
        mfaSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/mfa/{mfaSlug}',
            path: {
                'mfaSlug': mfaSlug,
            },
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Confirm MFA method
     * @param mfaSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static confirmMfaMethod(
        mfaSlug: string,
        requestBody: ConfirmMfaRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/mfa/{mfaSlug}/confirm',
            path: {
                'mfaSlug': mfaSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
