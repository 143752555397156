/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConfigureVmDiskAction {
    CREATE = 'CREATE',
    DETACH = 'DETACH',
    RESIZE = 'RESIZE',
    ATTACH = 'ATTACH',
}
