import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCreateTemplateDisk } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { CreateTemplateDiskRequest, TemplateDiskType, Template } from "generated";
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';

interface AddUploadDiskToTemplateButtonProps {
  template: Template;
  diskType: TemplateDiskType; // Accept diskType as a prop
}

const AddUploadDiskToTemplateButton: React.FC<AddUploadDiskToTemplateButtonProps> = ({ template, diskType }) => {
    const { t } = useTranslation();
    const { trigger: createDiskTrigger, isMutating: createDiskMutating } = useCreateTemplateDisk(template.slug);

    const handleAddUploadDiskToTemplateFail = useCallback(() => {
        toast.error(t("templates.uploadDiskForm.AddDiskFail"));
    }, [t]);

    const handleAddUploadDiskToTemplateSuccess = useCallback(() => {
    }, [t]);

    const handleAddUploadDiskToTemplateRequest = useCallback((values: CreateTemplateDiskRequest) => {
        createDiskTrigger(values, {
            onError: handleAddUploadDiskToTemplateFail,
            onSuccess: handleAddUploadDiskToTemplateSuccess
        });
    }, [createDiskTrigger, handleAddUploadDiskToTemplateFail, handleAddUploadDiskToTemplateSuccess]);

    // Render appropriate button based on diskType
    const getButtonContent = () => {
        if (diskType === TemplateDiskType.DISK) {
            return (
                <>
                    <i className="label-icon">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["upload"]} />
                    </i>
                    {t("templates.createTemplateForm.buttons.addUploadDisk")}
                </>
            );
        } else if (diskType === TemplateDiskType.CDROM) {
            return (
                <>
                    <i className="label-icon">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["compact-disc"]} />
                    </i>
                    {t("templates.createTemplateForm.buttons.addUploadOptical")}
                </>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            {createDiskMutating ? (
                <GalliumSpinnerButton className="w-100 btn-label" color="primary" />
            ) : (
                <Button
                    color="primary"
                    className="w-100 btn-label"
                    onClick={() =>
                        handleAddUploadDiskToTemplateRequest({ blank: false, diskType })
                    }
                >
                {getButtonContent()}
                </Button>
            )}
        </React.Fragment>
    );
};

export default AddUploadDiskToTemplateButton;
