import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import classnames from "classnames";
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useListBackupProfiles } from 'GalliumAPIHooks/Backup/BackupHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import BackupSetListComponent from './BackupSetList';
import BackupProfileUpdate from '../Update';
import RunBackupProfileButton from './RunBackupProfileButton';

const BackupProfileDetail = () => {
    const {t} = useTranslation()
    const {data, error, isLoading} = useListBackupProfiles()
    const profileSlug = useParams().profileSlug
    const backupProfile = data?.profiles?.find(profile => profile.slug === profileSlug)
    const backupSets = backupProfile?.backupSets
    document.title = t("backupProfiles.title") +" | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: t("backupProfiles.viewTitle"),
        }
    ]

    const [galliumNavTab, setgalliumNavTab] = useState("SETTINGS");
    const galliumNavToggle = (tab) => {
        if (galliumNavTab !== tab) {
            setgalliumNavTab(tab);
        }
    };

    const tabs = (
        <Nav className="nav-tabs-custom border-bottom-0 pt-1" role="tablist">
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "SETTINGS", })} onClick={() => { galliumNavToggle("SETTINGS"); }} >
                    {t("backupProfiles.settingTab")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "VM", })} onClick={() => { galliumNavToggle("VM"); }} >
                    {t("backupProfiles.setsTab")}
                </NavLink>
            </NavItem>
        </Nav>
    )

    return (
        <div className="page-content">
            <Container fluid>
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <React.Fragment>
                        <GalliumBreadcrumb title={backupProfile?.name || ""} crumbs={crumbs} tabs={tabs}>
                            <RunBackupProfileButton profileSlug={profileSlug} />
                        </GalliumBreadcrumb>
                        <TabContent activeTab={galliumNavTab} className="text-muted">
                            <TabPane tabId="SETTINGS" id="settingTab">
                                <BackupProfileUpdate backupProfile={backupProfile} />
                            </TabPane>
                            <TabPane tabId="VM" id="vmTab">
                                <BackupSetListComponent backupSets={backupSets}/>
                            </TabPane>
                        </TabContent>
                        
                        
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
};

export default BackupProfileDetail;
                    