// @ts-nocheck
import React from 'react';
import { Label } from 'reactstrap';
import { Field, useFormikContext } from "formik";
import { AlertType} from 'generated';
import { useMonitoringInfo } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';

const GalliumMonitoringCheckboxes = ({ alertType }:{alertType: AlertType}) => {
    const { values, setFieldValue } = useFormikContext();
    const isChecked = values.alertRules.some(item => item.alertKey === alertType.alertKey);

    const timeoutOptions = [
        {value: 5, label: "5 Minutes"},
        {value: 15, label: "15 Minutes"},
        {value: 30, label: "30 Minutes"},
        {value: 60, label: "1 Hour"},
    ]

    const findIndex = (alertRules, alertKey) => {
        const index = alertRules.findIndex(rule => rule.alertKey === alertKey);
        return index === -1 ? undefined : index;
    };

    const handleCheckboxChange = () => {
        if (isChecked) {
            const nextValue = values.alertRules.filter(item => item.alertKey !== alertType.alertKey);
            setFieldValue('alertRules', nextValue);
        } else {
            const newAlert = {
                alertKey: alertType.alertKey,
                config: alertType.configType === "MINUTES" ? {minutes: 5} : null  // default value if needed
            };
            setFieldValue('alertRules', [...values.alertRules, newAlert]);
        }
    };

    const handleMinutesChange = (event) => {
        const updatedAlerts = values.alertRules.map(item => {
            if (item.alertKey === alertType.alertKey) {
                return { ...item, config: {minutes: parseInt(event.target.value,10)} };
            }
            return item;
        });
        setFieldValue('alertRules', updatedAlerts);
    };

    return (

        <div className='form-check pt-1 pb-2'>
            <label className="form-check-label fw-normal" htmlFor={"formCheck1" + alertType.alertKey}>{alertType.alertDescription}</label>
            <input
                type="checkbox"
                className="form-check-input"
                id={"formCheck1" + alertType.alertKey}
                checked={isChecked}
                onChange={handleCheckboxChange}
            />


            {alertType.configType === "MINUTES" && (
                <Field as="select" className="form-select-sm ms-2 p-0" name={`alertRules.${findIndex(values.alertRules, alertType.alertKey)}.config.minutes`} onChange={handleMinutesChange} disabled={!isChecked}>
                    {timeoutOptions.map((item, key) => (
                        <option value={item.value} key={key}>{item.label}</option>
                    ))}
                </Field>
            )}
        </div>
    );
};

const AlertProfilesRulesEventsComponent = () => {
    const {data} = useMonitoringInfo()

    return (    
        <React.Fragment>
            <div className="mb-3">
                <Label>Alert Rules</Label>
                {data?.alertTypes.map((item, key) => (
                    <GalliumMonitoringCheckboxes
                        alertType={item}
                        key={key}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesRulesEventsComponent;