/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTemplateDiskRequest } from '../models/CreateTemplateDiskRequest';
import type { CreateTemplateRequest } from '../models/CreateTemplateRequest';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { Template } from '../models/Template';
import type { TemplateDisk } from '../models/TemplateDisk';
import type { TemplateResponse } from '../models/TemplateResponse';
import type { UpdateTemplateRequest } from '../models/UpdateTemplateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplateService {

    /**
     * list which templates are available
     * @param includeDraft Whether to include draft templates in the response
     * @returns TemplateResponse OK
     * @throws ApiError
     */
    public static listTemplates(
        includeDraft?: boolean,
    ): CancelablePromise<TemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/templates',
            query: {
                'includeDraft': includeDraft,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new template
     * @param requestBody
     * @returns Template OK
     * @throws ApiError
     */
    public static createTemplate(
        requestBody: CreateTemplateRequest,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/templates/newtemplate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get an existing template
     * @param templateSlug
     * @returns Template OK
     * @throws ApiError
     */
    public static getTemplate(
        templateSlug: string,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/templates/{templateSlug}',
            path: {
                'templateSlug': templateSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete a template
     * @param templateSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static deleteTemplate(
        templateSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/templates/{templateSlug}',
            path: {
                'templateSlug': templateSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update an existing template
     * @param templateSlug
     * @param requestBody
     * @returns Template OK
     * @throws ApiError
     */
    public static updateTemplate(
        templateSlug: string,
        requestBody: UpdateTemplateRequest,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/templates/{templateSlug}',
            path: {
                'templateSlug': templateSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Clone an existing template
     * @param templateSlug
     * @returns Template OK
     * @throws ApiError
     */
    public static cloneTemplateDisk(
        templateSlug: string,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/templates/{templateSlug}/clone',
            path: {
                'templateSlug': templateSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a specific disk from a template
     * @param templateSlug
     * @param diskSlug
     * @returns TemplateDisk OK
     * @throws ApiError
     */
    public static getTemplateDisk(
        templateSlug: string,
        diskSlug: string,
    ): CancelablePromise<TemplateDisk> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/templates/{templateSlug}/disks/{diskSlug}',
            path: {
                'templateSlug': templateSlug,
                'diskSlug': diskSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete a specific disk from a template
     * @param templateSlug
     * @param diskSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static deleteTemplateDisk(
        templateSlug: string,
        diskSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/templates/{templateSlug}/disks/{diskSlug}',
            path: {
                'templateSlug': templateSlug,
                'diskSlug': diskSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a disk on an existing template
     * @param templateSlug
     * @param requestBody
     * @returns TemplateDisk OK
     * @throws ApiError
     */
    public static createTemplateDisk(
        templateSlug: string,
        requestBody: CreateTemplateDiskRequest,
    ): CancelablePromise<TemplateDisk> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/templates/{templateSlug}/newdisk',
            path: {
                'templateSlug': templateSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
