const ToInitials = ( string: string ) => {
    const startingString = string || ''

    const getInitials = (input: string) => {
        const words = input.split(' ');
        const firstWord = words[0];
        const lastWord = words.length > 1 ? words[words.length - 1] : ''

        const initials = `${firstWord.charAt(0)}${lastWord.charAt(0)}`;

        return initials.toUpperCase();
    }

    const formattedText = getInitials(startingString)

    return formattedText
};

export default ToInitials;

