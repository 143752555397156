import React from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
} from "reactstrap";

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import withRouter from 'Components/Common/withRouter';
import { useTranslation } from 'react-i18next';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useParams } from 'react-router-dom';


const CreateVMLayout = (props) => {
    const {hostSlug, templateSlug} = useParams()
    const {t} = useTranslation()
    const crumbs = [
        {
            name: t("mainNav.virtualMachines"),
            link: "/vm"
        },
        {
            name: t("virtualMachine.create.pageTitle"),
        }
    ]
    document.title = `${t("virtualMachine.create.pageTitle")} | Gallium`
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="Create Virtual Machine" crumbs={crumbs} />
                    <Row>
                        <Col>
                                <Card>
                                    <CardBody className="checkout-tab">
                                        <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                            <Nav
                                                className="nav-pills nav-justified custom-nav"
                                                role="tablist"
                                            >
                                                <NavItem role="presentation">
                                                    <NavLink to="/vm/create" className="p-3 fs-15 nav-link">
                                                        <FontAwesomeIcon icon={byPrefixAndName.fal["server"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                                        {t("virtualMachine.create.hostSelectionTab")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    {hostSlug ? (
                                                    <NavLink to={`/vm/create/${hostSlug}`} className="p-3 fs-15 nav-link" >
                                                        <FontAwesomeIcon icon={byPrefixAndName.fal["file-code"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                                        {t("virtualMachine.create.templateSelectionTab")}
                                                    </NavLink>
                                                    ):(
                                                    <div className="p-3 fs-15 nav-link" >
                                                        <FontAwesomeIcon icon={byPrefixAndName.fal["file-code"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                                        {t("virtualMachine.create.templateSelectionTab")}
                                                    </div>
                                                    )}
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    {templateSlug ? (
                                                    <NavLink to={`/vm/create/${hostSlug}/${templateSlug}`} className="p-3 fs-15 nav-link">
                                                        <FontAwesomeIcon icon={byPrefixAndName.fal["gear"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                                    {t("virtualMachine.create.settingsTab")}
                                                    </NavLink>
                                                    ):(
                                                    <div className="p-3 fs-15 nav-link">
                                                        <FontAwesomeIcon icon={byPrefixAndName.fal["gear"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                                    {t("virtualMachine.create.settingsTab")}
                                                    </div>
                                                    )}
                                                    
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        {props.children}
                                    </CardBody>
                                </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(CreateVMLayout) 