

import React from 'react';
import { useTranslation } from 'react-i18next';

type GalliumPageColumnProps = {
    children: React.ReactNode;
};

const GalliumPageColumn: React.FC<GalliumPageColumnProps> = ({ children }) => {
    const { t } = useTranslation()

    return (
        <div className='p-2'>
            {children}
        </div>
    )
};

export default GalliumPageColumn;

