import React, { useMemo, useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";

import {
    Col,
    Alert,
    CardHeader,
    CardBody,
    Card,
} from "reactstrap"

import { GalliumApiErrorResponse } from 'generated';

import { useGetVirtualMachine, useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useParams } from 'react-router-dom';
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useTranslation } from 'react-i18next';
import GalliumCpuInput from 'Components/Gallium/GalliumCpuInput';
import useAsyncCommand from 'Components/Hooks/useAsyncCommand';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';

const VirtualMachineHardware = () => {
    const { t } = useTranslation(['translation'])
    const { vmSlug } = useParams();
    const {data: virtualMachine, isLoading: isVirtualMachineLoading, error: virtualMachineError} = useGetVirtualMachine(vmSlug)
    const {data: hypervisor, error: hypervisorError, isLoading: isHypervisorLoading } = useGetHost(virtualMachine?.host?.slug);
    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    // Using the custom hook for command tracking
    const { isUpdating, startCommand } = useAsyncCommand({
        onError: (error) => {
            editVirtualMachineHardwareFormik.setErrors(error.errors || {});
            setErrorObject(error || {});
        },
        onComplete: () => {
            editVirtualMachineHardwareFormik.resetForm()
        }
    });

    // Formik
    const editVirtualMachineHardwareFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            memoryMb: virtualMachine.memoryMb,
            cpus: virtualMachine.cpus
        },
        validationSchema: Yup.object({
            memoryMb: Yup.number()
                .required(t("formHelpers.requiredField"))
                .min(128, t("virtualMachine.formHelpers.memoryLimits"))
                .max(131072, t("virtualMachine.formHelpers.memoryLimits")),
            cpus: Yup.number().required(t("formHelpers.requiredField")),
        }),
        onSubmit: (values) => {
            // Use the command hook to submit and track the request
            startCommand(trigger, values);
        },
    });

    // Memoized to avoid unnecessary recalculations
    const isFormDisabled = useMemo(() => !virtualMachine?.availableActions?.modifyHardware, [virtualMachine]);

    if (isHypervisorLoading || isVirtualMachineLoading) return <Loader />;
    if (hypervisorError || virtualMachineError) return <ErrorNotice />;

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.NETWORK.title")} icon={t("virtualMachine.nav.NETWORK.icon")}>
            <Card>
                <CardHeader>

                    <h4 className="card-title mb-0">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["hard-drive"]} className='me-2'/>
                        {t("virtualMachine.hardwareCardHeader")}
                    </h4>

                </CardHeader>
                <CardBody>  
                
                    <FormikProvider value={editVirtualMachineHardwareFormik}>
                        {virtualMachine.availableActions.modifyHardware ? (null):(<Alert color="info">{t("virtualMachine.modifyVMUnavailable")}</Alert>)}
                        <ErrorAlert errorObj={errorObject} />

                        <GalliumCpuInput
                            name="cpus"
                            availableCpus={hypervisor?.availCpus}
                            isDisabled={isFormDisabled}
                        />
                        <GalliumSizeInput
                            label={t("virtualMachine.hardwareMemory")}
                            id="memoryInput"
                            name="memoryMb"
                            disabled={isFormDisabled}
                        />

                        {isFormDisabled ? (null) : (
                            <div className='float-end'>
                                <GalliumSubmitButton formik={editVirtualMachineHardwareFormik} spinner={isMutating} waiting={isUpdating} color="success"> {t("globals.submit")}</GalliumSubmitButton>
                            </div>
                        )}

                    </FormikProvider>   
                </CardBody>
            </Card>
        </GalliumThirdColumn>
    )
};

export default VirtualMachineHardware;
