import React, { ReactNode } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap"

import { TemplateDisk, TemplateDiskType } from 'generated';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useTranslation } from 'react-i18next';


interface RenderDiskProps {
  disk: TemplateDisk;
}

interface DiskCardProps {
  disk: TemplateDisk;
  children: ReactNode
}

const DiskCard: React.FC<DiskCardProps> = ({ children, disk }) => {
  const { t } = useTranslation();

  const getDiskLabel = (disk: TemplateDisk): string => {
    if (disk.blank) return t('templates.uploadDiskForm.blankDisk');
    if (!disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? t('templates.uploadDiskForm.uploadDisk')
        : t('templates.uploadDiskForm.uploadISO');
    }
    if (disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? t('templates.uploadDiskForm.uploadedDisk')
        : t('templates.uploadDiskForm.uploadedISO');
    }
    return "";
  };

  const getIcon = (disk: TemplateDisk) => {
    if (disk.blank) return byPrefixAndName.fal["hard-drive"];
    if (!disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? byPrefixAndName.fal["upload"]
        : byPrefixAndName.fal["compact-disc"];
    } else {
      return disk.diskType === TemplateDiskType.DISK
        ? byPrefixAndName.fal["hard-drive"]
        : byPrefixAndName.fal["compact-disc"];
    }
  };

  return (
    <Card className='mb-2'>
      <CardHeader className='border-0 pb-0'>
            <span className="card-title">
              <FontAwesomeIcon icon={getIcon(disk)} className='me-2'/> {getDiskLabel(disk)}
            </span>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

const UploadedDisk: React.FC<RenderDiskProps> = ({ disk }) => {
    const { t } = useTranslation();
    return (
        <DiskCard disk={disk}>
            <dl className="row mb-0">
                <dt className="col-sm-3">{t('templates.uploadDiskForm.fileName')}</dt>
                <dd className="col-sm-9">{disk.filename}</dd>

                <dt className="col-sm-3">{t('templates.uploadDiskForm.sha256')}</dt>
                <dd className="col-sm-9"><code>{disk.sha256sum}</code> </dd>
            </dl>      
        </DiskCard>
    );
};

const PendingUploadDisk: React.FC<RenderDiskProps> = ({ disk }) => {
    const { t } = useTranslation();
    return (
        <DiskCard disk={disk}>
        <span className='text-muted'>Pending Upload</span>
        </DiskCard>
    );
};

const BlankDisk: React.FC<RenderDiskProps> = ({ disk }) => {
    const { t } = useTranslation();
    return (
        <DiskCard disk={disk}>
            <dl className="row mb-0">
                <dt className="col-sm-3">{t('templates.uploadDiskForm.minimumSize')}</dt>
                <dd className="col-sm-9">{formatDiskSize(disk.minSizeMb)}</dd>
            </dl>
        </DiskCard>
    );
};

const RenderFinalisedTemplateDiskComponent: React.FC<{ disk: TemplateDisk }> = ({ disk}) => {

    if (disk.blank) {
        return <BlankDisk disk={disk} />;
    } else if  (!disk.filename) {
        return <PendingUploadDisk disk={disk} />;
    } else {
        return <UploadedDisk disk={disk} />;
    } 
};

export default RenderFinalisedTemplateDiskComponent
