import React from "react";
import { LocalDataTimeString, TimeAgoString } from "../GalliumTimeHelpers";
import { UncontrolledTooltip } from "reactstrap";

const ColTimeAgo = (cell) => {
    if (cell.getValue() === null) return (null)
    return (
        <React.Fragment>
            <span id={`tooltip_${cell.cell.id}`}>
                {TimeAgoString(cell.getValue())}
            </span>
            <UncontrolledTooltip placement="top" target={`tooltip_${cell.cell.id}`} > 
                {LocalDataTimeString(cell.getValue())}
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

export default ColTimeAgo