import React from 'react';

import { Field } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';
import { TemplateOsType } from 'generated';

const Cloudinit = ({ template }) => {
    const linuxText = 
    "This input accepts Cloudinit User Data or a bash script. Prefix with #cloud-config or #!/bin/bash as appropriate. Do not pass sensitive data using this mechanism."

    const windowsText = 
    "This input accepts Cloudinit User Data or a PowerShell script. Prefix with #cloud-config or #ps1 as appropriate. Do not pass sensitive data using this mechanism."

    return (
        <GalliumFormHeader icon="info" title="Configuration">
            {template?.supportsCloudInit ? (

                <Field
                    label="User Data or Script"
                    name="cloudInitUserData"
                    id="cloudInitUserData"
                    className="form-control"
                    placeholder={template.osType === TemplateOsType.WINDOWS ? (windowsText):(linuxText)}
                    type="textarea"
                    component={GalliumInput}
                />
            

            ):(
                <p className='text-muted'>This template does not support Cloud-init user data.</p>
            )}
        </GalliumFormHeader>
    )
};

export default Cloudinit;
