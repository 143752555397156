const StringTidy = (string: string) => {
    const startingString = string || ''

    function toTitleCase(string: string) {
        // Insert space before uppercase letters that follow lowercase letters
        const withSpaces = string.replace(/([a-z])([A-Z])/g, "$1 $2");

        // Replace underscores with spaces
        const noUnderscores = withSpaces.replace(/_/g, " ");

        // Convert to Title Case, preserving specific mixed-case patterns
        return noUnderscores.replace(
            /\w\S*/g,
            function(txt) {
                // Check if the word is all uppercase or starts with a lowercase followed by an uppercase
                if (txt === txt.toUpperCase()) {
                    return txt; // Keep all uppercase words as-is
                } else if (/^[a-z][A-Z]/.test(txt) && txt.substr(1).toUpperCase() !== txt.substr(1)) {
                    return txt; // Keep mixed-case patterns like 'iLO' as-is
                } else {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            }
        );
    }

    const formattedText = toTitleCase(startingString)

    return formattedText
};

export default StringTidy;




// TOOD Tests
// "iLO" should not become "I LO" or "Ilo"
// "DutyCycle"