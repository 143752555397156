
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    Button,
} from "reactstrap"
import UpdateModal from './UpdateModal';
import { HostDetail } from 'generated';

type HypervisorUpdateWarningProps = {
  hypervisorDetail: HostDetail
};

const HypervisorUpdateWarning: React.FC<HypervisorUpdateWarningProps> = ({hypervisorDetail}) => {
    const { t } = useTranslation()
    const [showArchiveModal, setShowArchiveModal] = useState(false);

    return (
        <React.Fragment>
            <UpdateModal
                showModal={showArchiveModal}
                closeModal={() => {
                    setShowArchiveModal(false)
                }}
                hypervisor={hypervisorDetail}
            />
            {hypervisorDetail.hostOsUpdateAvailable ? (
                <Alert color="primary" className='mt-0 mb-2'>
                    <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["microchip"]}/>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="alert-heading">{t("hypervisor.update.updateAvailableTitle")}</h5>
                            <span className="mb-2">{t("hypervisor.update.updateAvailableDetail")}</span>
                        </div>
                        <div className="flex-shrink-0">
                            <Button color="primary" onClick={() => setShowArchiveModal(true)}>{t("hypervisor.update.updateAction")}</Button>
                        </div>
                    </div>
                </Alert>
            ):(null)}
        </React.Fragment>
    )
};

export default HypervisorUpdateWarning;