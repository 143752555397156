import React from 'react';

import {
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import ActiveIncidentList from './List';

const MonitoringIncidentList = () => {
    document.title = "Monitoring | Gallium";
    const crumbs = [
        {
            name: "Monitoring",
            link: "/monitoring"
        },
        {
            name: "View Incidents",
        }
    ]
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title="Open Incidents" crumbs={crumbs} />
                <ActiveIncidentList />
            </Container>
        </div>
    );
};

export default MonitoringIncidentList;
                    