import React, { useMemo, useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Card,
    CardBody,
    CardHeader,
    Label
} from "reactstrap"

import { ConfigureVmAutoStart, VirtualMachineDetail, VmAutoStart } from 'generated';
import { useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import useAsyncCommand from 'Components/Hooks/useAsyncCommand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


const VirtualMachineAutostart = ({ virtualMachine }: {virtualMachine: VirtualMachineDetail}) => {

    const { t } = useTranslation(['translation'])

    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)

    const [errorObject, setErrorObject] = useState(null)

    const { isUpdating, startCommand } = useAsyncCommand({
        onError: (error) => {
            editVirtualMachineAutostartFormik.setErrors(error.errors || {});
            setErrorObject(error || {});
        },
        onComplete: () => {
            editVirtualMachineAutostartFormik.resetForm()
        }
    });

    // Formik
    const editVirtualMachineAutostartFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            autoStart: virtualMachine.autoStart === VmAutoStart.AUTOSTART ? true : false,
        },
        validationSchema: Yup.object({
            autoStart: Yup.boolean()
        }),
        onSubmit: (values) => {
            const editValues = {
                autoStart: values["autoStart"] ? ConfigureVmAutoStart.AUTOSTART : ConfigureVmAutoStart.NO_AUTOSTART,
            };
            // @ts-expect-error
            window.Intercom('trackEvent', 'edit-vm-request');
            // save new key
            startCommand(trigger, editValues)
        },
    });

    const isFormDisabled = useMemo(() => !virtualMachine?.availableActions?.changePowerState, [virtualMachine]);
    
    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">
                    <FontAwesomeIcon icon={byPrefixAndName.fal["sunrise"]} className="me-2"/>
                    {t("virtualMachine.settings.editStartup")}
                </h4>       
            </CardHeader>
            <CardBody>
                <FormikProvider value={editVirtualMachineAutostartFormik}>

                    <ErrorAlert errorObj={errorObject} />

                    <div className="mt-0 mb-3">
                        <div className="form-check form-switch form-switch-lg" dir="ltr">
                            <Field
                                id="autoStart" 
                                name="autoStart" 
                                type="checkbox" 
                                className="form-check-input"
                                disabled={isFormDisabled}  
                            />
                            <Label className="form-check-label" htmlFor="autoStart">{t("virtualMachine.settings.autostartText")}</Label>
                        </div>
                    </div>
                            
                    <div className='float-end'>
                        <GalliumSubmitButton 
                            formik={editVirtualMachineAutostartFormik} 
                            spinner={isMutating} 
                            waiting={isUpdating} 
                            color="success"
                            externallyDisabled={isFormDisabled}> 
                            Submit
                        </GalliumSubmitButton>
                    </div>

                </FormikProvider>
            </CardBody>
        </Card>
    )
};

export default VirtualMachineAutostart;
