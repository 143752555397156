import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { round } from "lodash";
import { DiskDetail } from 'generated';
import { useTranslation } from 'react-i18next';

interface DiskModalProps {
    showModal: boolean,
    closeModal: () => void,
    disk: DiskDetail
}

const DiskModal: React.FC<DiskModalProps> = ({ showModal, closeModal, disk }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true} size="lg">
            <ModalHeader className="p-3 bg-light" toggle={closeModal}>
                {t("hypervisor.diskInfo.modalTitle")}
            </ModalHeader>
            <ModalBody>
                <dl className="row mb-0">
                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.diskInfo.info")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.galliumId")}</dt>
                            <dd className="col-sm-8">{disk?.id || t("hypervisor.diskInfo.unknown")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.serial")}</dt>
                            <dd className="col-sm-8">{disk?.serialNumber || t("hypervisor.diskInfo.unknown")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.description")}</dt>
                            <dd className="col-sm-8">{disk?.description || t("hypervisor.diskInfo.unknown")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.type")}</dt>
                            <dd className="col-sm-8">{disk?.deviceType || t("hypervisor.diskInfo.unknown")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.sizeGB")}</dt>
                            <dd className="col-sm-8">{round(disk?.sizeBytes / 1073741824, 0) || t("hypervisor.diskInfo.unknown")}</dd>
                        </dl>
                    </dd>

                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.diskInfo.blockSize")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.logical")}</dt>
                            <dd className="col-sm-8">{disk?.logicalBlockSize || t("hypervisor.diskInfo.unknown")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.physical")}</dt>
                            <dd className="col-sm-8">{disk?.physicalBlockSize || t("hypervisor.diskInfo.unknown")}</dd>
                        </dl>
                    </dd>

                    <dt className="col-sm-3 text-truncate mb-2 border-bottom border-bottom-dashed">{t("hypervisor.diskInfo.partitions")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        {disk?.existingPartitions?.length === 0 ? <dd className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.noneFound")}</dd> : null}
                        {disk?.existingPartitions?.map((partition, index) => (
                            <dl className="row mb-0 border-bottom border-bottom-dashed" key={index}>
                                <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.number")}</dt>
                                <dd className="col-sm-8">{partition.partNum || t("hypervisor.diskInfo.unknown")}</dd>
                                <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.partitionType")}</dt>
                                <dd className="col-sm-8">{partition.type || t("hypervisor.diskInfo.unknown")}</dd>
                                <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.name")}</dt>
                                <dd className="col-sm-8">{partition.name || t("hypervisor.diskInfo.unknown")}</dd>
                                <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.sizeMB")}</dt>
                                <dd className="col-sm-8">{round(partition?.sizeBytes / 1000000, 0) || t("hypervisor.diskInfo.unknown")}</dd>
                            </dl>
                        ))}
                    </dd>

                    <dt className="col-sm-3 mb-0">{t("hypervisor.diskInfo.validation")}</dt>
                    <dd className="col-sm-9 mb-0">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.boot")}</dt>
                            <dd className="col-sm-8">{disk?.boot ? t("hypervisor.diskInfo.yes") : t("hypervisor.diskInfo.no")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.poolMember")}</dt>
                            <dd className="col-sm-8">{disk?.data ? t("hypervisor.diskInfo.yes") : t("hypervisor.diskInfo.no")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.usable")}</dt>
                            <dd className="col-sm-8">{disk?.usable ? t("hypervisor.diskInfo.yes") : t("hypervisor.diskInfo.no")}</dd>
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.diskInfo.warnings")}</dt>
                            <dd className="col-sm-8">{disk?.unusableReason || t("hypervisor.diskInfo.none")}</dd>
                        </dl>
                    </dd>
                </dl>
            </ModalBody>
        </Modal>
    );
};

export default DiskModal;
