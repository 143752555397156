/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertProfile } from '../models/AlertProfile';
import type { AlertProfileAddOrUpdateRequest } from '../models/AlertProfileAddOrUpdateRequest';
import type { AlertProfileDetailResponse } from '../models/AlertProfileDetailResponse';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { IncidentDetailResponse } from '../models/IncidentDetailResponse';
import type { IncidentListResponse } from '../models/IncidentListResponse';
import type { MonitoringInfoResponse } from '../models/MonitoringInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MonitoringService {

    /**
     * Get the current monitoring info for this Gallium account (available alert types, configured alerts, active incidents)
     * @returns MonitoringInfoResponse OK
     * @throws ApiError
     */
    public static monitoringInfo(): CancelablePromise<MonitoringInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitoring',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new alert
     * @param requestBody
     * @returns AlertProfile OK
     * @throws ApiError
     */
    public static newAlert(
        requestBody: AlertProfileAddOrUpdateRequest,
    ): CancelablePromise<AlertProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitoring/alertProfile/new',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update an existing alert profile
     * @param alertProfileSlug
     * @param requestBody
     * @returns AlertProfile OK
     * @throws ApiError
     */
    public static updateAlertProfile(
        alertProfileSlug: string,
        requestBody: AlertProfileAddOrUpdateRequest,
    ): CancelablePromise<AlertProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/monitoring/alertProfile/{alertProfileSlug}',
            path: {
                'alertProfileSlug': alertProfileSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete an alert profile
     * @param alertProfileSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static deleteAlertProfile(
        alertProfileSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/monitoring/alertProfile/{alertProfileSlug}',
            path: {
                'alertProfileSlug': alertProfileSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get an existing alert profile and associated notifications and incidents
     * @param alertProfileSlug
     * @returns AlertProfileDetailResponse OK
     * @throws ApiError
     */
    public static getAlertProfileDetails(
        alertProfileSlug: string,
    ): CancelablePromise<AlertProfileDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitoring/alertProfile/{alertProfileSlug}/details',
            path: {
                'alertProfileSlug': alertProfileSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Generate a test incident for an alert profile
     * @param alertProfileSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static generateTestIncident(
        alertProfileSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitoring/alertProfile/{alertProfileSlug}/test',
            path: {
                'alertProfileSlug': alertProfileSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * List incidents
     * @param hostSlug Filter incidents to a specific host
     * @returns IncidentListResponse OK
     * @throws ApiError
     */
    public static listIncidents(
        hostSlug?: string,
    ): CancelablePromise<IncidentListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitoring/incidents',
            query: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get details about an incident
     * @param incidentSlug
     * @returns IncidentDetailResponse OK
     * @throws ApiError
     */
    public static getIncidentDetail(
        incidentSlug: string,
    ): CancelablePromise<IncidentDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitoring/incidents/{incidentSlug}',
            path: {
                'incidentSlug': incidentSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Acknowledge an incident
     * @param incidentSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static acknowledgeIncident(
        incidentSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitoring/incidents/{incidentSlug}/acknowledge',
            path: {
                'incidentSlug': incidentSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Close an incident
     * @param incidentSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static closeIncident(
        incidentSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitoring/incidents/{incidentSlug}/close',
            path: {
                'incidentSlug': incidentSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
