import { GalliumApiErrorResponse, ServiceGateway, ServiceGatewayCreateRequest, ServiceGatewayCreateResponse, ServiceGatewayDeleteResponse, ServiceGatewayListResponse, ServiceGatewayService } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useGetListServiceGateways = () => {
    const getListServiceGateways = () => ServiceGatewayService.listServiceGateways()
    
    const { data, error, isLoading } = useSWR<ServiceGatewayListResponse>(`sg`, getListServiceGateways);

    return { data, error, isLoading };
};

export const useCreateServiceGateway = () => {
    const createServiceGateway = (key: string, {arg}:{arg:ServiceGatewayCreateRequest}) => ServiceGatewayService.createServiceGateway(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<ServiceGatewayCreateResponse, GalliumApiErrorResponse, string, ServiceGatewayCreateRequest>(`sg`, createServiceGateway);

    return { trigger, error, isMutating };
};


export const useDeleteServiceGateway = () => {
    const deleteServiceGateway = (key: null, {arg}:{arg:ServiceGateway}) => ServiceGatewayService.deleteServiceGateway(arg.slug)
    
    const { trigger, error, isMutating } = useSWRMutation<ServiceGatewayDeleteResponse, GalliumApiErrorResponse, string, ServiceGateway>(`sg`, deleteServiceGateway);

    return { trigger, error, isMutating };
};

