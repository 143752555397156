import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import DeleteModal from "Components/Common/DeleteModal";
import { useDeleteTemplate } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { useTranslation } from 'react-i18next';
import { Template } from 'generated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { toast } from 'react-toastify';

interface DeleteTemplateButtonProps {
  template: Template;
}

const DeleteTemplateButton: React.FC<DeleteTemplateButtonProps> = ({template}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { trigger, isMutating } = useDeleteTemplate(template.slug);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const handleDeleteTemplateFail = useCallback(() => {
        toast.error(t("template.deleteFail"));
    }, [t]);

    const handleDeleteTemplateSuccess = useCallback(() => {
        toast.success(t("template.deleteSuccess"));
        setShowDeleteModal(false);
        navigate("/inventory/templates");
    }, [t]);

    const handleDeleteTemplateRequest = useCallback(() => {
        trigger(null, {
            onError: handleDeleteTemplateFail,
            onSuccess: handleDeleteTemplateSuccess
        });
    }, [trigger, handleDeleteTemplateFail, handleDeleteTemplateSuccess]);
    
    return (
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteTemplateRequest}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={template.name || t("templates.unknown")}
                isWorking={isMutating}
            />

            <Button
                onClick={() => (setShowDeleteModal(true))}
                className="btn btn-soft-danger"
                aria-label={t("common.delete")}
            >
                <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} className='fa-fw me-2'/>
                {t("common.delete")}
            </Button>
        </React.Fragment>
        )
};

export default DeleteTemplateButton;
