//@ts-nocheck
//TODO refactor into a pop out and see if this can be typed better
import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from 'Components/Gallium/Loader';
import { Button, ButtonGroup, ButtonToolbar, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import VNCViewer from 'Components/Gallium/VNCViewer';
import VMListControls from 'Components/Gallium/VM/VirtualMachineControls';
import { useGetVirtualMachine, useGetVncUrlForVm } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';

const VirtaulMachineConnect = () => {
    const virtualMachineId = useParams().id;

    const { data: virtualMachine, isLoading: isVirtualMachineLoading } = useGetVirtualMachine(virtualMachineId, 12000)
    const { data: VNCUrl } = useGetVncUrlForVm(virtualMachine?.slug || null)

    // VNC Ref
    const vncRef = useRef()

    document.title = "Virtual Machine Connection | Gallium";
    return (
        <React.Fragment>
            {!isVirtualMachineLoading ? (
                <div className='d-flex flex-column justify-content-center align-items-center w-100 bg-dark' style={{ paddingTop: '93px', minHeight: '100vh'}}>

                    <div className="page-title-box d-sm-flex align-items-center justify-content-between w-100 mb-0">
                        <h4 className="mb-sm-0">{virtualMachine.name} - Connect</h4>

                        <ButtonToolbar>
                            <ButtonGroup className='me-2'>
                                <UncontrolledButtonDropdown id='keyboardButtonToggle'>
                                    <DropdownToggle caret className='btn-light'>
                                        <i className="mdi mdi-keyboard-outline label-icon me-1"></i> 
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => vncRef.current?.sendCtrlAltDel()}>CTRL + ALT + DEL</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </ButtonGroup>
                            <ButtonGroup className='me-2'>
                                <VMListControls vm={virtualMachine} />
                            </ButtonGroup>
                            <ButtonGroup className=''>
                                <Link to={"/vm/" + virtualMachine.slug}>
                                    <Button color="light" className="btn-label right">
                                        <i className="mdi mdi-monitor-off label-icon align-middle fs-16 ms-2"></i> 
                                        Disconnect
                                    </Button>
                                </Link>
                            </ButtonGroup>
                        </ButtonToolbar>

                    </div>

                    {virtualMachine.availableActions.console ? (
                        <React.Fragment>
                            { VNCUrl ? (
                                <VNCViewer wss_url={VNCUrl.url} ref={vncRef} />
                            ):(
                                <div className='flex-grow-1 w-100 bg-dark align-items-center' style={{ paddingTop: '200px' }}>
                                    <Loader />
                                </div>
                            )}
                        </React.Fragment>
                    ):(
                        <div className='flex-grow-1 w-100 bg-dark align-items-center text-center' style={{ paddingTop: '200px' }}>
                            <Button disabled outline color="light" className="btn-label"><i className="bx bx-desktop label-icon"></i> Not Available</Button>
                        </div>
                    )}

                </div>
            ):(<Loader />)}
        </React.Fragment>
    )
}

export default VirtaulMachineConnect;