import React, { useState, useContext } from "react";
import { ModalContext } from "./MFAModal";

import { Label,  ModalBody, ModalHeader } from "reactstrap";

import { Col, Row } from 'reactstrap';

import { useUserProfileMFACreateRequest } from "GalliumAPIHooks/User/UserProfileHooks";

import { CreateMfaTotpRequiredResponse, GalliumApiErrorResponse } from 'generated';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field, Form } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";

const Enroll = () => {
    const { response, setResponse, closeModal } = useContext(ModalContext);

    const { trigger, isMutating } = useUserProfileMFACreateRequest();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleEnrollChangeFail = (error) => {
        enrollFormik.setErrors(error.response.data.errors || {})
        setErrorObject(error.response.data || {})
    }

    const handleEnrollChangeSuccess = (data: CreateMfaTotpRequiredResponse) => {
        setResponse(data)
        enrollFormik.resetForm()
    }

    const handleEnrollRequest = (values: object) => {
        const options = {
            onError(error: object) {
                handleEnrollChangeFail(error)
            },
            onSuccess(data: CreateMfaTotpRequiredResponse) {
                handleEnrollChangeSuccess(data)
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const enrollFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            description: '',
            method: "TOTP"
        },
        validationSchema: Yup.object().shape({
            description: Yup.string()
                .required('Description is required')
        }),
        onSubmit: (values) => {
            handleEnrollRequest(values);
        }
    });

    return (    
        <>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
            Add MFA Method
            </ModalHeader>      
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={enrollFormik}>
                    <Row className="g-2 pb-3">
                        <Label>Select Type</Label>
                        <Col md={12}>
                            <div className="form-check card-radio">
                                <Field
                                    id="TOTP"
                                    name="method"
                                    type="radio"
                                    value="TOTP"
                                    className="form-check-input"
                                />
                                <Label
                                    className="form-check-label"
                                    htmlFor="TOTP"
                                    onClick={()=> enrollFormik.setFieldValue("otp", undefined)}
                                >
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 text-center">
                                            <i className="mdi mdi-qrcode mdi-24px me-3"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                            TOTP
                                            </span>
                                            <p className="small text-muted m-0 ">Enroll using QR Code Setup</p>
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-check card-radio">
                                <Field
                                    id="YUBICO_OTP"
                                    name="method"
                                    type="radio"
                                    value="YUBICO_OTP"
                                    className="form-check-input"
                                />
                                <Label
                                    className="form-check-label"
                                    htmlFor="YUBICO_OTP"
                                >
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 text-center">
                                            <i className="mdi mdi-usb-flash-drive-outline mdi-24px me-3"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                            Yubikey
                                            </span>
                                            <p className="small text-muted m-0 ">Enroll using keyboard OTP</p>
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-check card-radio">
                                <Field
                                    id="RECOVERY_CODES"
                                    name="method"
                                    type="radio"
                                    value="RECOVERY_CODES"
                                    className="form-check-input"
                                />
                                <Label
                                    className="form-check-label"
                                    htmlFor="RECOVERY_CODES"
                                    onClick={()=> enrollFormik.setFieldValue("otp", undefined)}
                                >
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 text-center">
                                            <i className="mdi mdi-keyboard-outline mdi-24px me-3"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                            Recovery Codes
                                            </span>
                                            <p className="small text-muted m-0 ">Generate single use codes</p>
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </Col>
                    </Row>
                
                    <Row className="g-2">

                        <Col lg={6}>
                            <Field
                                type="text"
                                id="description"
                                name="description"
                                label="Description"
                                placeholder="Example: Gary's Authy"
                                component={GalliumInput}
                            />
                        </Col>
                        {enrollFormik.values.method === "YUBICO_OTP" ? (
                            <Col lg={6}>
                                <Field
                                    type="text"
                                    id="otp"
                                    name="otp"
                                    label="Yubikey OTP"
                                    placeholder="Press Yubikey Button"
                                    component={GalliumInput}
                                />
                            </Col>
                        ):(null)}
                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={enrollFormik} color="primary" spinner={isMutating}>
                            Next
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </FormikProvider>
            </ModalBody>
        </>
    );
};

export default Enroll