import React, { useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Card,
    CardBody,
    CardHeader,
    Col
} from "reactstrap"

import { GalliumApiErrorResponse, VirtualMachineDetail } from 'generated';

import { useUpdateVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';

import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { toast } from 'react-toastify';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useTranslation } from 'react-i18next';

const VirtualMachineIdentification = ({ virtualMachine }: {virtualMachine: VirtualMachineDetail}) => {
    const {t} = useTranslation()
    const {trigger, isMutating} = useUpdateVirtualMachine(virtualMachine.slug)

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleVirtualMachineChangeFail = (error: GalliumApiErrorResponse) => {
        editVirtualMachineBasicsFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleVirtualMachineChangeSuccess = () => {
        toast.success("Virtual Machine Updated")
    }

    const handleVirtualMachineChangeRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachineChangeFail(error)
            },
            onSuccess() {
                handleVirtualMachineChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik
    const editVirtualMachineBasicsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: virtualMachine.name,
            description: virtualMachine.description,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Virtual Machine must have a name")
        }),
        onSubmit: (values) => {
            // @ts-expect-error
            window.Intercom('trackEvent', 'edit-vm-request');
            handleVirtualMachineChangeRequest(values)
        },
    });
    
    return (
        <Card className='mb-3'>
            <CardHeader>
                <h4 className="card-title mb-0">
                    <FontAwesomeIcon icon={byPrefixAndName.fal["pen-to-square"]} className="me-2"/>
                    {t("virtualMachine.settings.editIdentification")}
                </h4>       
            </CardHeader>
            <CardBody>
                <FormikProvider value={editVirtualMachineBasicsFormik}>
                    <ErrorAlert errorObj={errorObject} />

                    <div className="mt-0 mb-3">
                                
                        <Col lg={4}>
                            <Field
                                name="name"
                                label="VM Name"
                                id="nameField"
                                className="form-control"
                                component={GalliumInput}
                                type="text"
                            />

                        </Col>
                                
                    </div>

                    <div className="mt-3 mb-3">

                        <Field
                            name="description"
                            id="descriptionField"
                            className="form-control"
                            label="Description"
                            type="textarea"
                            component={GalliumInput}
                        />
                            
                    </div>
                    <div className='float-end'>
                        <GalliumSubmitButton formik={editVirtualMachineBasicsFormik} spinner={isMutating} color="success"> Submit</GalliumSubmitButton>
                    </div>

                        
                </FormikProvider>
            </CardBody>
        </Card>
    )
};

export default VirtualMachineIdentification;
