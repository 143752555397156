import React, { useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Card,
    CardBody,
    CardHeader,
    Col
} from "reactstrap"

import { GalliumApiErrorResponse, HostDetail } from 'generated';

import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { toast } from 'react-toastify';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useUpdateHost } from 'GalliumAPIHooks/Host/HostHooks';
import { useTranslation } from 'react-i18next';

const EditBasics = ({ hypervisorDetail }: {hypervisorDetail: HostDetail}) => {
    const {t} = useTranslation()

    const {trigger, isMutating} = useUpdateHost(hypervisorDetail.slug)

    const [errorObject, setErrorObject] = useState(null)

    const handleHypervsiorChangeFail = (error: GalliumApiErrorResponse) => {
        editHypervsiorBasicsFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleHypervsiorChangeSuccess = () => {
        toast.success("Hypervisor Updated")
    }

    const handleHypervsiorChangeRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleHypervsiorChangeFail(error)
            },
            onSuccess() {
                handleHypervsiorChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik
    const editHypervsiorBasicsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: hypervisorDetail.name,
            description: hypervisorDetail.description,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Hypervisor must have a name")
        }),
        onSubmit: (values) => {
            // @ts-expect-error
            window.Intercom('trackEvent', 'edit-vm-request');
            handleHypervsiorChangeRequest(values)
        },
    });
    
    return (
        <React.Fragment>
            <Card className='mb-2'>
                <CardHeader >
                    <h4 className="card-title mb-0">{t("hypervisor.settings.editIdentification")}</h4>
                </CardHeader>

                <CardBody >  
                    <FormikProvider value={editHypervsiorBasicsFormik}>
                        <ErrorAlert errorObj={errorObject} />

                        <div className="mt-0 mb-3">
                                    
                            <Col lg={4}>
                                <Field
                                    name="name"
                                    label={t("hypervisor.settings.name")}
                                    id="nameField"
                                    className="form-control"
                                    component={GalliumInput}
                                    type="text"
                                />

                            </Col>
                                    
                        </div>

                        <div className="mt-3 mb-3">

                            <Field
                                name="description"
                                id="descriptionField"
                                className="form-control"
                                label={t("hypervisor.settings.description")}
                                type="textarea"
                                component={GalliumInput}
                            />
                                
                        </div>
                        <div className='float-end'>
                            <GalliumSubmitButton formik={editHypervsiorBasicsFormik} spinner={isMutating} color="success"> 
                                {t("globals.submit")}
                            </GalliumSubmitButton>
                        </div>

                            
                    </FormikProvider>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default EditBasics;
