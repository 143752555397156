import React from "react";
import VMListControls from "../VM/VirtualMachineControls";

const ColVmControls = (cell) => {
    return (
        <React.Fragment>
            <div className="text-end">
                <VMListControls vm={cell.row.original}/>
            </div>
        </React.Fragment>
    );
};

export default ColVmControls