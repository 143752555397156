import React from 'react';


import Loader from 'Components/Gallium/Loader';

import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import HypervisorMetrics from './Metrics';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import HypervisorMemoryUsageDetailed from './MemoryUsageDetailed';
import HypervisorStorageWarning from '../Warnings/StorageWarning';
import HypervisorKVMWarning from '../Warnings/KVMWarning';
import HypervisorUpdateWarning from '../Warnings/UpdateWarning';
import HypervisorQuickInfo from './QuickInfo';


type HypervisorDashboardProps = {
  hypervisorSlug: string;
};

const HypervisorDashboard: React.FC<HypervisorDashboardProps> = ({ hypervisorSlug }) => {
    const { t } = useTranslation()
    const {data: hypervisorDetail, error, isLoading } = useGetHost(hypervisorSlug)

    return (
        <GalliumPageColumn>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <React.Fragment>
                    <HypervisorStorageWarning  show={hypervisorDetail.storagePools.length === 0}/>
                    <HypervisorKVMWarning  show={hypervisorDetail.kvmMissing}/>
                    <HypervisorUpdateWarning  hypervisorDetail={hypervisorDetail}/>
                    <HypervisorQuickInfo hypervisorDetail={hypervisorDetail}/>
                    <HypervisorMetrics />
                    <HypervisorMemoryUsageDetailed hypervisorDetail={hypervisorDetail} />
                </React.Fragment>
            )}
        </GalliumPageColumn>
    )
};

export default HypervisorDashboard