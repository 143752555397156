import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//SimpleBar
import SimpleBar from "simplebar-react";

import FormatCommand from './FormatCommand';
import StatusBadge from './StatusBadge';
import { useGetListCommands } from 'GalliumAPIHooks/Commands/CommandsHooks';
import { ApiCmdStatus } from 'generated';


const CommandsDropdown = () => {
    const { data } = useGetListCommands();

    const runningCommands = [];
    const completedCommands = [];

    if (data) {
        data.commands.forEach(command => {
            // Commands with status IN_PROGRESS or PENDING are considered running
            if (command.status === ApiCmdStatus.IN_PROGRESS || command.status === ApiCmdStatus.PENDING) {
                runningCommands.push(command);
            } else {
                // All other statuses are considered as indicating a completed command
                if (completedCommands.length < 5) {
                    completedCommands.push(command);
                }
            }
        });
    }


    //Dropdown Toggle
    const [isCommandsDropdown, setIsCommandsDropdown] = useState(false);
    const toggleCommandsDropdown = () => {
        setIsCommandsDropdown(!isCommandsDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isCommandsDropdown} toggle={toggleCommandsDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-task fs-22'></i>
                    {runningCommands.length > 0 ? (
                        <span
                            className="position-absolute topbar-badge translate-middle badge rounded-pill bg-primary">{runningCommands.length}<span
                                className="visually-hidden">active tasks</span></span>
                    ):(null)}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Tasks </h6>
                                </Col>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        Active {runningCommands.length}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Recent
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {runningCommands.length > 0 ? (
                                    runningCommands.map((command, key) => (
                                        <React.Fragment key={key}>
                                            <div className="text-reset notification-item d-block dropdown-item position-relative">
                                                <div className="d-flex">
                                                    <div className="flex-1">
                                                        <StatusBadge status={command.status} />

                                                        <h6 className="mt-2 mb-0 lh-base">
                                                            <FormatCommand command={command} />
                                                        </h6>

                                                        {command.entityName ? (
                                                            <p className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> {command.entityName} {command.progressMessage}</p>
                                                        ):(null)}

                                                        <p className="mb-0 mt-1 fs-11 fw-medium text-uppercase text-muted">
                                                            <span><i className="mdi mdi-clock-outline"></i> Requested {command.issuedAtDisplay}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                ):(
                                    <div className="my-3 p-5 text-center">
                                        <p className='text-muted'>No Active Tasks</p>
                                    </div>
                                )}
                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {completedCommands.length > 0 ? (
                                    completedCommands.map((command, key) => (
                                        <React.Fragment key={key}>
                                            <div className="text-reset notification-item d-block dropdown-item position-relative">
                                                <div className="d-flex">
                                                    <div className="flex-1">
                                                        <StatusBadge status={command.status} />

                                                        <h6 className="mt-2 mb-0 lh-base">
                                                            <FormatCommand command={command} />
                                                        </h6>

                                                        {command.entityName ? (
                                                            <p className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> {command.entityName}</p>
                                                        ):(null)}

                                                        <p className="mb-0 mt-1 fs-11 fw-medium text-uppercase text-muted">
                                                            <span><i className="mdi mdi-clock-outline"></i> Requested {command.issuedAtDisplay}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                ):(
                                    <div className="my-3 p-5 text-center">
                                        <p className='text-muted'>No Completed Tasks</p>
                                    </div>
                                )}
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default CommandsDropdown;