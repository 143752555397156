/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BackupTaskState {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
    DELETED = 'DELETED',
}
