//@ts-nocheck
import crc32 from "crc-32";

const BUFFER_LOW_THRESHOLD = 256*1024;

const MAX_SEND_CHUNK_SIZE = 65500;
const HEADER_LEN = 32;


const MAGIC_NUM_DATA = BigInt(1);
const MAGIC_NUM_EOF = BigInt(2);
const MAGIC_NUM_RECV_SUCCESS = BigInt(3);


const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

class FtmProvider {
    private static instance: Ftm;

    private static hostMessageFn: (hostSlug: string, message: string) => Promise<string>;

    public static getInstance(): Ftm {
        if (!FtmProvider.instance) {
            FtmProvider.instance = new Ftm();
        }
        (window as any).foo = FtmProvider.instance;

        return FtmProvider.instance;
    }

    public static setHostMessageFn(fn: (hostSlug: string, message: string) => Promise<string>) {
        FtmProvider.hostMessageFn = fn;
    }

    static getHostMessageFn(): (hostSlug: string, message: string) => Promise<string> {
        return FtmProvider.hostMessageFn;
    }

    static isDownloadAvailable(): boolean {
        return 'showSaveFilePicker' in window;
    }
}

class AvailableFile {
    id = "";
    name = "";
    size = 0;
}

class FileListEntry {
    hostId = "";
    id = "";
    name = "";
    size = 0;
}

abstract class TransferTask {
    completed = false;
    failed = false;
    bytePosition = 0;
    pct = 0;

    abstract name(): string;
}

class UploadTask extends TransferTask {
    fileHandle: FileSystemFileHandle;
    dataChannel: RTCDataChannel;

    constructor(fileHandle: FileSystemFileHandle, dataChannel: RTCDataChannel) {
        super();
        this.fileHandle = fileHandle;
        this.dataChannel = dataChannel;
        this.type = "upload"
    }

    name(): string {
        return this.fileHandle.name;
    }

    async beginUpload() {
        this.dataChannel.onmessage = async ev => await this.onMessage(ev);
        const file = await this.fileHandle.getFile();
        this.dataChannel.send(JSON.stringify({UploadFile: this.fileHandle.name}));

        while (this.bytePosition < file.size) {
            const endPosition = Math.min(this.bytePosition + MAX_SEND_CHUNK_SIZE, file.size);
            const chunkBlob = file.slice(this.bytePosition, endPosition);
            const chunkBuf = await chunkBlob.arrayBuffer();
            const chunkCrc = crc32.buf(new Uint8Array(chunkBuf), 0);
            const sendBuffer = new ArrayBuffer(HEADER_LEN + chunkBuf.byteLength);
            const sendBufferView = new DataView(sendBuffer);
            sendBufferView.setBigUint64(0, MAGIC_NUM_DATA, true);
            sendBufferView.setBigUint64(8, BigInt(this.bytePosition), true);
            sendBufferView.setInt32(16, chunkCrc, true);

            //TODO: is there a better way to achieve this? seems like something a utility fn should exist for.
            // At least the JIT compiler should figure out that this can be optimised to a memcpy, probably.
            const chunkArray = new Uint8Array(chunkBuf);
            for (const ii = 0; ii < chunkArray.length; ii++) {
                sendBufferView.setUint8(ii + HEADER_LEN, chunkArray[ii]);
            }

            // dataChannel.send(...) is not async, it copies the data to be sent into a buffer and returns immediately.
            // It will throw an exception if there is too much data in the buffer already, so loop until the buffered
            // amount is below a threshold.
            //
            // The 'proper' way to achieve this is to set a dataChannel.onbufferedamountlow, but this works fine for now
            while (this.dataChannel.bufferedAmount > BUFFER_LOW_THRESHOLD && this.dataChannel.readyState === "open") {
                await sleep(1);
            }
            //The other exceptions this may throw still need to be handled
            this.dataChannel.send(sendBuffer);

            this.bytePosition = endPosition;
            this.pct = (this.bytePosition / file.size) * 100;

            FtmProvider.getInstance().notifyListeners(); // Notify listeners on progress update
        }

        const eofSendBuffer = new ArrayBuffer(HEADER_LEN);
        const eofSendBufferView = new DataView(eofSendBuffer);
        eofSendBufferView.setBigUint64(0, MAGIC_NUM_EOF, true);
        eofSendBufferView.setBigUint64(8, BigInt(this.bytePosition), true);
        this.dataChannel.send(eofSendBuffer);
    }

    async onMessage(evt: MessageEvent<ArrayBuffer>) {
        const header = new DataView(evt.data, 0, 32);
        if (header.getBigUint64(0, true) === MAGIC_NUM_RECV_SUCCESS) {
            this.completed = true;
            FtmProvider.getInstance().notifyListeners(); // Notify listeners on completion
        } else {
            console.log("message received on complete channel without RECV_SUCCESS");
        }
    }
}

class DownloadTask extends TransferTask {
    file: AvailableFile;
    fileHandle: FileSystemFileHandle;
    dataChannel: RTCDataChannel;

    writableStream: any;

    constructor(file: AvailableFile, fileHandle: FileSystemFileHandle, dataChannel: RTCDataChannel) {
        super();
        this.file = file;
        this.fileHandle = fileHandle;
        this.dataChannel = dataChannel;
        this.type = "download"
    }

    name(): string {
        return this.file.name;
    }

    onFailure() {
        this.failed = true;
        this.dataChannel.close();
        this.writableStream.truncate(0);
        this.writableStream.close();
        FtmProvider.getInstance().notifyListeners(); // Notify listeners on failure
    }

    async beginDownload() {
        this.writableStream = await (this.fileHandle as any).createWritable();
        this.dataChannel.onmessage = async (evt: MessageEvent<ArrayBuffer>) => {
            if (evt.data.byteLength < 32) {
                console.log("error: message too short");
                this.onFailure();
            }
            const header = new DataView(evt.data, 0, 32);
            if (header.getBigUint64(8, true) !== BigInt(this.bytePosition)) {
                console.log("error: byte position mismatch", header.getBigUint64(8, true), this.bytePosition);
                this.onFailure();
            } else if (header.getBigUint64(0, true) === BigInt(1)) {
                const expectedCrc = header.getInt32(16, true);
                const actualCrc = crc32.buf(new Uint8Array(evt.data.slice(32)), 0);
                if (expectedCrc !== actualCrc) {
                    console.log("error: crc mismatch", expectedCrc, actualCrc);
                    this.onFailure();
                    return;
                }
                this.bytePosition += (evt.data.byteLength - 32);
                this.writableStream.write(evt.data.slice(32));
                this.pct = (this.bytePosition / this.file.size) * 100;
                FtmProvider.getInstance().notifyListeners(); // Notify listeners on progress update
            } else if (header.getBigUint64(0, true) === BigInt(2)) {
                this.writableStream.close();
                this.dataChannel.close();
                this.completed = true;
                alert("done");
                FtmProvider.getInstance().notifyListeners(); // Notify listeners on completion
            }
        };
        this.dataChannel.send(JSON.stringify({DownloadFile: this.file.id}));
    }
}

class HostConnection {
    peerConnection: RTCPeerConnection;
    controlChannel: RTCDataChannel;
    fileList: AvailableFile[] = [];
    transferTasks: TransferTask[] = [];

    constructor(peerConnection: RTCPeerConnection, controlChannel: RTCDataChannel) {
        this.peerConnection = peerConnection;
        this.controlChannel = controlChannel;
    }

    onMessage(event: MessageEvent) {
        if (typeof event.data === "string") {
            console.log(event.data)
            const body = JSON.parse(event.data);
            if (body.FileList !== undefined) {
                this.fileList = body.FileList as AvailableFile[];
            }
        } else {
            (window as any).tstevt = event;
            console.log("unhandled message");
        }
    }
}

class RemoteSessionDescription {
    sessionDescription: object = {}
}

class Ftm {
    private hosts: Map<string, HostConnection>;

    constructor() {
        this.hosts = new Map<string, HostConnection>();
        this.listeners = [];
    }

    public addListener(listener: Function) {
        this.listeners.push(listener);
    }

    public removeListener(listener: Function) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    private notifyListeners() {
        this.listeners.forEach(listener => listener());
    }

    public getConnectedHostCount(): number {
        return this.hosts.size;
    }

    public getFileList(): FileListEntry[] {
        const files: FileListEntry[] = [];
        this.hosts.forEach((host, hostId) => {
            host.fileList.forEach(file => {
                files.push({hostId: hostId, id: file.id, name: file.name, size: file.size});
            });
        });

        return files;
    }

    public getHostFileList(hostSlug: string): FileListEntry[] {
        const files: FileListEntry[] = [];
        const host = this.hosts.get(hostSlug);
        if (host) {
            host.fileList.forEach(file => {
                files.push({ hostId: hostSlug, id: file.id, name: file.name, size: file.size });
            });
        } else {
            console.error(`Host with slug ${hostSlug} not found`);
        }
        return files;
    }

    public hostAvailable(hostId: string): boolean {
        const host = this.hosts.get(hostId);
        if (host instanceof HostConnection) {
            return host.controlChannel.readyState === "open";
        } else {
            return false;
        }
    }

    public async connectToHost(hostId: string) {
        const peerConnection = new RTCPeerConnection({
            iceServers: [
            ]
        });


        peerConnection.onicecandidate = async event => {
            console.log("onicecandidate", event);
            if (event.candidate === null) {
                const sessionDescriptionStr = JSON.stringify({
                    browserInitiate: true,
                    sessionDescription: peerConnection.localDescription
                });
                const remoteSessionDescription: RemoteSessionDescription = JSON.parse(await FtmProvider.getHostMessageFn().call(null, hostId, sessionDescriptionStr));
                console.log("remoteSessionDescription", remoteSessionDescription);
                await peerConnection.setRemoteDescription(remoteSessionDescription.sessionDescription as RTCSessionDescriptionInit);
                console.log("set remote description");
            }
        };
        peerConnection.onnegotiationneeded = async event => {
            console.log("onnegotiationneeded", event);
            const offer = await peerConnection.createOffer();
            try {
                await peerConnection.setLocalDescription(offer);
            } catch (e) {
                console.log("error setting local description", e);
            }
        }

        const dataChannel = peerConnection.createDataChannel("gallium-ft-control");
        dataChannel.onopen = (ev) => {
            dataChannel.send("\"ListFiles\"");
        }
        dataChannel.onmessage = async (evt) => {
            hostConnection.onMessage(evt);
            this.notifyListeners(); // Notify listeners when a message is received
        };

        const hostConnection = new HostConnection(peerConnection, dataChannel);
        this.hosts.set(hostId, hostConnection);
        this.notifyListeners();

    }


    public async beginDownload(hostId: string, fileId: string) {
        console.log('fileId:', fileId)
        const host = this.hosts.get(hostId) as HostConnection;
        console.log(host)
        const file = host.fileList.filter(f => f.id === fileId)[0];
        console.log(file)
        const fileHandle: FileSystemFileHandle = await (window as any).showSaveFilePicker({
            startIn: "downloads",
            suggestedName: file.name,
        });
        const dataChannel = host.peerConnection.createDataChannel("gallium-ft-data");
        const transferTask = new DownloadTask(file, fileHandle, dataChannel);
        host.transferTasks.push(transferTask);
        await transferTask.beginDownload();

        this.notifyListeners();
    }

    public async beginUpload(hostId: string) {
        const host = this.hosts.get(hostId) as HostConnection;
        const fileHandles: FileSystemFileHandle[] = await (window as any).showOpenFilePicker({
            types: [{
                description: "Qcow2 Image File",
                accept: {
                    "application/octet-stream": [".qcow2"],
                },
            }],
            id: "gallium-qcow2-upload",
            multiple: false,
        });
        const dataChannel = host.peerConnection.createDataChannel("gallium-ft-data");
        const transferTask = new UploadTask(fileHandles[0], dataChannel);
        host.transferTasks.push(transferTask);
        await transferTask.beginUpload();

        this.notifyListeners(); 
    }

    allTransferTasks(): TransferTask[] {
        const transferTasks: TransferTask[] = [];
        this.hosts.forEach((h) => h.transferTasks.forEach(t => transferTasks.push(t)));
        return transferTasks;
    }
}

export default FtmProvider;
