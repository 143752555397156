import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';


const SupportDropdown = () => {

    const [userLinks, setUserLinks] = useState([]);


    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setUserLinks(obj.otherLinks === undefined ? [] : obj.otherLinks);
        }
    }, []);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    
    return (
        userLinks.length > 0 ? (
            <React.Fragment>
                <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="topbar-head-dropdown ms-1 header-item">
                    <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                        <i className='bx bx-category fs-22'></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {userLinks.map((item, index)=>(
                            <Link to={item.url} key={index}>
                                <DropdownItem>
                                    <span className="align-middle">{item.name}</span>
                                </DropdownItem>
                            </Link>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        ) : (null)
    );

    return null
    
};

export default SupportDropdown;