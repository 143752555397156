import React from 'react';

import { Field, useFormikContext } from 'formik';
import { AlertType } from 'generated';

const GalliumArrayCheckbox = ({ name, value, label, ...props }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const isChecked = field.value.includes(value);
                
                return (
                    <div className='form-check mb-2'>
                        <label className="form-check-label fw-normal" htmlFor={"formCheck1" + value}>{label}</label>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"formCheck1" + value}
                            checked={isChecked}
                            onChange={() => {
                                if (isChecked) {
                                    const nextValue = field.value.filter(item => item !== value);
                                    form.setFieldValue(name, nextValue);
                                } else {
                                    const nextValue = [...field.value, value];
                                    form.setFieldValue(name, nextValue);
                                }
                            }}
                            {...props}
                        />
                    </div>
                );
            }}
        </Field>
    );
};

export {GalliumArrayCheckbox};
