import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { HostDetail} from "generated";
import { Link } from "react-router-dom";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DiskInfoModalProps {
    hypervisor: HostDetail,
}

const AddStoragePoolCard: React.FC<DiskInfoModalProps> = ({ hypervisor }) => {
    const { t } = useTranslation();
    if (!hypervisor?.online || hypervisor.storagePools.length > 0) return (null)
    return ( 
        <Card className="mb-2">
            <CardBody className="p-5 mt-5 mb-5 text-center">
                <Link to={`/hypervisors/${hypervisor.slug}/storage`} className="text-dark">
                    <Button color="primary">
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-circle-plus"]} className='me-2'/> 
                        {t("hypervisor.storagePool.createPoolButton")}
                    </Button>
                </Link>
            </CardBody>
        </Card>
    );
}

export default AddStoragePoolCard