import React from 'react';
import {
    Label,
} from "reactstrap"
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import { useTranslation } from 'react-i18next';

const TemplateSettings = () => {
    const {t} = useTranslation()
    
    return (
        <GalliumFormHeader title={t("templates.createTemplateForm.title.settings")} icon="settings">
            <Label className="form-label" htmlFor="memory-input">{t("templates.createTemplateForm.labels.minimumMemory")}</Label>
            <GalliumSizeInput
                name="minMemoryMb"
                id="memory-input"
            />
        </GalliumFormHeader>
    )
};

export default TemplateSettings;
