import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Loader from "Components/Gallium/Loader";

import {
    Row,
} from "reactstrap";

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { useTranslation } from 'react-i18next';
import SingleSelectCard from 'Components/Gallium/Forms/SingleSelectCard';
import { useListTemplates } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const CreateVMSelectTemplate = () => {
    const {hostSlug} = useParams()
    const {t} = useTranslation()    
    const {data:templateList, isLoading: isTemplateListLoading, error: templateListError} = useListTemplates()
    const navigate = useNavigate()

  
    const selectTemplateFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            templateSlug: null
        },
        validationSchema: Yup.object({
            templateSlug: Yup.string().required("Please Select A Template"),
        }),
        onSubmit: (values) => {
            navigate(`/vm/create/${hostSlug}/${values["templateSlug"]}`)
        },
    });
    return (    
        <FormikProvider value={selectTemplateFormik}>
            {isTemplateListLoading ? (<Loader />) : templateListError ? (<ErrorNotice />) : (
                <React.Fragment>
                    <div>
                        <h5 className="mb-1">{t("virtualMachine.create.templateSelectionHeader")}</h5>
                        <p className="text-muted mb-4">
                        {t("virtualMachine.create.templateSelectionText")}
                        </p>
                    </div>

                    <div>
                        <Row className="g-4">
                            {templateList.templates.map((template) => (
                            <SingleSelectCard
                                id={`selectHypervisorCard$-${template.slug}`}
                                name="templateSlug"
                                value={template.slug}
                                labelTitle={template.name}
                                labelText={template.description}
                                key={template.slug}
                                width={6}
                            />
                            ))}
                        </Row>

                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <Link to="/vm/create" className="btn btn-link text-decoration-none">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-left"]} className='me-2' />
                                {t("virtualMachine.create.backToHost")}
                            </Link>
                            <GalliumSubmitButton formik={selectTemplateFormik} color="primary" spinner={false}>
                                {t("virtualMachine.create.proceedToSettings")}
                                <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-right"]} className='ms-2' />
                            </GalliumSubmitButton>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </FormikProvider>
    );
};

export default CreateVMSelectTemplate;