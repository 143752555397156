import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

import { AlertProfile, AlertProfileAddOrUpdateRequest, AlertProfileDetailResponse, GalliumApiErrorResponse, GalliumApiSuccessResponse, IncidentDetailResponse, MonitoringInfoResponse, MonitoringService } from "generated";

export const useMonitoringInfo = () => {
    const monitoringInfo = () => MonitoringService.monitoringInfo()
    
    const { data, error, isLoading } = useSWR<MonitoringInfoResponse>(`monitoring`, monitoringInfo);

    return { data, error, isLoading };
};

export const useGetAlertProfileDetails = (slug: string) => {
    const getAlertProfileDetails = () => MonitoringService.getAlertProfileDetails(slug)
    
    const { data, error, isLoading } = useSWR<AlertProfileDetailResponse>(`monitoring/alertProfile/${slug}`, getAlertProfileDetails);

    return { data, error, isLoading };
};

export const useGetIncidentDetail = (incidentSlug: string) => {
    const getIncidentDetail = () => MonitoringService.getIncidentDetail(incidentSlug)
    
    const { data, error, isLoading } = useSWR<IncidentDetailResponse>(`monitoring/${incidentSlug}`, getIncidentDetail);

    return { data, error, isLoading };
};


export const useNewAlert = () => {
    const newAlert = (key: string, {arg}: {arg: AlertProfileAddOrUpdateRequest}) => MonitoringService.newAlert(arg)

    const { trigger, error, isMutating } = useSWRMutation<AlertProfile, GalliumApiErrorResponse, string, AlertProfileAddOrUpdateRequest>(`monitoring`, newAlert);

    return { trigger, error, isMutating };
};

export const useUpdateAlertProfile = (slug) => {
    const updateAlertPorofile = (key: string, {arg}: {arg: AlertProfileAddOrUpdateRequest}) => MonitoringService.updateAlertProfile(slug, arg)

    const { trigger, error, isMutating } = useSWRMutation<AlertProfile, GalliumApiErrorResponse, string, AlertProfileAddOrUpdateRequest>(`monitoring/alertProfile/${slug}`, updateAlertPorofile);

    return { trigger, error, isMutating };
};

export const useDeleteAlertProfile = (slug) => {
    const deleteAlertProfile = (key: string) => MonitoringService.deleteAlertProfile(slug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`monitoring`, deleteAlertProfile);

    return { trigger, error, isMutating };
};

export const useGenerateTestIncident = (slug: string) => {
    const generateTestIncident = (key: string) => MonitoringService.generateTestIncident(slug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`monitoring`, generateTestIncident);

    return { trigger, error, isMutating };
};


export const useAcknowledgeIncident = (slug) => {
    const acknowledgeIncident = (key: string) => MonitoringService.acknowledgeIncident(slug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`monitoring`, acknowledgeIncident);

    return { trigger, error, isMutating };
};

export const useCloseIncident = (slug) => {
    const closeIncident = (key: string) => MonitoringService.closeIncident(slug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`monitoring`, closeIncident);

    return { trigger, error, isMutating };
};
