import React from 'react';

import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { SetUserFlagButton, UserFlags } from 'Components/Gallium/Flags';
import { useGetDownloads } from 'GalliumAPIHooks/Downloads/DownloadHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';

const GettingStarted = () => {

    const {data, isLoading, error} = useGetDownloads()

    const trackDownload = () => {
        //@ts-expect-error
        window.Intercom('trackEvent', 'download-image');
    }

    return (    
        <React.Fragment>
            <UserFlags flag="HIDE_GETTING_STARTED_3OCT2023" renderIfExists={false}>
                <Card>
                    <CardBody>
                        {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                            <div className="text-muted">
                                <h5 className="fs-12 text-uppercase text-primary">Getting Started</h5>
                                <h4 className="mb-3">Deploying your first Gallium Hypervisor</h4>
                                <p className="mb-3 ff-secondary">The following resources will help you deploy your first Hypervisor, configure Storage, and deploy a Virtual Machine. Please contact support if you require additional help getting up and running.</p>
                                <SetUserFlagButton flag="HIDE_GETTING_STARTED_3OCT2023" className="mb-4">Hide this Guide</SetUserFlagButton>
                                <Row>
                                    <Col sm={12}>

                                        <div className="vstack gap-2">
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                        1
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-3 mt-1">Prepare a Server</h5>
                                                    <p className='text-muted'>Gallium allows you to orchestrate Virtual Machines on hardware that you own or rent. The first thing you need is a server to deploy the Hypervisor on. Most modern servers are suitable for production. For evaluation purposes you can also use a Desktop PC or Virtual Machine with embedded virtualisation enabled. </p>
                                                    <ListGroup className="list-group-fill-primary">
                                                        <ListGroupItem tag="a" target="_blank" href="https://docs.gallium.cloud/en/articles/8001807-deploying-a-gallium-hypervisor" className="list-group-item-action"><i className="ri-file-list-2-line align-middle me-2"></i>Deploying Gallium</ListGroupItem>
                                                        <ListGroupItem tag="a" target="_blank" href="https://docs.gallium.cloud/en/articles/8325173-hardware-compatibility-guide" className="list-group-item-action"><i className="ri-file-list-2-line align-middle me-2"></i>Hardware Compatibility Guide</ListGroupItem>
                                                        {/* <ListGroupItem tag="a" target="_blank" href="https://gallium.b-cdn.net/images/gallium-os-2023-07-05-2241.img.gz" className="list-group-item-action"><i className="ri-file-list-2-line align-middle me-2"></i>Deploying Gallium on OVH</ListGroupItem> */}
                                                    </ListGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                        2
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-3 mt-2">Flash Boot Media</h5>
                                                    <p className='text-muted'>You will need to download the Gallium boot media and flash it to a suitable USB or SD Card storage device. Or, if using a hosting provider; you can use their imaging or flashing tools to apply the image.   </p>
                                                    <ListGroup className="list-group-fill-primary">
                                                        <ListGroupItem tag="a" href={data?.baseImage?.url} className="list-group-item-action"><i className="ri-download-2-fill align-middle me-2" onClick={trackDownload}></i>Gallium Boot Image {`(${data.baseImage.sizeMb}MB)`}</ListGroupItem>
                                                        <ListGroupItem tag="a" target="_blank" href="https://etcher.balena.io/#download-etcher" className="list-group-item-action"><i className="ri-external-link-line align-middle me-2"></i>Etcher (Third Party)</ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                        3
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-3 mt-2">Join & Configure</h5>
                                                    <p className='text-muted'>Once your server is booted it will display a Join Code on the screen which you can use to add the Hypervisor to your account. You can also scan the displayed QR code to add the Hypervisor on mobile. Once added you will be prompted to configure a Storage Pool. </p>
                                                    <ListGroup className="list-group-fill-primary">
                                                        <ListGroupItem tag="a" target="_blank" href="https://docs.gallium.cloud/en/articles/8002015-storage-pools" className="list-group-item-action"><i className="ri-file-list-2-line align-middle me-2"></i>Storage Guide</ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                        4
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-3 mt-2">Deploy Virtual Machines</h5>
                                                    <p className='text-muted'>Your server is now ready to deploy Virtual Machines. You can choose from our pre-built image library or upload your own into Templates. Virtual Machines can optionally be provisioned using Cloudinit or Bash Scripts to automate deployments.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </UserFlags>
        </React.Fragment>
    );
};

export default GettingStarted;