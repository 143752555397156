import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import TableContainer from 'Components/Gallium/TableContainerNew';

import {
    Button,
    Card,
    CardBody,
    Container,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useMonitoringInfo } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertProfilesList = () => {
    const {t} = useTranslation()
    // Get the SSH Key List
    const { data, error, isLoading } = useMonitoringInfo()

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-12",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to={cellProps.row.original.slug} className="fw-medium link-primary">{cellProps.getValue()}</Link>
                        </React.Fragment>
                    );
                },
            }
        ],[]
    );

    document.title = "Alert Profiles | Gallium";
    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("alertProfiles.title"),
        }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("alertProfiles.list")} crumbs={crumbs}>
                        <Link to="/settings/alerts/add">
                            <Button color="soft-primary" className="add-btn me-1">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["circle-plus"]} className='me-2'/>
                                {t("alertProfiles.add")}
                            </Button>
                        </Link>
                    </GalliumBreadcrumb>
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <Card>
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(data?.alertProfiles || [])}
                                    isGlobalFilter={true}
                                    customPageSize={20}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for an Alert Profile...'
                                />
                            </CardBody>
                        </Card>
                    )}            
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesList;