/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceGatewayCreateRequest } from '../models/ServiceGatewayCreateRequest';
import type { ServiceGatewayCreateResponse } from '../models/ServiceGatewayCreateResponse';
import type { ServiceGatewayDeleteResponse } from '../models/ServiceGatewayDeleteResponse';
import type { ServiceGatewayListResponse } from '../models/ServiceGatewayListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceGatewayService {

    /**
     * List service gateways in your Gallium account
     * @returns ServiceGatewayListResponse OK
     * @throws ApiError
     */
    public static listServiceGateways(): CancelablePromise<ServiceGatewayListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sg',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new service gateway in your Gallium account
     * @param requestBody
     * @returns ServiceGatewayCreateResponse OK
     * @throws ApiError
     */
    public static createServiceGateway(
        requestBody: ServiceGatewayCreateRequest,
    ): CancelablePromise<ServiceGatewayCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sg',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete a service gateway from your Gallium account
     * @param sgSlug
     * @returns ServiceGatewayDeleteResponse OK
     * @throws ApiError
     */
    public static deleteServiceGateway(
        sgSlug: string,
    ): CancelablePromise<ServiceGatewayDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sg/{sgSlug}',
            path: {
                'sgSlug': sgSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
