import { GalliumSpinnerButton } from "Components/Gallium/GalliumForms";
import { useAcknowledgeIncident, useCloseIncident } from "GalliumAPIHooks/Monitoring/MonitoringHooks";
import React from "react";
import { Button } from "reactstrap";
import { MonitoringIncident } from "generated";


interface IncidentControlsProps {
  incident: MonitoringIncident
  className?: string; 
}

const IncidentControls: React.FC<IncidentControlsProps> = ({incident, className}) => {
    const {trigger: triggerAcknowledgeIncident, isMutating: ackMutating} = useAcknowledgeIncident(incident.slug)
    const {trigger: triggerCloseIncident, isMutating: closeMutating} = useCloseIncident(incident.slug)

    return (
        <div className="text-end">
            {incident?.availableActions.acknowledge ? (
                ackMutating ? (
                    <GalliumSpinnerButton color="warning" className={className}/>
                ):(
                    <Button color="warning" className={`btn-soft-warning ${className}`} onClick={()=> triggerAcknowledgeIncident()}>
                        Acknowledge
                    </Button>
                )        
            ):(null)}
            {incident?.availableActions.close ? (
                closeMutating ? (
                    <GalliumSpinnerButton color="warning" className={`btn-soft-warning ${className} ms-2`}/>
                ):(
                    <Button color="warning" className={`${className} ms-2`} onClick={()=> triggerCloseIncident()}>
                        Close
                    </Button>
                )           
            ):(null)}
        </div>
    );
};

export default IncidentControls;