import { GalliumApiSuccessResponse, ListVmResponse, ReconfigureVmRequest, VirtualMachineDetail, VirtualMachineService, UpdateVmRequest, VmPowerStateTransition, VncUrlResponse, GalliumApiErrorResponse } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useDestroyVirtualMachine = (vmSlug: string) => {
    const destroyVirtualMachine = (key: null) => VirtualMachineService.destroyVirtualMachine(vmSlug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`vm`, destroyVirtualMachine);

    return { trigger, error, isMutating };
};

export const useGetVirtualMachines = (pollInterval?: number) => {
    const getListVms = () => VirtualMachineService.listVms()
    
    const { data, error, isLoading } = useSWR<ListVmResponse>(`vm`, getListVms, {
        refreshInterval: pollInterval || 0,
    });

    return { data, error, isLoading };
};

export const useGetVirtualMachine = (vmSlug: string, pollInterval?: number) => {
    const getVirtualMachine = () => VirtualMachineService.getVirtualMachine(vmSlug)
    
    const { data, error, isLoading } = useSWR<VirtualMachineDetail>(`vm/${vmSlug}`, getVirtualMachine, {
        refreshInterval: pollInterval || 0,
    });

    return { data, error, isLoading };
};

export const useGetVncUrlForVm = (vmSlug: string | null) => {
    const getVncUrlForVm = () => {
        if (vmSlug) {
            return VirtualMachineService.getVncUrlForVm(vmSlug);
        }
        return Promise.resolve(null);
    };
    
    const { data, error, isLoading } = useSWR<VncUrlResponse>(`vm/${vmSlug}/vnc`, getVncUrlForVm, {
        revalidateOnFocus: false,
    });

    return { data, error, isLoading };
};

export const useModifyVirtualMachineConfig = (vmSlug: string) => {
    const modifyVirtualMachineConfig = (key: string, {arg}: {arg: ReconfigureVmRequest}) => VirtualMachineService.modifyVmConfig(vmSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, ReconfigureVmRequest>(`vm/${vmSlug}`, modifyVirtualMachineConfig);

    return { trigger, error, isMutating };
};

export const useUpdateVirtualMachine = (vmSlug: string) => {
    const updateVirtualMachine = (key: string, {arg}: {arg: UpdateVmRequest}) => VirtualMachineService.updateVirtualMachine(vmSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<VirtualMachineDetail, GalliumApiErrorResponse, string, UpdateVmRequest>(`vm/${vmSlug}`, updateVirtualMachine);

    return { trigger, error, isMutating };
};

export const useChangeVmPowerState = (vmSlug: string) => {
    const changeVmPowerState = (key: string, {arg}: {arg: VmPowerStateTransition}) => VirtualMachineService.changeVmPowerState(vmSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, VmPowerStateTransition>(`cmd`, changeVmPowerState);

    return { trigger, error, isMutating };
};

