import React, { useState } from 'react';

import {
    Button,
} from "reactstrap"

import { VirtualMachineDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import AddAdaptorModal from './AddAdpatorModal';

interface AddAdaptorButtonProps {
    virtualMachine: VirtualMachineDetail
}

const AddAdaptorButton: React.FC<AddAdaptorButtonProps> = ({ virtualMachine }) => {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true)
    }

    const buttonDisabled = !virtualMachine.availableActions.modifyHardware

    return (
        <React.Fragment>
            <AddAdaptorModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                virtualMachine={virtualMachine}
            />

            <Button disabled={buttonDisabled} color="soft-primary" className='mb-0' onClick={openModal}>
                <FontAwesomeIcon icon={byPrefixAndName.fak["light-ethernet-circle-plus"]} className='ms-1 me-2'/>
                {t('virtualMachine.network.addAdaptorButton')}
            </Button>


        </React.Fragment>
    )
};

export default AddAdaptorButton;