/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BeginRtcRequest } from '../models/BeginRtcRequest';
import type { BeginRtcResponse } from '../models/BeginRtcResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RtcService {

    /**
     * Begin an RTC session with a Host
     * @param requestBody
     * @returns BeginRtcResponse OK
     * @throws ApiError
     */
    public static beginRtcSession(
        requestBody: BeginRtcRequest,
    ): CancelablePromise<BeginRtcResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/rtc/begin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
