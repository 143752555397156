import React, { useMemo } from 'react';

import {
    Card,
    CardHeader,
    CardBody,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import FormatCommand from 'Components/Gallium/FormatCommand';
import { useGetListCommands } from 'GalliumAPIHooks/Commands/CommandsHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { ApiCmdStatus } from 'generated';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import ColTimeAgo from 'Components/Gallium/Columns/ColTimeAgo';
import { ColStatusBadge } from 'Components/Gallium/Columns';
import TableContainer from 'Components/Gallium/TableContainerNew';


type VirtualMachineTasksProps = {
  vmSlug: string;
};

const VirtualMachineTasksBadge: React.FC<VirtualMachineTasksProps> = ({ vmSlug }) => {
    const { data, error, isLoading } = useGetListCommands();

    const vmCommands = data?.commands?.filter(command => command.hostSlug === vmSlug);
    // Compute the number of in-progress commands, handling cases where data or commands may not be loaded yet
    const numInProgressCommands = vmCommands?.filter(command => command.status === ApiCmdStatus.IN_PROGRESS).length ?? 0;

    if (isLoading || error || numInProgressCommands === 0) {
        // Return null to render nothing if loading, error, or no commands in progress
        return null;
    }

    // Render the badge only if there are in-progress commands
    return (
        <span className="badge bg-primary text-white rounded p-1">{numInProgressCommands}</span>
    );
};

const VirtualMachineTasks = () => {
    const { t } = useTranslation()
    const {vmSlug} = useParams  ()
    const { data, error, isLoading } = useGetListCommands();

    const vmCommands = data?.commands?.filter(command => command.vmSlug === vmSlug)

    const columns = useMemo(
        () => [
            {
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    const command = cellProps.row.original
                    return (
                        <React.Fragment>
                            <FormatCommand command={command}/> 
                            <p className='text-muted m-0 small'>{command?.progressMessage || ""}</p>
                        </React.Fragment>
                    )
                },
            },
            
            {
                header: "Time",
                accessorKey: "issuedAt",
                enableColumnFilter: false,
                id: "attached",
                className: "col-3",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />;
                },
            },
            {
                header: "User",
                accessorKey: "requestingUser",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    if (!cellProps.getValue()) return ("System")
                    return cellProps.getValue().name
                },
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-3 text-end",
                cell: (cellProps) => {
                    return <ColStatusBadge {...cellProps} />;
                },
            },
            
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'issuedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.TASKS.title")} icon={t("virtualMachine.nav.TASKS.icon")}>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardHeader className=''>
                        <h4 className="card-title mb-0">{t("tasks.allTasks")}</h4>
                    </CardHeader>
                    <CardBody className="pt-0">
                        
                        <TableContainer
                            columns={columns}
                            data={vmCommands}
                            isGlobalFilter={true}
                            customPageSize={50}
                            sorting={sorting}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a Task'
                        />
                    </CardBody>
                </Card>    
            )}
        </GalliumThirdColumn>
    )
};

export {VirtualMachineTasks, VirtualMachineTasksBadge}