import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Progress } from "reactstrap";
import { formatDiskSize } from "../FormatDiskSize";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import DeleteModal from "Components/Common/DeleteModal";
import { HostVolume } from "generated";
import { useDestroyHostVolume } from "GalliumAPIHooks/HostVolume/HostVolumeHooks";
import VolumeEditButton from "pages/Hypervisor/HypervisorDetail/Volumes/VolumeEditButton";

const VolumeName = (cell) => {
    const hostSlug = useParams().id
    return (
        <React.Fragment>
            <Link to={`/hypervisors/${hostSlug}/volumes/${cell.row.original.slug}`} className="fw-medium link-primary text-wrap">{cell.getValue()}</Link>
        </React.Fragment>
    );
};

const VolumeAttached = (cell) => { 
    const {t} = useTranslation()
    return (
        <React.Fragment>
            {cell.getValue() ? (
                <Link to={`/vm/${cell.row.original.currentAttachment.vmSlug}`} className="badge border border-success text-success text-start fs-12">
                    <p className="mb-1 fw-semibold">{t("common.attached")}</p>
                    <p className="mb-0 fw-light">
                        <FontAwesomeIcon icon={byPrefixAndName.fasl["arrow-turn-down-right"]} className='me-1'/> 
                        {cell.row.original.currentAttachment.vmName}
                    </p>
                </Link>
            ):(
                <span className="badge border border-primary text-primary text-start fs-12">
                    <p className="mb-0 fw-semibold">{t("common.detached")}</p>
                </span>
            )}
        </React.Fragment>
    );
};

const VolumeSize = (cell) => {
    const sizeUsed = formatDiskSize(cell.row.original.sizeUsedMb)
    const sizeTotal = formatDiskSize(cell.row.original.sizeTotalMb)
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <span className="fs-12 fw-semibold">{sizeTotal}</span><span className="fs-12 fw-semibold text-muted"> ({sizeUsed} {t("globals.used")})</span>
            <Progress multi className='progress-xl mt-0'>
                <Progress bar color="primary" value={cell.row.original.sizeUsedMb} />
                <Progress bar color="secondary-subtle" value={cell.row.original.sizeTotalMb - cell.row.original.sizeUsedMb} />
            </Progress>
        </React.Fragment>
    );
};

const VolumeActions = (cell) => {
    const hostSlug = useParams().id
    const { trigger, isMutating } = useDestroyHostVolume(hostSlug, cell.getValue())
    const [selectedVolume, setSelectedVolume] = useState<HostVolume | undefined>(undefined);  
    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);
    
    const onClickDelete = (volume: HostVolume) => {
        setSelectedVolume(volume);
        setShowDeleteModal(true);
    };

    const handleDeleteVolume = () => {
        if (selectedVolume) {
            trigger();
            setShowDeleteModal(false);
        }
    };
    return (
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteVolume}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={selectedVolume?.name || "Unknown"}
                isWorking={isMutating}
            />
            <div className='hstack gap-2'>
                <VolumeEditButton volume={cell.row.original} hostSlug={hostSlug}/>
                {cell.row.original.attached === false?(
                    <Button className="btn btn-icon btn-soft-danger btn-sm" aria-label="Delete" onClick={() => {onClickDelete(cell.row.original) }}>
                        <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} className='fa-fw'/>
                    </Button>
                ):(null)}
            </div>
        </React.Fragment>
    );
};

export {VolumeAttached, VolumeName, VolumeSize, VolumeActions}