import React, { useState } from 'react';

import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { ApiVmStatus, HostDetail } from 'generated';
import {round} from "lodash"
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


type HypervisorMemoryUsageDetailedProps = {
  hypervisorDetail: HostDetail;
};

const HypervisorMemoryUsageDetailed: React.FC<HypervisorMemoryUsageDetailedProps> = ({ hypervisorDetail }) => {

    const vmColor = getChartColorsArray('["--vz-primary"]')[0];
    const systemColor = getChartColorsArray('[ "--vz-danger"]')[0]

    const newData = hypervisorDetail.vms.map(vm => ({
        name: vm.name,
        data: [
            vm.status === ApiVmStatus.RUNNING ? vm.memoryMb : null,  // runningMemAmount
            vm.autoStart ? vm.memoryMb : 0  // startupMemAmount
        ]
    }));

    const systemReserved = 
        {
            name: "System",
            data: [
                // Reserved right now
                (hypervisorDetail.memTotalMb - (hypervisorDetail.memAllocatedRunningMb + hypervisorDetail.memAvailRunningMb)), 
                // Reserved on startup
                (hypervisorDetail.memTotalMb - (hypervisorDetail.memAllocatedStartupMb + hypervisorDetail.memAvailStartupMb))
            ],
        }

    const finalData = [systemReserved, ...newData];


    const memoryChartOptions = {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: true,
            toolbar: {
                show: false,
            },
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
        },
        grid: {
            show: true,
            padding:
            {
                top: -20

            }
        },
        dataLabels: {
            formatter: function (value, opt) {
                // console.log(opt)
                // return opt.w.config.series[opt.seriesIndex].name;
                // return null
                // return round(value/1024,1)
                return ""
            },
        },
        plotOptions: {
            bar: {
                barHeight: '102%',
                horizontal: !0,
            },
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        xaxis: {
            categories: ["Running", "On Startup"],
            tickAmount: 2,
            labels: {
                formatter: function(value) {
                    return round(value/1024,1) + " GB"
                }
            }
        },
        yaxis:{
            max: hypervisorDetail.memTotalMb
        },
        tooltip: {
            y: {
                formatter: function(value) {
                    return round(value/1024,1) + " GB"
                }
            }
        },
        fill: {
            opacity: 1,
        },
        legend:{
            show: false
        },
        // colors: chartStackedBar100Colors,
        colors: [function({ value, seriesIndex, w }) {
            if (seriesIndex  === 0) {
                return systemColor
            } else {
                return vmColor
            }
        }]
    };

    const [options] = useState(memoryChartOptions)


    return (

        <React.Fragment>
            <Card>
                <CardHeader >
                    <h4 className="card-title mb-0">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["memory"]} className='fa-fw me-2'/>
                        Memory Allocation
                    </h4>
                </CardHeader>
                <CardBody className='pt-1 p-0'>
                    <ReactApexChart
                        dir="ltr"
                        options={options}
                        series={finalData || []}
                        type="bar"
                        height={125}
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
        
    );
};

export default HypervisorMemoryUsageDetailed