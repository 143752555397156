import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Button,
    Card,
    CardBody,
} from "reactstrap"

import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import { useTranslation } from 'react-i18next';
import { useGetBackupProfilesByVM } from 'GalliumAPIHooks/Backup/BackupHooks';
import AddBackupProfileButton from './AddBackupProfileButton';
import VirtualMachineBackupProfile from './BackupProfile';
import { OrgFlags } from 'Components/Gallium/Flags';

const VirtualMachineBackups = () => {
    const {t} = useTranslation()
    const {vmSlug} = useParams();
    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(vmSlug)
    const {data: backupProfiles, isLoading: backProfilesLoading, error: backupProfileError} = useGetBackupProfilesByVM(vmSlug)

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.BACKUP.title")} icon={t("virtualMachine.nav.BACKUP.icon")}>
            <OrgFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={true}>
                {isLoading || backProfilesLoading? (<Loader />) : error || backupProfileError? (<ErrorNotice />) : (
                <React.Fragment>

                    {backupProfiles.profiles.length > 0 ? (
                        <VirtualMachineBackupProfile profile={backupProfiles?.profiles[0]} setSlug={backupProfiles?.profiles[0]?.backupSets[0].slug} virtualMachine={virtualMachine}/>
                    ):(
                        <Card className=''>
                            <CardBody className='p-5 mt-5 mb-5 text-center'>
                                <AddBackupProfileButton virtualMachine={virtualMachine}/>
                            </CardBody>
                        </Card>
                    )}
                               
                </React.Fragment>
                )}
            </OrgFlags>
            <OrgFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={false}>
                <Card>
                    <CardBody className='p-5 mt-5 mb-5 text-center'>

                        <Button disabled outline color="secondary">No Backup License</Button>

                    </CardBody>
                </Card>
            </OrgFlags>
            
        </GalliumThirdColumn>
    )
}

export default VirtualMachineBackups;