import React, { useState } from 'react';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { BackupProfile, BackupSet, BackupTask, GalliumApiErrorResponse, RestoreBackupRequest } from 'generated';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { Card, CardBody, Col } from 'reactstrap';
import { Field, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useRestoreBackup } from 'GalliumAPIHooks/Backup/BackupHooks';
import SingleSelectCard from 'Components/Gallium/Forms/SingleSelectCard';
import { LocalDataTimeString } from 'Components/Gallium/GalliumTimeHelpers';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { toast } from 'react-toastify';

interface RestoreBackupFormProps {
    backupTask: BackupTask
    backupProfile: BackupProfile
    backupSet: BackupSet
}

const RestoreBackupForm: React.FC<RestoreBackupFormProps> = ({backupTask, backupProfile, backupSet}) => {
    const {t} = useTranslation()
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)
    const navigate = useNavigate()
    const {trigger, isMutating} = useRestoreBackup(backupTask.slug)

    const handleRestoreBackupTaskFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleRestoreBackupTaskSuccess = () => {
        toast.info(t("backup.restore.restoreStarted"))
        navigate(`/vm/`)
    }

    const handleRestoreBackupTaskRequest = (requestObj: RestoreBackupRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleRestoreBackupTaskFail(error)
            },
            onSuccess() {
                handleRestoreBackupTaskSuccess()
            }
        }
        setErrorObject(null)
        
        trigger(requestObj, options);
    }

    // Formik
    const addVirtualMachineFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            sourceTaskSlug: backupTask.slug,
            destinationHostSlug: backupProfile.targetHost.slug
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
        }),
        onSubmit: (values) => {
            const newVM = {
                name: values.name,
            };

            (window as any).Intercom('trackEvent', 'restore-backup-request');

            handleRestoreBackupTaskRequest(newVM)
        },
    });

    return (
        <Card>
            <CardBody>
                <FormikProvider value={addVirtualMachineFormik}>
                    <ErrorAlert errorObj={errorObject} />
                    <GalliumFormHeader icon="box" title={t("backup.restore.formSectionSource")}>
                        <div className='pb-3'>
                            <SingleSelectCard 
                                id='sourceCard' 
                                name='sourceTaskSlug' 
                                value={backupTask.slug} 
                                labelText={`${LocalDataTimeString(backupTask.createdAt)}`} 
                                labelTitle={`${backupSet.vmReference.name}`}
                            />
                        </div>
                    </GalliumFormHeader>
                    <GalliumFormHeader icon="server" title={t("backup.restore.formSectionDestination")}>
                        <div className='pb-3'>
                            <SingleSelectCard 
                                id='destCard' 
                                name='destinationHostSlug' 
                                value={backupProfile.targetHost.slug} 
                                labelText='Primary Storage Pool' 
                                labelTitle={backupProfile.targetHost.name}
                            />
                        </div>
                    </GalliumFormHeader>
                    <GalliumFormHeader icon="info" title={t("backup.restore.formSectionIdentification")}>
                        <Col lg={4} className="mb-3">
                            <Field
                                name="name"
                                label={t("backup.restore.nameFieldLabel")}
                                id="name"
                                className="form-control"
                                placeholder={t("backup.restore.nameFieldPlaceholder")}
                                type="text"
                                component={GalliumInput}
                            />
                        </Col>
                    </GalliumFormHeader>


                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <Link to={`/vm/${backupSet.vmReference.slug}`} className="btn btn-link text-decoration-none">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-left"]} className='me-2' />
                            {t("backup.restore.backToVM")}
                        </Link>
                        <GalliumSubmitButton formik={addVirtualMachineFormik} color="success" className="ms-auto right nexttab" spinner={isMutating}>
                            {t("backup.restore.submitButton")}
                        </GalliumSubmitButton>
                    </div>

                    

                </FormikProvider>
            </CardBody>
        </Card>
    );
};

export default RestoreBackupForm;