import React from 'react';
import { useParams } from 'react-router-dom';

import Loader from "Components/Gallium/Loader";

import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import VirtualMachineAutostart from './Autostart';
import VirtualMachineIdentification from './Identification';

import { useTranslation } from 'react-i18next';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';

const VirtualMachineSettings = () => {
    const { t } = useTranslation()
    const {vmSlug} = useParams()
    const {data: virtualMachine, error: virtualMachineError, isLoading: isVirtualMachineLoading} = useGetVirtualMachine(vmSlug)

    if (isVirtualMachineLoading) return <Loader />;
    if (virtualMachineError) return <ErrorNotice />;

    return (    
        <GalliumThirdColumn heading={t("virtualMachine.nav.SETTINGS.title")} icon={t("virtualMachine.nav.SETTINGS.icon")}>
            
            <VirtualMachineIdentification virtualMachine={virtualMachine} />

            <VirtualMachineAutostart virtualMachine={virtualMachine} />

        </GalliumThirdColumn>
    );
};

export default VirtualMachineSettings