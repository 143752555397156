import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MFAModal from "./MFAModal";
import DeleteModal from "Components/Common/DeleteModal";

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import { toast } from "react-toastify";

import { useUserProfileRequest, useUserProfileMFADeleteRequest } from "GalliumAPIHooks/User/UserProfileHooks";
import { GalliumApiErrorResponse, MfaMethodType } from 'generated';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const MFATable = () => {
    const { data } = useUserProfileRequest();

    const [MFAMethod, setMFAMethod] = useState(undefined);  
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (mfaMethod) => {
        setMFAMethod(mfaMethod)
        setDeleteModal(true);
    };

    const { trigger: userProfileMFADeleteTrigger, isMutating } = useUserProfileMFADeleteRequest(MFAMethod?.slug)

    const handleMFADeleteFail = (error: GalliumApiErrorResponse) => {
        toast.error(error.error || "Deletion Failed", { autoClose: 3000 });
        setDeleteModal(false);
    }

    const handleMFADeleteSuccess = () => {
        toast.success("MFA Method Deleted", { autoClose: 3000 });
        setDeleteModal(false);
    }

    const handleMFADeleteRequest = () => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleMFADeleteFail(error)
            },
            onSuccess() {
                handleMFADeleteSuccess()
            },
        }
        userProfileMFADeleteTrigger(MFAMethod, options);
    }

    const mfaFormatter = (type: MfaMethodType, details:string) => {
        switch(type) {
        case "YUBICO_OTP":
            return (
                <span className="fs-14 mb-1 text-wrap d-block">
                    <i className="mdi mdi-usb-flash-drive-outline me-2"></i>Yubikey
                    <span className='text-muted ms-2'>({details})</span>
                </span>
            )
        case "RECOVERY_CODES":
            return (
                <span className="fs-14 mb-1 text-wrap d-block">
                    <i className="mdi mdi-keyboard-outline me-2"></i>Recovery Codes
                    <span className='text-muted ms-2'>({details})</span>
                </span>
            )
        case "TOTP":
            return (
                <span className="fs-14 mb-1 text-wrap d-block">
                    <i className="mdi mdi-qrcode me-2"></i>TOTP Authenticator
                </span>
            )        
        default:
            return type
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Description",
                accessorKey: "description",
                enableColumnFilter: false,
                className: "col-5",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue()}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: false,
                className: "col-6",
                cell: (cellProps) => {
                    return mfaFormatter(cellProps.getValue(), cellProps.row.original.details)
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-1 text-end",
                cell: (cellProps) => {
                    return (
                        <Button className="btn btn-icon btn-soft-danger btn-sm" aria-label="Delete" onClick={() => {onClickDelete(cellProps.row.original) }}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} className='fa-fw'/>
                        </Button>
                    )
                },
            }
        ],
        [] // The dependencies array
    );

    const sorting = useMemo(
        () => [
            {
                id: 'description',
                desc: false, 
            },
        ],[]
    );

    const [showMFAModal, setMFAModal] = useState(false);
    const showMFAModalButton = ( 
        <Button color="soft-primary" className="add-btn" onClick={() => {setMFAModal(true)}}><i className="mdi mdi-plus-circle-outline me-1"></i> Add MFA Method</Button>
    )

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleMFADeleteRequest}
                onCloseClick={() => setDeleteModal(false)}
                isWorking={isMutating}
            />
            <MFAModal
                showModal={showMFAModal}
                closeModal={() => setMFAModal(false)}
            />                    

            <div className="d-flex align-items-center mb-3">
                <h6 className="card-title mb-0 flex-grow-1 fs-14">Your Authenticators</h6>
                <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                    </div>
                </div>
            </div>

            <div style={{"margin": -8}}>
                <TableContainer
                    columns={columns}
                    data={(data.mfaMethods || [])}
                    isGlobalFilter={true}
                    customPageSize={20}
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap mb-0"
                    theadClass="table-light table-nowrap"
                    thClass="table-light text-muted"
                    SearchPlaceholder='Search for a MFA Method...'
                    sorting={sorting}
                    button={showMFAModalButton}
                />
            </div>
      
        </React.Fragment>
    );
};

export default MFATable;