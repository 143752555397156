import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import React, { SetStateAction, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import { ConfigureVmDiskAction, GalliumApiErrorResponse, ReconfigureVmRequest, VirtualMachineDetail, VmDisk, HostDetail } from "generated";
import { useTranslation } from "react-i18next";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FormikProvider, useFormik } from "formik";
import GalliumSizeInput from "Components/Gallium/GalliumSizeInput";
import * as Yup from "yup";
import { useGetHost } from "GalliumAPIHooks/Host/HostHooks";
import { formatDiskSize } from "Components/Gallium/FormatDiskSize";



interface ExpandModalProps {
    showModal: boolean,
    setShowModal: React.Dispatch<SetStateAction<boolean>>,
    disk: VmDisk,
    virtualMachine: VirtualMachineDetail,
    hypervisor: HostDetail
}

const ExpandModal: React.FC<ExpandModalProps> = ({ showModal, setShowModal, disk, virtualMachine, hypervisor }) => {
    const { t } = useTranslation(['translation'])

    const closeModal = () => {
        expandDiskFormik.resetForm()
        setShowModal(false)
    }

    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleExpandDiskFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleExpandDiskSuccess = () => {
        closeModal()
    }

    const handleExpandDiskRequest = (requestObj: ReconfigureVmRequest) => {
        const options = {
            onError(error: object) {
                handleExpandDiskFail(error)
            },
            onSuccess() {
                handleExpandDiskSuccess()
            }
        }
        setErrorObject(undefined)
        
        trigger(requestObj, options);
    }

    const expandDiskFormik = useFormik({
        initialValues: {
            sizeMb: null
        },
        validationSchema: Yup.object({
            sizeMb: Yup.number()
                .required("Disk size is required")
                .positive("Disk size must be a positive number")
                .integer("Disk size must be an integer")
                .max(hypervisor.availDiskMb, `Disk size cannot exceed ${hypervisor.availDiskMb} MB`)
        }),
        onSubmit: (values) => {
            const requestObj = {
                "disks": [
                    {
                        action: ConfigureVmDiskAction.RESIZE,
                        sizeMb: values["sizeMb"] + disk.sizeTotalMb,
                        diskSlug: disk.volumeSlug
                    }
                ]
            };
            
            handleExpandDiskRequest(requestObj)
        },
    });

    return (
        <FormikProvider value={expandDiskFormik}>
            <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
                <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                    {t('translation:virtualMachine:diskExpandTitle')}
                </ModalHeader>   
                <ModalBody className="py-3 px-5">
                    <ErrorAlert errorObj={errorObject}/>
                    <Row className="g-2">
                        <Col lg={12}>
                            <Label>{t('translation:virtualMachine:diskExpandExistingLabel')}</Label>
                            <p className="mb-2">{formatDiskSize(disk.sizeTotalMb)}</p>
                        </Col>

                        <Label>{t('translation:virtualMachine:diskExpandAmountLabel')}</Label>
                        <GalliumSizeInput
                            name="sizeMb"
                            width={12}
                        />


                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={expandDiskFormik} color="success" spinner={isMutating}>
                                {t('translation:virtualMachine:diskExpand')}
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </FormikProvider>
    );
};

interface DiskExpandButtonProps {
    disk: VmDisk
    virtualMachine: VirtualMachineDetail
}

const DiskExpandButton: React.FC<DiskExpandButtonProps> = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    const {data: hypervisor, isLoading} = useGetHost(virtualMachine.host.slug)

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    return (
        <React.Fragment>
            {isLoading ? (
                null
            ):(
                <>
                    <ExpandModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        disk={disk}
                        virtualMachine={virtualMachine}
                        hypervisor={hypervisor}
                    />
                    {virtualMachine.availableActions.resizeDisk ? (
                        <Button color="soft-primary" id={`expandDiskButton-${disk.volumeSlug}`} className='mt-1 btn-sm btn-block' onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["arrows-left-right-to-line"]} className='me-1'/>
                            {t('translation:virtualMachine:diskExpand')}
                        </Button>
                    ):(
                        <React.Fragment>
                            <Button color="soft-secondary" id={`expandDiskButton-${disk.volumeSlug}`} className='mt-1 btn-sm btn-block'>
                                <FontAwesomeIcon icon={byPrefixAndName.fal["arrows-left-right-to-line"]} className='me-1'/>
                                {t('translation:virtualMachine:diskExpand')}
                            </Button>
                            <Tooltip
                                isOpen={isBtntooltipOpen}
                                placement="bottom"
                                target={`expandDiskButton-${disk.volumeSlug}`}
                                toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                                <div>{t('translation:common:warningActionUnavailable')}</div>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    
                </>
            )}
        </React.Fragment>
    )
};

export default DiskExpandButton;