import { GalliumApiErrorResponse, GalliumApiSuccessResponse,  HostNetworkCreateRequest, HostNetworkService, HostNetworkUpdateRequest } from "generated";
import useSWRMutation from 'swr/mutation'

export const useCreateHostNetwork = (hostSlug: string) => {
    const createHostNetwork = (key: null, {arg}: {arg: HostNetworkCreateRequest}) => HostNetworkService.createHostNetwork(hostSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, HostNetworkCreateRequest>(`hosts/${hostSlug}`, createHostNetwork);

    return { trigger, error, isMutating };
};

export const useUpdateHostNetwork = (hostSlug: string, netSlug: string) => {
    const updateHostNetwork = (key: null, {arg}: {arg: HostNetworkUpdateRequest}) => HostNetworkService.updateHostNetwork(hostSlug, netSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, HostNetworkUpdateRequest>(`hosts/${hostSlug}`, updateHostNetwork);

    return { trigger, error, isMutating };
};

export const useDeleteHostNetwork = (hostSlug: string, netSlug: string) => {
    const deleteHostNetwork = (key: null) => HostNetworkService.deleteHostNetwork(hostSlug, netSlug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`hosts/${hostSlug}`, deleteHostNetwork);

    return { trigger, error, isMutating };
};


