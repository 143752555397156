import ToInitials from 'Components/Gallium/ToInitials';
import { useUserProfileRequest } from 'GalliumAPIHooks/User/UserProfileHooks';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Loader from './Loader';


const ProfileDropdown = () => {
    const {data, isLoading} = useUserProfileRequest()
    const [userName, setUserName] = useState("Admin");
    const [userEmail, setUserEmail] = useState("Unknown");
    const [userInitials, setUserInitials] = useState("UA");
    
    useEffect(() => {
        if (data) {
            setUserName(data?.name);
            setUserEmail(data?.email);
            setUserInitials(data?.name === undefined ? "Admin" : ToInitials(data?.name));
            // @ts-expect-error Intercom
            window.Intercom('boot', {
                app_id: 'wapdro5j',
                name: data.name,
                email: data.email,
            });
        }
    }, [data]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    {isLoading ? (<Loader />):(  
                        <span className="d-flex align-items-center">
                            <div className="avatar-sm">
                                <div className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {userInitials}
                                </div>
                            </div>
                            <span className="text-start ms-xl-2">
                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                                <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userEmail}</span>
                            </span>
                        </span>
                    )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <Link to="/profile">
                        <DropdownItem>
                            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> 
                            <span className="align-middle">User Settings</span>
                        </DropdownItem>
                    </Link>
                    <DropdownItem href={"/logout"}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                        className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;