import { useGetFlags, useSetFlags } from 'GalliumAPIHooks/User/UserHooks';
import { FlagType } from 'generated';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Button } from 'reactstrap';

interface UserFlagsProps {
    flag: string;
    renderIfExists?: boolean; // Made it optional
    children: ReactNode;
}

const UserFlags: React.FC<UserFlagsProps> = ({ flag, renderIfExists = true, children }) => {
    const {data, isLoading, error} = useGetFlags()

    if (isLoading || error) {
        return null;
    } else {
        const flagExists = data.userFlags.includes(flag);
        return renderIfExists ? (flagExists ? <>{children}</> : null) : (!flagExists ? <>{children}</> : null);
    }  
};

const OrgFlags: React.FC<UserFlagsProps> = ({ flag, renderIfExists = true, children }) => {
    const {data, isLoading, error} = useGetFlags()

    if (isLoading || error) {
        return null;
    } else {
        const flagExists = data.orgFlags.includes(flag);
        return renderIfExists ? (flagExists ? <>{children}</> : null) : (!flagExists ? <>{children}</> : null);
    }  
};

interface SetUserFlagButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    flag: string;
    children: ReactNode;
    color?: string;
}

const SetUserFlagButton: React.FC<SetUserFlagButtonProps> = ({ flag, children, color = "primary", className, ...props }) => {
    const {trigger, isMutating} = useSetFlags()

    if (isMutating){
        return(
            <Button
                color={color}
                className={`btn-load ${className}`}
            >
                <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                        Working
                    </span>
                    <span className="spinner-grow flex-shrink-0" role="status">
                        <span className="visually-hidden">Working</span>
                    </span>
                </span>
            
            </Button>

        )
    } else {
        return(
            <Button
                {...props}
                color={color}
                className={`${className}`}
                onClick={() => {
                    trigger({flag: flag, scope: FlagType.USER})
                }}
            >
                {children}
            </Button>
        )
    }
};

export { UserFlags, SetUserFlagButton, OrgFlags}

