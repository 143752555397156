import React from 'react';
import { Button, Progress } from 'reactstrap';
import { formatBytes, formatDuration } from 'react-dropzone-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';

interface PreviewProps {
    className?: string;
    imageClassName?: string;
    style?: React.CSSProperties;
    imageStyle?: React.CSSProperties;
    fileWithMeta: {
        cancel: () => void;
        remove: () => void;
        restart: () => void;
        file: File;
        meta: {
            name?: string;
            percent?: number;
            size?: number;
            previewUrl?: string;
            status: string;
            duration?: number;
            validationError?: any;
        };
        xhr?: any;
    };
    meta: {
        status: 'preparing' | 'error_file_size' | 'error_validation' | 'ready' | 'getting_upload_params' |
            'error_upload_params' | 'uploading' | 'exception_upload' | 'aborted' | 'error_upload' |
            'headers_received' | 'done';
        type: string;
        name: string;
        uploadedDate: string;
        percent: number;
        size: number;
        lastModifiedDate: string;
        previewUrl: string;
        duration: number;
        width: number;
        height: number;
        videoWidth: number;
        videoHeight: number;
        validationError: any;
    };
    isUpload: boolean;
    canCancel: boolean;
    canRemove: boolean;
    canRestart: boolean;
    extra: {
        minSizeBytes: number;
    };
}

class Preview extends React.PureComponent<PreviewProps> {
    render() {
        const {
            className,
            style,
            fileWithMeta: { cancel, remove, restart },
            meta: { name = '', percent = 0, size = 0, previewUrl, status, duration, validationError },
            isUpload,
            canCancel,
            canRemove,
            canRestart,
            extra: { minSizeBytes },
        } = this.props;

        let title = `${name || '?'}, ${formatBytes(size)}`;
        if (duration) title = `${title}, ${formatDuration(duration)}`;

        if (status === 'error_file_size' || status === 'error_validation') {
            return (
                <div className={className} style={style}>
                    <p className="text-muted">{title}</p>
                    {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
                    {status === 'error_validation' && <span>{String(validationError)}</span>}
                    {canRemove && <span className="dzu-previewButton" onClick={remove} />}
                </div>
            );
        }

        if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
            title = `${title} (upload failed)`;
        }
        if (status === 'aborted') title = `${title} (cancelled)`;

        return (
            <div className="">
                {status === 'uploading' && canCancel && (
                    <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={cancel}>
                        <i className="mdi mdi-cancel align-middle"></i>
                    </Button>
                )}

                {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
                    <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={remove}>
                        <i className="mdi mdi-file-cancel-outline align-middle"></i>
                    </Button>
                )}

                {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
                    canRestart && (
                        <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={restart}>
                            <i className="mdi mdi-restart align-middle"></i>
                        </Button>
                    )}

                {!previewUrl && <p className="text-muted">{title}</p>}

                <div className="dzu-previewStatusContainer">
                    {isUpload && (
                        <div>
                            <Progress 
                                max={100} 
                                value={status === 'done' || status === 'headers_received' ? 100 : percent} 
                                striped={status === 'uploading'} 
                                animated={status === 'uploading'} 
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Preview;
