import React from 'react';

import { Field, useFormikContext } from "formik";

import {
    Col,
    Label,
} from "reactstrap"

import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';
import { Template, TemplateOsType } from 'generated';

const Authentication = ({ template }:{template: Template}) => {
    const formik = useFormikContext()
    return (
        <GalliumFormHeader icon="key" title="Authentication">
            {template?.supportsCloudInit ? (
                <React.Fragment>
                    <div className="mt-3 mb-3">
                        <Col lg={4}>
                            <Field
                                name="rootPassword"
                                label="User Password"
                                id="rootPassword"
                                className="form-control"
                                placeholder="Enter Password"
                                type="password"
                                component={GalliumInput}
                            />
                            {template.osType === TemplateOsType.WINDOWS ? (null):(
                                <span className='text-muted'>The default username is gallium</span>
                            )}
                        </Col>
                    </div>
                    {template.osType === TemplateOsType.WINDOWS ? (null):(
                        <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                            <Field
                                id="sshAutoAdd" 
                                name="sshAutoAdd" 
                                type="checkbox" 
                                className="form-check-input"  
                            />
                            <Label className="form-check-label" htmlFor="sshAutoAdd">Add SSH Keys from Inventory to Virtual Machines</Label>
                        </div>
                    )}
                </React.Fragment>
            ):(
                <p className='text-muted'>This template does not support automatic authentication configuration.</p>
            )}
        </GalliumFormHeader>
    )
};

export default Authentication;
