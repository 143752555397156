import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSquareWhite from "../assets/images/logosquare-white.png";
import logoFullWhite from "../assets/images/logofull-white.png";
import logoSquareColor from "../assets/images/logosquare-color.png";
import logoFullColor from "../assets/images/logofull-color.png";

//Import Components
import { Container } from "reactstrap";
import VerticalLayoutNav from "./VerticalLayoutNav";

const Sidebar = ({ layoutType }) => {

    useEffect(() => {
        const verticalOverlay = document.getElementsByClassName("vertical-overlay");
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener("click", function () {
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
    });

    const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        } else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
    };
    return (
        <React.Fragment>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        {/* This is used for for the color logo, not the dark mode logo */}
                        <span className="logo-sm">
                            <img src={logoSquareColor} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoFullColor} alt="" height="55" className="mt-3 mb-3" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        {/* Dark Mode */}
                        <span className="logo-sm">
                            <img src={logoSquareWhite} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoFullWhite} alt="" height="55" className="mt-3 mb-3"/>
                        </span>
                    </Link>
                    <button
                        onClick={addEventListenerOnSmHoverMenu}
                        type="button"
                        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover"
                    >
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                <SimpleBar id="scrollbar" className="h-100">
                    <Container fluid>
                        <div id="two-column-menu"></div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <VerticalLayoutNav layoutType={layoutType} />
                        </ul>
                    </Container>
                </SimpleBar>
                <div className="sidebar-background"></div>
            </div>
            <div className="vertical-overlay"></div>
        </React.Fragment>
    );
};

export default Sidebar;
