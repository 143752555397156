import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Progress, Table } from 'reactstrap';
import Loader from 'Components/Gallium/Loader';

import { useFileTransferManager } from 'GalliumFileTransferManager/wrapper';
import { round } from "lodash"


const HypervisorExportDisk = () => {
    const { t } = useTranslation()
    const hypervisorSlug = useParams().hostSlug
    const {ftmReady, connectToHost, getHostFileList, getActiveTransferTask, beginDownload} = useFileTransferManager()

    useEffect(() => {
        connectToHost(hypervisorSlug);
    }, [ftmReady]);

    const hostFileList = ftmReady ? getHostFileList(hypervisorSlug) : [];

    return (
        ftmReady && hostFileList.length > 0 ? (
            <Card>
                <CardHeader className=''>
                    <h4 className="card-title mb-0">Export Disk</h4>
                </CardHeader>
                <CardBody >
                    <div className="table-responsive table-card">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" className="col-6">Disk</th>
                                    <th scope="col" className="col-3">Size</th>
                                    <th scope="col" className="col-3 text-end">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hostFileList.map(file => (
                                    <tr key={file.id}>
                                        <td className='col-6'>
                                            {file.name} 
                                        </td>
                                        <td className='col-3'>
                                            {round(file.size / (1024 ** 3),2)} GB
                                        </td>
                                        <td className='col-3 text-end'>
                                            {getActiveTransferTask(file.id) ? (
                                                <React.Fragment>
                                                    <span>Downloading {round(getActiveTransferTask(file.id).pct,2)} %</span>
                                                    <Progress color="primary" className="progress-xl" value={getActiveTransferTask(file.id).pct} striped animated />
                                                </React.Fragment>
                                            ):(
                                                <Button color="primary" onClick={(_e) => beginDownload(hypervisorSlug, file.id)}>
                                                    Download
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        ) : (
            <Loader />
        )
    )
};

export default HypervisorExportDisk