import React, { useContext } from "react";
import { ModalContext } from "./MFAModal";

import { ModalBody, ModalHeader } from "reactstrap";
import LordIcon from "Components/Gallium/LordIcon";
import SuccessIcon from "assets/lordicon/success.json"

const Success = () => {
    const { response, setResponse, closeModal } = useContext(ModalContext);

    return (
        <React.Fragment>   
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                Success
            </ModalHeader>
            <ModalBody className='text-center'>
                <div className="mt-2">
                    <LordIcon icon={SuccessIcon} />
                    <h4 className="mb-3 mt-4">Your Yubikey method has been added</h4>
                    <div className="hstack gap-2 justify-content-center">
                        <button className="btn btn-primary" onClick={()=>{closeModal()}}>Close</button>
                        {/* <button className="btn btn-soft-success"><i className="ri-links-line align-bottom"></i> Copy tracking link</button> */}
                    </div>
                </div>
            </ModalBody>
        </React.Fragment> 
    );
};

export default Success
