import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, ServiceGatewayCreateRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useGetAllHosts, useGetHostServiceGatewayInfo } from 'GalliumAPIHooks/Host/HostHooks';
import ServiceGatewayMappings from './Mappings';
import Loader from 'Components/Gallium/Loader';
import { useCreateServiceGateway } from 'GalliumAPIHooks/ServiceGateway/ServiceGatewayHooks';
import ServiceGatewaySettings from './Settings';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';


const ServiceGatewayAdd = () => {
    const {t} = useTranslation()
    const {data: hypervisors, isLoading, error } = useGetAllHosts()
    const { trigger, isMutating } = useCreateServiceGateway()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleCreateServiceGatewayFail = (error: GalliumApiErrorResponse) => {
        createServiceGatewayFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleCreateServiceGatewaySuccess = () => {
        toast.info("Service Gateway Submitted")
        navigate("/servicegateway");
    }

    const handleCreateServiceGatewayRequest = (values: ServiceGatewayCreateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateServiceGatewayFail(error)
            },
            onSuccess() {
                handleCreateServiceGatewaySuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const [usedPorts, setUsedPorts] = useState([])

    const createServiceGatewayFormik = useFormik({
        initialValues: {
            name: '',
            hypervisor: '',
            mappings: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter a Name"),
            mappings: Yup.array().of(
                Yup.object({
                    type: Yup.string().required("Type is required"), 
                    ingresses: Yup.array().of(
                        Yup.object({
                            port: Yup.number()
                                .min(1, "Source Port must be between 1 and 1024")
                                .max(1024, "Source Port must be between 1 and 1024")
                                .notOneOf(usedPorts, "This port is already in use on this Hypervisor")
                                .required("Source Port is required")
                                .test("is-unique", "This port is already assigned in another entry", function(value) {

                                    const allIngresses = this.from[2].value.mappings.flatMap(mapping => mapping.ingresses); //TODO Find a better answer to this
                                    const hasDuplicate = allIngresses.some(
                                        (ingress, index, array) =>
                                            array.findIndex(t => t.port === ingress.port) !== index
                                    );

                                    return !hasDuplicate
                                }),
                            type: Yup.string().required("Mapping Type is required"), 
                        })
                    ).required("Ingress is required"),
                    targets: Yup.array().of(
                        Yup.object({
                            vmSlug: Yup.string().required("Target VM is Required"), 
                            targetPort: Yup.number().required("Target Port is required") 
                        })
                    ).required("Target is required")
                })
            ).required("Mappings are required")
        }),
        onSubmit: (values) => {
            const request = {
                name: values["name"],
                mappings: values["mappings"]
            }
            handleCreateServiceGatewayRequest(request)
        },
    });

    // This deals with fetching the host data when the user has selected it

    const {data: hostData } = useGetHostServiceGatewayInfo(createServiceGatewayFormik.values["hypervisor"])

    useEffect(() => {
        if (hostData && Array.isArray(hostData.consumedPorts)) { 
            setUsedPorts(hostData.consumedPorts);
        }
    }, [hostData]); 

    document.title = "Add Service Gateway | Gallium";
    const crumbs = [
        {
            name: t("serviceGateways.title"),
            link: "/servicegateway"
        },
        {
            name: t("serviceGateways.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("serviceGateways.add")} crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <FormikProvider value={createServiceGatewayFormik}>
                            <Card>
                                <CardBody>
                                    <ErrorAlert errorObj={errorObject} />

                                    <ServiceGatewaySettings hypervisors={hypervisors}/>
                                    
                                    <ServiceGatewayMappings formik={createServiceGatewayFormik} hostData={hostData}/>

                                    <div className="float-end">
                                        <GalliumSubmitButton formik={createServiceGatewayFormik} spinner={isMutating} color="success">
                                            Create
                                        </GalliumSubmitButton>
                                    </div>
                                </CardBody>
                            </Card>

                        </FormikProvider>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ServiceGatewayAdd;