import React, { useCallback, useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";

import TemplateBasics from './Basics';
import OSType from './OSType';
import TemplateSettings from './Settings';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useCreateTemplate } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CreateTemplateRequest, GalliumApiErrorResponse, Template, TemplateOsType } from 'generated';
import { useNavigate } from 'react-router-dom';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const CreateTemplateStepOne = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {trigger, isMutating} = useCreateTemplate()
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleCreateTemplateFail = useCallback((error: GalliumApiErrorResponse) => {
        setErrorObject(error)
    }, [t]);

    const handleCreateTemplateSuccess = useCallback((data: Template) => {
        toast.success(t("templates.createTemplateForm.draftCreated"));
        navigate(`/inventory/templates/${data.slug}/upload`);
    }, [t]);

    const handleCreateTemplateRequest = useCallback((values: CreateTemplateRequest) => {
        trigger(values, {
            onError: handleCreateTemplateFail,
            onSuccess: handleCreateTemplateSuccess
        });
    }, [trigger, handleCreateTemplateFail, handleCreateTemplateSuccess]);

    const createTemplateFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: "",
            description: "",
            osType: TemplateOsType.LINUX,
            minMemoryMb: 1024,
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required(t("templates.createTemplateForm.name.required")), // translation key
            description: Yup.string()
                .nullable(), // Optional string
            osType: Yup.mixed<TemplateOsType>()
                .oneOf(Object.values(TemplateOsType), t("templates.createTemplateForm.osType.invalid")), // translation key
            minMemoryMb: Yup.number()
                .integer(t("templates.createTemplateForm.minMemoryMb.integer")) // translation key
                .min(512, t("templates.createTemplateForm.minMemoryMb.min")) // translation key
                .max(131072, t("templates.createTemplateForm.minMemoryMb.max")) // translation key
                .required(t("templates.createTemplateForm.minMemoryMb.required")), // translation key
        }),

        onSubmit: (values) => {
            // @ts-expect-error
            window.Intercom('trackEvent', 'create-template-request');

            // save new template
            handleCreateTemplateRequest(values);
        },
    });

    return (    
        <React.Fragment>
            <FormikProvider value={createTemplateFormik}>
                <ErrorAlert errorObj={errorObject} />
                <TemplateBasics/>
                <OSType/>
                <TemplateSettings/>
                    <GalliumSubmitButton
                        color="primary"
                        formik={createTemplateFormik}
                        spinner={isMutating}
                        className="float-end btn-label right"
                    >
                        {t("templates.createTemplateForm.configureDiskButton")}
                        <i className="label-icon">
                            <FontAwesomeIcon icon={byPrefixAndName.far["arrow-right"]}/>
                        </i>
                    </GalliumSubmitButton>
            </FormikProvider>
        </React.Fragment>
    );
};

export default CreateTemplateStepOne