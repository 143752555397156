import React from 'react';
import { Label } from 'reactstrap';
import { Field, useFormikContext } from "formik";

const AlertProfilesRemindersComponent = () => {
    const { setFieldValue } = useFormikContext();
    const reminderOptions = [
        {value: 0, label: "No Reminders"},
        {value: 15, label: "15 Minutes"},
        {value: 30, label: "30 Minutes"},
        {value: 60, label: "1 Hour"},
        {value: 240, label: "4 Hours"},
        {value: 480, label: "8 Hours"},
        {value: 720, label: "12 Hours"},
        {value: 1440, label: "24 Hours"},
    ];

    const handleReminderChange = (event) => {
        // Here we use setFieldValue to update the Formik state
        setFieldValue("reminderThresholdMinutes", parseInt(event.target.value, 10));
    };

    return (    
        <React.Fragment>
            <div className="mb-3 col-md-4">
                <Label htmlFor="roleField">Reminders</Label>
                <Field
                    id="roleField"
                    name="reminderThresholdMinutes"
                    as="select"
                    className="form-select mb-3"
                    onChange={handleReminderChange}  // Passing the custom onChange handler
                >
                    {reminderOptions.map((item, key) => (
                        <option value={item.value} key={key}>{item.label}</option>
                    ))}
                </Field>
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesRemindersComponent;
