// TODO Refactor Delete Process
import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import DeleteModal from "../../../../Components/Common/DeleteModal";
import TableContainer from 'Components/Gallium/TableContainerNew';

import {
    Modal,
    ModalBody,
    ModalHeader,
    Col,
    Row,
    Card,
    CardBody,
    Button,
} from "reactstrap"

import {
    AutoAddEnabled,
    KeyPreview,
    DateAdded,
} from "./SSHKeyListCol";
import { useDeleteSSHKey, useGetListSSHKeys } from 'GalliumAPIHooks/SSH/SSHHooks';
import { SshKey } from 'generated';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import EditSSHKeyModal from './EditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const SSHKeys = () => {
    // Get the SSH Key List
    const { data, error, isLoading } = useGetListSSHKeys()
    const { trigger, isMutating } = useDeleteSSHKey()

    const [selectedKey, setSelectedKey] = useState<SshKey | undefined>(undefined);  

    // Delete SSH Key Modal
    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);
    
    const onClickDelete = (key: SshKey) => {
        setSelectedKey(key);
        setShowDeleteModal(true);
    };

    const handleDeleteSSHKey = () => {
        if (selectedKey) {
            trigger(selectedKey);
            setShowDeleteModal(false);
        }
    };

    // View SSH Key Modal 
    const [showViewModal, setShowViewModal] = useState<boolean | undefined>(false);

    const toggle = useCallback(() => {
        if (showViewModal) {
            setShowViewModal(false);
            setSelectedKey(undefined);
        } else {
            setShowViewModal(true);
        }
    }, [showViewModal]);

    const onSSHKeyClick = (key: SshKey) => {
        setSelectedKey(key)
        setShowViewModal(true);
        toggle();
    };

    //  Edit SSH Key Modal 
    const [showEditModal, setShowEditModal] = useState<boolean | undefined>(false);

    const onClickEdit = (key: SshKey) => {
        setSelectedKey(key)
        setShowEditModal(true);
    };

    // Format the Added On Date 
    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
      
        const formattedDate = date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short"
        });
      
        return formattedDate;
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to="#" className="fw-medium link-primary" onClick={() => { onSSHKeyClick(cellProps.row.original) }}>{cellProps.getValue()}</Link>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Auto",
                accessorKey: "autoAdd",
                enableColumnFilter: false,
                className: "col-2 text-center",
                cell: (cellProps) => {
                    return <AutoAddEnabled {...cellProps} />;
                },
            },
            {
                header: "Added On",
                accessorKey: "addedAt",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <DateAdded {...cellProps} />;
                },
            },
            {
                header: "Key",
                accessorKey: "key",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <KeyPreview {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-1 text-end",
                cell: (cellProps) => {
                    return (
                        <div className="hstack gap-2 fs-15">
                            <Button color="primary" className="btn btn-icon btn-soft-primary btn-sm" aria-label="Edit" onClick={() => {onClickEdit(cellProps.row.original)}}>
                                <FontAwesomeIcon icon={byPrefixAndName.fal["pen"]} className='fa-fw'/>
                            </Button>
                            <Button className="btn btn-icon btn-soft-danger btn-sm" aria-label="Delete" onClick={() => {const sshkey = cellProps.row.original; onClickDelete(sshkey)}}>
                                <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} className='fa-fw'/>
                            </Button>
                        </div>
                    )
                },
            },
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'autoAdd',
                desc: false, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteSSHKey}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={selectedKey?.name || "Unknown"}
                isWorking={isMutating}
            />

            <EditSSHKeyModal
                show={showEditModal}
                onCloseClick={() => setShowEditModal(false)}
                selectedKey={selectedKey || undefined}
            />

            <Modal
                isOpen={showViewModal}
                toggle={toggle}
                centered
                size="md"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-info-subtle" toggle={toggle}>
                View SSH Key
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="g-3">
                        <Col lg={12}>
                            <h6 className="mb-3 fw-semibold">Name</h6>
                            <p>{selectedKey?.name || "Unknown"}</p>
                            <h6 className="mb-3 fw-semibold">Key</h6>
                            <p><code>{selectedKey?.key || "Unknown"}</code></p>
                            <h6 className="mb-3 fw-semibold">Added On</h6>
                            <p>{formatDate(selectedKey?.addedAt) || "Unknown"}</p>
                            <h6 className="mb-3 fw-semibold">Auto Add Enabled</h6>
                            <p>{selectedKey?.autoAdd ? "Yes" : "No"}</p>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardBody className="pt-0">
                        <TableContainer
                            columns={columns}
                            data={(data.keys || [])}
                            isGlobalFilter={true}
                            customPageSize={20}
                            sorting={sorting}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a SSH Key...'
                        />
                    </CardBody>
                </Card>
            )}            

        </React.Fragment>
    );
};

export default SSHKeys;