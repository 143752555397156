import React from 'react';
import { useParams } from 'react-router-dom';

import Loader from "Components/Gallium/Loader";

import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useListTemplates } from 'GalliumAPIHooks/Templates/TemplateHooks';
import CreateVMForm from './CreateVMForm';



const CreateVMSettings = () => {
    const {hostSlug, templateSlug} = useParams()
    const {data: hypervisor, isLoading: isHypervisorLoading, error: hypervisorError} = useGetHost(hostSlug)
    const {data:templatesList, isLoading: isTemplatesLoading, error: templateError} = useListTemplates()
    const template = templatesList?.templates?.find(t => t.slug === templateSlug);
    return (    
        isHypervisorLoading || isTemplatesLoading? (<Loader />) : hypervisorError || templateError ? (<ErrorNotice />) : (
            
            <CreateVMForm template={template} hypervisor={hypervisor}/>

        )
    );
};

export default CreateVMSettings;