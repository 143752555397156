const StringPretty = ({ string }) => {

    function toTitleCase(string: string) {
        const noUnderscores = string.replace(/_/g, " ");

        return noUnderscores.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    const formattedText = toTitleCase(string)

    return formattedText
};

export default StringPretty;