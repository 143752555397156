import React from 'react';
import { Button, Container } from 'reactstrap';

import VirtualMachineList from './List';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { Link } from 'react-router-dom';


const VirtualMachines = () => {
    const crumbs = [
        {
            name: "Virtual Machines",
            link: "/vm"
        },
        {
            name: "View All",
        }
    ]
    document.title = "Virtual Machines | Gallium";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="Virtual Machines" crumbs={crumbs}>
                        <Link to="/vm/create">
                            <Button color="soft-primary" className="add-btn me-1"><i className="ri-add-line align-bottom me-1"></i> Create New VM</Button>
                        </Link>
                    </GalliumBreadcrumb>


                    <VirtualMachineList />
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default VirtualMachines;