import React, { useMemo } from 'react';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';

import { useGetOrgInfo } from 'GalliumAPIHooks/Org/OrgHooks';
import { useTranslation } from 'react-i18next';

const ServiceProviderOrgsListComponent = () => {
    const {t} = useTranslation()
    const { data, error, isLoading } = useGetOrgInfo()

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue()}</span>
                        </React.Fragment>
                    );
                },
            }
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'name',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Row>
                    <Col lg={12}>
                        <Card id="serviceGatewayList">
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(data.childOrgs || [])}
                                    isGlobalFilter={true}
                                    customPageSize={20}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder={'Search ' + t("serviceProvider.orgs.title")}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}            
                
        </React.Fragment>
    );
};

export default ServiceProviderOrgsListComponent;