import React, { useMemo } from 'react';
import TableContainer from '../../../Components/Gallium/TableContainerNew';

import { Col } from 'reactstrap';

import {
    Hypervisor,
    Name,
    Resources,
    Status,
} from "../../../Components/Gallium/VM/VirtualMachineListCol";

import 'react-toastify/dist/ReactToastify.css';
import { useGetVirtualMachines } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import ColVmControls from 'Components/Gallium/Columns/ColControls';

const VirtualMachineList = () => {

    const {data, isLoading, error} = useGetVirtualMachines(6000)

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <Name {...cellProps} />;
                },
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <Status {...cellProps} />;
                },
            },
            {
                header: "Resources",
                accessorKey: "vcpus",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <Resources {...cellProps} />;
                },
            },
            {
                header: "Hypervisor",
                accessorKey: "hostName",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <Hypervisor {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <ColVmControls {...cellProps} />;
                },
            },
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'name',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <div className="row">
                    <Col lg={12}>
                        <div className="card" id="tasksList">
                            <div className="card-body pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={data?.vms || []}
                                    isGlobalFilter={true}
                                    customPageSize={8}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for a VM'
                                />
                            </div>
                        </div>
                    </Col>
                </div>
            )}
        </React.Fragment>
    );
};

export default VirtualMachineList;