import React, { forwardRef } from "react";
import { VncScreen } from 'react-vnc/lib';
import ErrorNotice from "./ErrorNotice";
import RFB from "react-vnc/noVNC/core/rfb";

interface VNCViewerProps {
    wss_url: string;
}

const VNCViewer = forwardRef<any, VNCViewerProps>(({wss_url}, ref) => {

    const handleCapabilities = (e?: { detail: { capabilities: RFB["capabilities"]; }; }) => {
        // Check if the event exists and if the details are provided
        if (e && e.detail) {
            console.log(e.detail.capabilities);
        }
    };

    let vncComponent = <div></div>

    if (wss_url !== null && wss_url !== '') {
        vncComponent =  <VncScreen
            url={wss_url}
            scaleViewport
            background="#000000"
            style={{
                width: '100%',
                height: '100px',
            }}
            className="flex-grow-1"
            ref={ref}
            onCapabilities={handleCapabilities}
        />
    } else {
        vncComponent = <ErrorNotice text="The VNC Viewer failed to start" />
    }

    return (
        <React.Fragment>
            {vncComponent}
        </React.Fragment>
    )
})

VNCViewer.displayName = "VNCViewer";

export default VNCViewer

