import React, { useCallback, useEffect, useState } from 'react';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { GalliumApiErrorResponse, Template, TemplateDiskType } from 'generated';
import { toast } from 'react-toastify';
import { useCreateTemplateDisk } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { CreateTemplateDiskRequest } from "generated";
import { FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

interface AddBlankDiskToTemplateButtonProps {
  template: Template;
}

const AddBlankDiskToTemplateButton: React.FC<AddBlankDiskToTemplateButtonProps> = ({template}) => {
    const {t} = useTranslation();
    const {trigger: createDiskTrigger, isMutating: createDiskMutating} = useCreateTemplateDisk(template.slug)
    const [showModal, setShowModal] = useState(false);
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)
    const handleAddBlankDiskToTemplateFail = useCallback(() => {
        toast.error(t("templates.uploadDiskForm.AddDiskFail"));
    }, [t]);

    const handleAddBlankDiskToTemplateSuccess = useCallback(() => {
        setShowModal(false);
    }, [t]);

    const handleAddBlankDiskToTemplateRequest = useCallback((values: CreateTemplateDiskRequest) => {
        createDiskTrigger(values, {
            onError: handleAddBlankDiskToTemplateFail,
            onSuccess: handleAddBlankDiskToTemplateSuccess
        });
    }, [createDiskTrigger, handleAddBlankDiskToTemplateFail, handleAddBlankDiskToTemplateSuccess]);

    const AddBlankDiskToTemplateFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            sizeMb: 8192,
            diskType: TemplateDiskType.DISK,
            blank: true
        },
        validationSchema: Yup.object().shape({
            sizeMb: Yup.number()
                .integer(t("templates.createTemplateForm.sizeMb.integer")) // translation key
                .min(512, t("templates.createTemplateForm.sizeMb.min")) // translation key
                .max(1048576, t("templates.createTemplateForm.sizeMb.max")) // translation key
                .required(t("templates.createTemplateForm.sizeMb.required")), // translation key
        }),
        onSubmit: (values) => {
            handleAddBlankDiskToTemplateRequest(values)
        },
    });

    useEffect(() => {
        AddBlankDiskToTemplateFormik.resetForm()
        setErrorObject(null)
    }, [showModal]); 

    return (
        <React.Fragment>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered={true}>  
                <ModalHeader className="p-3 bg-light" toggle={()=>{setShowModal(false)}}>
                {t("templates.uploadDiskForm.titles.addBlankDisk")}
                </ModalHeader>      
                <ModalBody className="py-3 px-5 mb-3">
                    <ErrorAlert errorObj={errorObject}/>
                    <FormikProvider value={AddBlankDiskToTemplateFormik}>
                        <Label htmlFor='sizeMbInpput'>{t("templates.uploadDiskForm.labels.sizeMb")}</Label>
                        <GalliumSizeInput name="sizeMb" id="sizeMbInpput" width={12}/>
                
                        <GalliumSubmitButton className="float-end" formik={AddBlankDiskToTemplateFormik} color="success" spinner={createDiskMutating}>
                        {t("common.create")}
                        </GalliumSubmitButton>
                    </FormikProvider>
                </ModalBody>
            </Modal>

            <Button color="primary" className="btn-label w-100" onClick={() => setShowModal(true)}>
                 <i className="label-icon align-middle">
                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-plus"]}/>
                </i>
            {t("templates.createTemplateForm.buttons.addBlankDisk")}
            </Button>
        </React.Fragment>    
    );
};
export default AddBlankDiskToTemplateButton;
