import React from 'react';

import { Field } from "formik";

import {
    Label,
    Col,
    Row,

} from "reactstrap"
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import { TemplateOsType } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const OSType = () => {
    const {t} = useTranslation()
    const availableOperatingSystems = [
        {"name": "Linux", "value": TemplateOsType.LINUX, "icon": "bx bxl-tux bx-md", "description": t(`templates.createTemplateForm.osTypes.${TemplateOsType.LINUX}`)},
        {"name": "Windows", "value": TemplateOsType.WINDOWS, "icon": "bx bxl-windows bx-md", "description": t(`templates.createTemplateForm.osTypes.${TemplateOsType.WINDOWS}`)},
        {"name": "Other", "value": TemplateOsType.OTHER, "icon": "bx bx-file-blank bx-md", "description": t(`templates.createTemplateForm.osTypes.${TemplateOsType.OTHER}`)}
    ];

    const returnOsIcon = (os: TemplateOsType) => {
        switch (os) {
            case TemplateOsType.LINUX: {
                return <FontAwesomeIcon icon={byPrefixAndName.fab["linux"]} className='fa-fw'/>;
            }
            case TemplateOsType.WINDOWS: {
                return <FontAwesomeIcon icon={byPrefixAndName.fab["windows"]} className='fa-fw'/>;
            }
            default: {
                return <FontAwesomeIcon icon={byPrefixAndName.fas["globe"]} className='fa-fw'/>;
            }
        }
    };
    
    return (
        <GalliumFormHeader title={t("templates.createTemplateForm.title.operatingSytem")} icon="cpu">
            <Label className="form-label" htmlFor="key-data-input">{t("templates.createTemplateForm.labels.selectTemplateOS")}</Label>
            <Row className='gy3 mb-2'>
                {Object.values(TemplateOsType).map((item, key) => (
                    <Col lg={4} key={key}>
                        <div className="form-check card-radio">
                            <Field
                                id={"osType"+key}
                                name="osType"
                                value={item}
                                type="radio"
                                className="form-check-input"
                            />
                            <Label
                                className="form-check-label"
                                htmlFor={"osType"+key}
                            >
                                <span className="fs-24 float-end mt-1 text-wrap d-block">
                                    {returnOsIcon(item)}
                                </span>
                                <span className="fs-14 mb-1 text-wrap d-block">
                                    {t(`TemplateOsType.${item}`)}
                                </span>
                                <span className="text-muted fw-normal text-truncate d-block">
                                    {t(`templates.createTemplateForm.osTypes.${item}`)}
                                </span>
                            </Label>
                
                        </div>
                    </Col>
                ))}
            </Row>
        </GalliumFormHeader>
    )
};

export default OSType;
