import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useGetBackupSetDetails, useListBackupProfiles } from 'GalliumAPIHooks/Backup/BackupHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import RestoreBackupForm from './RestoreForm';

const RestoreBackup = () => {
    const {t} = useTranslation()
    const {setSlug, profileSlug, taskSlug} = useParams()

    const {data: backupSetDetail, error: backupSetError, isLoading: isbackupSetLoading} = useGetBackupSetDetails(profileSlug, setSlug)
    const {data: backupProfileList, error: backupProfileError, isLoading: backupProfileLoading} = useListBackupProfiles()
    const backupProfile = backupProfileList?.profiles?.find(profile => profile.slug === profileSlug)
    const backupTask = backupSetDetail?.backupTasks?.find(task => task.slug === taskSlug)
    const backupSet = backupSetDetail?.backupSet

    document.title = t("backupProfiles.title") +" | Gallium";
    const crumbs = [
        {
            name: t("mainNav.virtualMachines"),
            link: "/vm"
        },
        {
            name:  backupSet?.vmReference?.name,
            link: `/vm/${backupSet?.vmReference?.slug}`
        },
        {
            name: t("backup.restore.pageTitle"),
        }
    ]


    return (
        <div className="page-content">
            <Container fluid>
                {isbackupSetLoading || backupProfileLoading? (<Loader />) : backupSetError || backupProfileError? (<ErrorNotice />) : (
                    <React.Fragment>
                        <GalliumBreadcrumb title={t("backup.restore.pageTitle")} crumbs={crumbs} />

                        <RestoreBackupForm backupProfile={backupProfile} backupSet={backupSet} backupTask={backupTask}/>
                        
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
};

export default RestoreBackup;
                    