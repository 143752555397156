import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { DayOfWeek, GalliumApiErrorResponse, NewBackupProfileRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useGetAllHosts } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import BackupProfileSettings from '../Common/Settings';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useNewBackupProfile } from 'GalliumAPIHooks/Backup/BackupHooks';
import BackupProfileTarget from './Target';


const BackupProfileAdd = () => {
    const {t} = useTranslation()
    const {data: hypervisors, isLoading, error } = useGetAllHosts()
    const targetCandidates = hypervisors?.filter(hypervisor => hypervisor.availableActions.backupRecv === true);
    const { trigger, isMutating } = useNewBackupProfile()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleCreateBackupProfileFail = (error: GalliumApiErrorResponse) => {
        createBackupProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleCreateBackupProfileSuccess = () => {
        toast.info("New Backup Profile Submitted")
        navigate("/backups");
    }

    const handleCreateBackupProfileRequest = (values: NewBackupProfileRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateBackupProfileFail(error)
            },
            onSuccess() {
                handleCreateBackupProfileSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }


    const createBackupProfileFormik = useFormik({
        initialValues: {
            targetHostSlug: "",
            name: "",
            fullBackupDay: DayOfWeek.SUNDAY, // Assumes DayOfWeek is an enum
            backupTime: {
                hour: 20,
                minute: 0,
                timezoneOffsetMinutes: 0,
            },
            expiryDays: 365,
        },
        validationSchema: Yup.object({
            targetHostSlug: Yup.string().required('Target host is required'),
            name: Yup.string().required('Name is required'),
            fullBackupDay: Yup.mixed<DayOfWeek>().oneOf(Object.values(DayOfWeek)).required('Full backup day is required'),
            backupTime: Yup.object({
                hour: Yup.number()
                    .integer('Hour must be an integer')
                    .min(0, 'Hour must be at least 0')
                    .max(23, 'Hour cannot be more than 23')
                    .required('Hour is required'),
                minute: Yup.number()
                    .integer('Minute must be an integer')
                    .min(0, 'Minute must be at least 0')
                    .max(55, 'Minute cannot be more than 55')
                    .required('Minute is required'),
                timezoneOffsetMinutes: Yup.number()
                    .integer('Timezone offset must be an integer')
                    .required('Timezone offset is required'),
            }).required(),
            expiryDays: Yup.number()
                .integer('Retention must be an integer')
                .min(1, 'Retention must be at least 1 day')
                .max(2555, 'Retention cannot be more than 2555 days')
                .required('Retention is required'),
        }),
        onSubmit: (values) => {
            const processedValues = {
                ...values,
                backupTime: {
                    ...values.backupTime,
                    hour: Number(values.backupTime.hour),
                    minute: Number(values.backupTime.minute),
                    timezoneOffsetMinutes: Number(values.backupTime.timezoneOffsetMinutes),
                },
            };

            handleCreateBackupProfileRequest(processedValues);
        },
    });



    document.title = "Add Service Gateway | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: t("backupProfiles.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("backupProfiles.add")} crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <FormikProvider value={createBackupProfileFormik}>
                            <Card>
                                <CardBody>
                                    <ErrorAlert errorObj={errorObject} />

                                    <BackupProfileTarget hypervisors={targetCandidates}/>
                                    <BackupProfileSettings/>
                                    
                                    <div className="float-end">
                                        <GalliumSubmitButton formik={createBackupProfileFormik} spinner={isMutating} color="success">
                                            {t("common.create")}
                                        </GalliumSubmitButton>
                                    </div>
                                </CardBody>
                            </Card>

                        </FormikProvider>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BackupProfileAdd;