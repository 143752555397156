import React from 'react';

import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HostDetail } from 'generated';
import { useTranslation } from 'react-i18next';

type HypervisorStatusHeaderProps = {
  data: HostDetail;
};

const HypervisorStatusHeader: React.FC<HypervisorStatusHeaderProps> = ({ data }) => {
    const { t } = useTranslation()
    const color = data.online ? ("success"):("danger")
    const icon = data.online ? ("sharp-light-server-circle-check"):("sharp-light-server-circle-xmark")

    return (
        <React.Fragment>
            <div className='border-bottom p-0'>
                <div className={`d-flex  border-${color} border-bottom`}>
                    <div className="avatar-md flex-shrink-0">
                        <span className={`avatar-title bg-${color}-subtle fs-2`}>
                            <FontAwesomeIcon icon={byPrefixAndName.fak[icon]} className={`fs-2 text-${color}`}/>
                        </span>
                    </div>

                    <div className="ms-2 p-0 col-8">
                        <h5 className="fs-16 mb-1 mt-3 text-truncate">{data.name}</h5>
                        <p className="text-muted mb-0 fs-12">{t("hypervisor.deploymentTypes.edge")} - {data.statusText}</p>
                    </div>
                </div>  
            </div>
        </React.Fragment>
    )
};

export default HypervisorStatusHeader;