const parseErrors = (error, parentKey = 'root') => {
    if (error === null) return [];

    if (typeof error === 'string') 
        return [<p className="mb-1" key={parentKey}>{error}</p>];

    if (Array.isArray(error))
        return error.flatMap((err, i) => parseErrors(err, `${parentKey}-${i}`));

    if (typeof error === 'object') 
        return Object.entries(error).flatMap(([key, err]) => parseErrors(err, `${parentKey}-${key}`));
    
    return [];
};

export default parseErrors