import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';


const HelpDropdown = () => {

    //Dropdown Toggle
    const [isHelpDowndownOpen, setIsHelpDowndownOpen] = useState(false);
    const toggleHelpDowndownOpen = () => {
        setIsHelpDowndownOpen(!isHelpDowndownOpen);
    };

    const items = [
        {
            title: "Documentation",
            url: "https://docs.gallium.cloud/en/",
            icon: "bx bx-link-external",
            target: "_blank"
        }
    ]
    
    return (
        <React.Fragment>
            <Dropdown isOpen={isHelpDowndownOpen} toggle={toggleHelpDowndownOpen} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-question-mark fs-22'></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {items.map((item, index)=>(
                        <Link to={item.url} key={index} target={item.target}>
                            <DropdownItem>
                                <span className="align-middle"><i className={item.icon}></i> {item.title}</span>
                            </DropdownItem>
                        </Link>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );    
};

export default HelpDropdown;