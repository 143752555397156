/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownloadInfoResponse } from '../models/DownloadInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DownloadsService {

    /**
     * Get information about the latest downloads available
     * @returns DownloadInfoResponse OK
     * @throws ApiError
     */
    public static getDownloads(): CancelablePromise<DownloadInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/downloads',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
