import useSWR from "swr";
import useSWRMutation from 'swr/mutation'
import { UserService } from "../../generated/services/UserService";
import { PendingInvitesResponse } from "../../generated/models/PendingInvitesResponse";
import { AcceptOrgInviteResponse } from "../../generated/models/AcceptOrgInviteResponse";
import { PasswordResetRequest } from "../../generated/models/PasswordResetRequest";
import { GalliumApiSuccessResponse } from "../../generated/models/GalliumApiSuccessResponse";
import { ConfirmPasswordResetRequest } from "../../generated/models/ConfirmPasswordResetRequest";
import { ConfirmPasswordResetResponse } from "../../generated/models/ConfirmPasswordResetResponse";
import { GalliumTokenRequest } from "../../generated/models/GalliumTokenRequest";
import { FlagCreateRequest, FlagResponse, GalliumApiErrorResponse, GalliumLoginResponse, GalliumRegisterRequest, ValidatePasswordResetResponse } from "generated";

export const useLogin = () => {
    const login = (key, {arg}) => UserService.login(arg)

    const { trigger, error, isMutating } = useSWRMutation(`login`, login);

    return { trigger, error, isMutating };
};

export const useGetPendingOrgInvitations = () => {
    const getPendingOrgInvitations = () => UserService.getPendingOrgInvitations()
    
    const { data, error, isLoading } = useSWR<PendingInvitesResponse>(`user/invites`, getPendingOrgInvitations);

    return { data, error, isLoading };
};

export const useAcceptOrgInvite = () => {
    const acceptOrgInvite = (key: null, {arg}:{arg:string}) => UserService.acceptOrgInvite({slug: arg})
    
    const { trigger, error, isMutating } = useSWRMutation<AcceptOrgInviteResponse, GalliumApiErrorResponse, string, string>(`user/invites`, acceptOrgInvite);

    return { trigger, error, isMutating };
};

export const usePasswordResetRequest = () => {
    const passwordResetRequest = (key: null, {arg}:{arg:PasswordResetRequest}) => UserService.passwordResetRequest(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, PasswordResetRequest>(`user/invites`, passwordResetRequest);

    return { trigger, error, isMutating };
};

export const usePasswordResetValidate = (token: string) => {
    const passwordResetValidate = () => UserService.passwordResetValidate({token: token})
    
    const { data, error, isLoading } = useSWR<ValidatePasswordResetResponse>(`user/resettoken`, passwordResetValidate, {
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    return { data, error, isLoading };
};


export const usePasswordResetConfirm = () => {
    const passwordResetConfirm = (key: null, {arg}:{arg:ConfirmPasswordResetRequest}) => UserService.passwordResetConfirm(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<ConfirmPasswordResetResponse, GalliumApiErrorResponse, string, ConfirmPasswordResetRequest>(`user`, passwordResetConfirm);

    return { trigger, error, isMutating };
};


export const useRefreshAccessToken = () => {
    const refreshAccessToken = (key: null, {arg}:{arg:GalliumTokenRequest}) => UserService.refreshAccessToken(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumLoginResponse, GalliumApiErrorResponse, string, GalliumTokenRequest>(`user`, refreshAccessToken);

    return { trigger, error, isMutating };
};

export const useRegister = () => {
    const register = (key: string, {arg}:{arg:GalliumRegisterRequest}) => UserService.register(arg)
    
    //TODO find out how to deal with an empty API response
    const { trigger, error, isMutating } = useSWRMutation<any, GalliumApiErrorResponse, string, GalliumRegisterRequest>(`register`, register);

    return { trigger, error, isMutating };
};

export const useGetFlags = () => {
    const getFlags = (key: string) => UserService.getFlags()
    
    const { data, error, isLoading } = useSWR<FlagResponse>(`flags`, getFlags,
        {
            shouldRetryOnError: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            dedupingInterval: 3600000
        }
    );

    return { data, error, isLoading };
};

export const useSetFlags = () => {
    const setFlags = (key: string, {arg}:{arg:FlagCreateRequest}) => UserService.setFlag(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<FlagResponse, GalliumApiErrorResponse, string, FlagCreateRequest>(`flags`, setFlags );

    return { trigger, error, isMutating };
};