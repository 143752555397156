import React, { useMemo } from 'react';
import TableContainer from 'Components/Gallium/TableContainerNew';
import { Card, CardBody } from 'reactstrap';

import {
    Name,
    Status,
    OperatingSystem,
} from "./TemplateListCol";

import { useListTemplates } from 'GalliumAPIHooks/Templates/TemplateHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';

const TemplatesTable = () => {
    const {data, isLoading, error} = useListTemplates(true)

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-6",
                cell: (cellProps) => {
                    return <Name {...cellProps} />;
                },
            },
            {
                header: "Operating System",
                accessorKey: "osType",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <OperatingSystem {...cellProps} />;
                },
            },
            {
                header: "Status",
                accessorKey: "state",
                enableColumnFilter: false,
                className: "col-3 text-end",
                cell: (cellProps) => {
                    return <Status {...cellProps} />;
                },
            },
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'state',
                desc: false, 
            },
        ],[]
    );


    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardBody className="pt-0">
                        <TableContainer
                            columns={columns}
                            data={data.templates || []}
                            isGlobalFilter={true}
                            customPageSize={8}
                            sorting={sorting}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a template...'
                        />
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
};

export default TemplatesTable;