/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionConfirmResponse } from '../models/ActionConfirmResponse';
import type { CreateOrgRequest } from '../models/CreateOrgRequest';
import type { CreateOrgResponse } from '../models/CreateOrgResponse';
import type { InviteUserRequest } from '../models/InviteUserRequest';
import type { OrgInfoResponse } from '../models/OrgInfoResponse';
import type { OrgUpdateRequest } from '../models/OrgUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrgService {

    /**
     * Get the current org
     * @returns OrgInfoResponse OK
     * @throws ApiError
     */
    public static getOrgInfo(): CancelablePromise<OrgInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/org',
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update the current org
     * @param requestBody
     * @returns OrgInfoResponse OK
     * @throws ApiError
     */
    public static updateOrg(
        requestBody: OrgUpdateRequest,
    ): CancelablePromise<OrgInfoResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/org',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Invite a user to the current org
     * @param requestBody
     * @returns ActionConfirmResponse OK
     * @throws ApiError
     */
    public static inviteUserToOrg(
        requestBody: InviteUserRequest,
    ): CancelablePromise<ActionConfirmResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/org/invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Create a new Gallium organisation
     * @param requestBody
     * @returns CreateOrgResponse OK
     * @throws ApiError
     */
    public static createNewOrg(
        requestBody: CreateOrgRequest,
    ): CancelablePromise<CreateOrgResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/org/new',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete a user from the org
     * @param slug
     * @returns ActionConfirmResponse OK
     * @throws ApiError
     */
    public static deleteUserFromOrg(
        slug: string,
    ): CancelablePromise<ActionConfirmResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/org/user/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
