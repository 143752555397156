import { FieldProps, getIn } from 'formik';
import React from 'react';
import { FormFeedback, Input, Label, Button, Tooltip } from "reactstrap";

import FeatherIcon from 'feather-icons-react';

import parseErrors from './ParseErrors';

interface GalliumInputProps extends FieldProps {
    label?: string;
    width?: number;
    [key: string]: any; // To allow any additional props
}

const GalliumInput: React.FC<GalliumInputProps> = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors },
    width = 12, // default width to 12 if not provided
    label,
    ...props
}) => {
    // Ensure error is of type string before displaying it
    const errorMessage = touched[field.name] && typeof errors[field.name] === 'string'
        ? (errors[field.name] as string)
        : null;

    return (
        <div className={`mb-2 col-md-${width}`}>
            {label && <Label htmlFor={field.name}>{label}</Label>}
            <Input
                {...field}
                {...props}
                invalid={!!errorMessage}
            />
            {errorMessage && (
                <FormFeedback type="invalid">{errorMessage}</FormFeedback>
            )}
        </div>
    );
};

export default GalliumInput;

const GalliumArrayInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {

    const error = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    return (
        <div>
            {props.label ? (<Label htmlFor={field.id}>{props.label}</Label>):(null)}
            <Input
                {...field}
                {...props}
                invalid={
                    touch && error ? true : false
                }
            />
        </div>
    )

}

interface GalliumSpinnerButtonProps {
  color: string;
  className?: string;
}

const GalliumSpinnerButton: React.FC<GalliumSpinnerButtonProps>  = ({ color, className }) => {
    return(
        <Button
            color={color}
            className={"btn-load " + className}
        >
            <span className="d-flex align-items-center">
                <span className="flex-grow-1 me-2">
                    Working
                </span>
                <span className="spinner-grow flex-shrink-0" role="status">
                    <span className="visually-hidden">Working</span>
                </span>
            </span>
        </Button>
    )
}

const GalliumSubmitButton = ({ formik, children, color, spinner, waiting = false, externallyDisabled = false,  ...props }) => {

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    if (spinner){
        return(
            <Button
                color={color}
                className={"btn-load " + props.className}
                type="button"
            >
                <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                        Working
                    </span>
                    <span className="spinner-grow flex-shrink-0" role="status">
                        <span className="visually-hidden">Working</span>
                    </span>
                </span>
            
            </Button>

        )
    } else if (waiting){
        return(
            <Button
                color={color}
                className={"btn-load " + props.className}
                type="button"
            >
                <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                        Waiting
                    </span>
                    <span className="spinner-grow flex-shrink-0" role="status">
                        <span className="visually-hidden">Waiting</span>
                    </span>
                </span>
            
            </Button>

        )
    } else if (formik.errors && Object.keys(formik.errors).length > 0) {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    id="GalBtn"
                    color="secondary"
                    {...props}
                >
                    
                    {children}
                </Button>

                <Tooltip
                    isOpen={isBtntooltipOpen}
                    placement="left"
                    target="GalBtn"
                    toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                    {
                        Object.entries(formik.errors).flatMap(([field, errorMsg], index) => parseErrors(errorMsg).map((ErrorComponent, i) => <div key={`${index}-${i}`}>{ErrorComponent}</div>))
                    }
                </Tooltip>
            </React.Fragment>
        )
    } else {
        return(
            <Button
                color={color}
                disabled={externallyDisabled}
                {...props}
                onClick={() => {
                    formik.handleSubmit()
                }}
                type="button"
            >
                {children}
            </Button>
        )
    }
}

const GalliumFormHeader = ({title, icon, children}) => {

    return (
        <React.Fragment>
            <div className="d-flex mb-2">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon={icon} className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>{title}</h5>
                    {/* <Label className="form-label">Create mappings to direct traffic to Virtual Machines</Label> */}
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
};

export { GalliumInput, GalliumArrayInput, GalliumSubmitButton, GalliumSpinnerButton, GalliumFormHeader };