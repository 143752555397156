import React from 'react';

import { Container } from 'reactstrap';

import { useGetOrgInfo } from 'GalliumAPIHooks/Org/OrgHooks';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import SupportAccessForm from './form';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import Loader from 'Components/Gallium/Loader';

const SupportAccess = () => {
    const {t} = useTranslation()
    const { data, error, isLoading } = useGetOrgInfo()
  

    document.title = t("supportAccess.title") + " | Gallium";

    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("supportAccess.title"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("supportAccess.title")} crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (<SupportAccessForm org={data} />)}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SupportAccess;