import React, { useState } from 'react';

import VolumeEditModal from './VolumeEditModal';
import { HostVolume } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { Button } from 'reactstrap';

interface VolumeEditButtonProps {
    hostSlug: string
    volume: HostVolume
}

const VolumeEditButton: React.FC<VolumeEditButtonProps> = ({ hostSlug, volume }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>
            <VolumeEditModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                hostSlug={hostSlug}
                volume={volume}
            />
            
            <Button color="primary" className="btn btn-icon btn-soft-primary btn-sm" aria-label="Edit" onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={byPrefixAndName.fal["pen"]} className='fa-fw'/>
            </Button>
        </React.Fragment>
    )
};

export default VolumeEditButton;