import React from 'react';

import { Row, Label, Card, CardBody, Col, Button } from 'reactstrap';

import { Field, FieldArray, useFormikContext } from "formik";
import { GalliumArrayInput } from 'Components/Gallium/GalliumForms'; 

const AlertProfilesWebhooksComponent = () => {
    const formik = useFormikContext();

    return (    
        <React.Fragment>
            <Label htmlFor="roleField">Webhooks</Label>
            <Card className='mb-2 pt-3'>
                {/* <CardBody className="pb-1 mb-3 border-bottom border-bottom-dashed">
                    <div className="d-flex align-items-center">

                        <div className="flex-grow-1 overflow-hidden">
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label">Target URL</Label>
                                    
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-shrink-0 ms-2 ps-1">
                            <div className="d-flex gap-1">
                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"
                                >
                                </button>
                            </div>
                        </div>
                    </div>
                </CardBody> */}
                <FieldArray name="webhookUrls" render={arrayHelpers => (
                    <React.Fragment>
                        {formik.values["webhookUrls"].map((item, key) =>(
                        
                            <CardBody key={key} className="pb-1 pt-1">
                                <div className="d-flex align-items-center">

                                    <div className="flex-grow-1 overflow-hidden pe-2">
                                    
                                        <Row>
                                            <Col sm={12} className="pe-1">
                                                <Field
                                                    id={`webhookUrls[${key}]`}
                                                    name={`webhookUrls[${key}]`}
                                                    type="text"
                                                    className="mb-1 form-control"
                                                    placeholder="Webhook URL"
                                                    component={GalliumArrayInput}
                                                />

                                            </Col>
                                        </Row>  
                                    </div>

                                    <div className="flex-shrink-0 ms-2">
                                        <div className="d-flex gap-1 mb-1 ps-1">
                                            <Button color="light" className="btn btn-icon text-muted btn-sm fs-19"
                                                onClick={() => arrayHelpers.remove(key)}
                                            >
                                                <i className="lab las la-trash-alt"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        ))}
                        <CardBody className="pt-0">
                            <Button color="soft-primary" className="mb-0 w-100" onClick={() => arrayHelpers.push(null)}>Add Webhook URL</Button>
                        </CardBody>
                    </React.Fragment>
                )} />
            </Card>
            <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <Field
                    id="webhookVerifySslField" 
                    name="webhookVerifySsl" 
                    type="checkbox" 
                    className="form-check-input"  
                />
                <Label className="form-check-label fw-normal" htmlFor="webhookVerifySslField">Enable Webhook certificate verification</Label>
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesWebhooksComponent;