import React, { useState } from 'react';

import { useGetAlertProfileDetails,  } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import EditAlertProfileForm from './FormComponent';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import DeleteAlertProfile from './DeleteButton';
import classnames from "classnames";
import AllIncidentList from './IncidentList';
import NotificationsList from './NotificationList';


const EditAlertProfile = () => {
    document.title = "Update Alert Profile | Gallium";
    const crumbs = [
        {
            name: "Alert Profiles",
            link: "/settings/alerts"
        },
        {
            name: "Edit Profile",
        }
    ]
    const alertProfileSlug = useParams().slug
    const { data, error, isLoading } = useGetAlertProfileDetails(alertProfileSlug)

    const [searchParams] = useSearchParams()

    // Hypervisor Detail Nav
    const [galliumNavTab, setgalliumNavTab] = useState(searchParams.get("tab") || "CONFIG");
    const galliumNavToggle = (tab) => {
        if (galliumNavTab !== tab) {
            setgalliumNavTab(tab);
        }
    };

    const tabs = (
        <Nav className="nav-tabs-custom border-bottom-0 pt-1" role="tablist">
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "CONFIG", })} onClick={() => { galliumNavToggle("CONFIG"); }} >
                Configuration
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "HISTORY", })} onClick={() => { galliumNavToggle("HISTORY"); }} >
                History
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "NOTIFICATIONS", })} onClick={() => { galliumNavToggle("NOTIFICATIONS"); }} >
                Notifications
                </NavLink>
            </NavItem>
        </Nav>
    )

    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <ErrorNotice />
                    ) : (
                        <React.Fragment>
                            <GalliumBreadcrumb title={data?.alertProfile.name} crumbs={crumbs} tabs={tabs}>
                                {/* <TestAlertProfile alertProfile={data?.alertProfile} /> */}
                                <DeleteAlertProfile alertProfile={data?.alertProfile} />
                            </GalliumBreadcrumb>
                            <TabContent activeTab={galliumNavTab} className="text-muted">
                                <TabPane tabId="CONFIG" id="nav-light-home">
                                    <EditAlertProfileForm alertProfile={data?.alertProfile}/>  
                                </TabPane>
                                <TabPane tabId="HISTORY" id="nav-light-home">
                                    <AllIncidentList data={data?.incidents}/>  
                                </TabPane>
                                <TabPane tabId="NOTIFICATIONS" id="nav-light-home">
                                    <NotificationsList data={data?.notifications}/>  
                                </TabPane>
                            </TabContent>
                               
                        </React.Fragment>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EditAlertProfile;