import React from 'react';
import { useEffect } from "react";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

import FallbackComponent from './error';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";

import { PostHogProvider} from 'posthog-js/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import './i18n';

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

Sentry.init({
    dsn: "https://8c602c5c1a7246f192acf525171120b6@o4504894130814976.ingest.sentry.io/4505277707911168",
    integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const myFallback = <FallbackComponent />;

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWithProviders = process.env.NODE_ENV !== 'development' ? (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
            <App />
        </PostHogProvider>
    </Sentry.ErrorBoundary>
) : (
    <App />
);

root.render(
    <Provider store={configureStore({})}>
        <React.Fragment>
            <BrowserRouter>
                {AppWithProviders}
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//TODO set this up
// reportWebVitals();