import React from 'react';

import {
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap"

import { useParams } from 'react-router-dom';
import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import { useTranslation } from 'react-i18next';
import RemoveAdaptorButton from './RemoveAdaptor';
import AddAdaptorButton from './AddAdaptor';

const VirtualMachineNetwork = () => {
    const {t} = useTranslation()
    const {vmSlug} = useParams();
    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(vmSlug)

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.NETWORK.title")} icon={t("virtualMachine.nav.NETWORK.icon")}>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <React.Fragment>
                    <Row className='gy3'>
                        <Col md={12} >
                            <div className="text-end pb-3">
                                <AddAdaptorButton virtualMachine={virtualMachine} />
                            </div>
                        </Col>
                    </Row>
                    {virtualMachine?.networkInterfaces.map((adaptor) => (
                        <Card key={adaptor.interfaceSlug} className='mb-2'>
                            <CardBody>
                                <Row className="gy-3">
                                    <div className="col-sm">
                                        <h5 className="fs-14 text-truncate">
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["ethernet"]} className='me-2'/>
                                            {`Interface ${adaptor.macAddress ?? "Unknown"}`}
                                        </h5>

                                        <span className="fw-medium text-muted mb-3">
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-turn-down-right"]} className='ms-1 me-2'/>
                                            {`Connected to: ${adaptor.networkName}`}
                                        </span>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex align-items-center gap-2 text-muted">
                                            {virtualMachine?.networkInterfaces.length >= 2 && (
                                                <RemoveAdaptorButton adaptor={adaptor} virtualMachine={virtualMachine} />
                                            )}
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    ))}
                </React.Fragment>
            )}
        </GalliumThirdColumn>
    )
};

export default VirtualMachineNetwork;