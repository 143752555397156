import React, { useState } from 'react';

import {
    Col,
    Row,
    Button,
    Card,
    CardHeader,
    CardBody,
} from "reactstrap"

import PowerModal from './ActionPowerModal';
import { HostDetail, HostPowerAction } from 'generated';
import { useTranslation } from 'react-i18next';

interface HypervisorActionPowerProps {
    hypervisor: HostDetail
}

const HypervisorActionPower: React.FC<HypervisorActionPowerProps> = ({ hypervisor }) => {
    const { t } = useTranslation()

    const [showPowerModal, setShowPowerModal] = useState(false);
    const [modalHostPowerAction, setModalHostPowerAction] = useState(undefined);

    const handlePowerActionClick = (hostPowerAction: HostPowerAction) => {
        setModalHostPowerAction(hostPowerAction)
        setShowPowerModal(true)
    }
    
    return (
        <React.Fragment>
            <PowerModal
                showModal={showPowerModal}
                closeModal={() => {
                    setShowPowerModal(false)
                    setModalHostPowerAction(undefined) // Safety switch to ensure that the form cannot submit unintentionally
                }}
                hypervisor={hypervisor}
                hostPowerAction={modalHostPowerAction}
            />
            <Card className='mb-2'>
                <CardHeader >
                    <h4 className="card-title mb-0">{t("hypervisor.actions.powerControl")}</h4>
                </CardHeader>

                <CardBody >    
                    <Row>
                        <Col md={8}>
                            <h6 className="mb-0">{t(`hostPowerAction.${HostPowerAction.SHUTDOWN}`)}</h6>
                            <p className="text-muted mb-0">{t(`hostPowerAction.shutdownExplainer`)}</p>
                        </Col>
                        <Col md={4} className='text-end'>
                            <Button color="danger" className='mt-1' onClick={() => handlePowerActionClick(HostPowerAction.SHUTDOWN)}>{t(`hostPowerAction.${HostPowerAction.SHUTDOWN}`)}</Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={8}>
                            <h6 className="mb-0">{t(`hostPowerAction.${HostPowerAction.REBOOT}`)}</h6>
                            <p className="text-muted mb-0">{t(`hostPowerAction.restartExplainer`)}</p>
                        </Col>
                        <Col md={4} className='text-end'>
                            <Button color="danger" className='ms-2 mt-1' onClick={() => handlePowerActionClick(HostPowerAction.REBOOT)}>{t(`hostPowerAction.${HostPowerAction.REBOOT}`)}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default HypervisorActionPower;