import React from "react";
import { BackupTaskProgress } from "generated";
import { Progress } from "reactstrap";

const ColBackupStatus = ({ inProgressDisk, bitrateMbps, percent }: BackupTaskProgress) => {
    return (
        <div className=''>
            <span className="fs-12 fw-semibold">Disk: {inProgressDisk}</span><span className="fs-12 fw-semibold text-muted"> ({percent}% - {bitrateMbps}MB/sec)</span>
            <Progress multi className='progress-xl mt-0'>
                <Progress bar color="primary" value={percent} />
            </Progress>
        </div>
    );
};

export default ColBackupStatus