import React from 'react';

import {
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import classnames from "classnames";

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const CreateTemplateFrame = ({children, activeTab}) => {
    const {t} = useTranslation();

    return (    

                    <Card>
                        <CardBody className="create-template-tab">
                            <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                <Nav
                                    className="nav-pills nav-justified custom-nav"
                                    role="tablist"
                                >
                                    <NavItem role="presentation">
                                        <NavLink href="#"
                                            className={classnames({ active: activeTab === 1, done: (activeTab <= 3 && activeTab >= 0) }, "p-3 fs-15")}
                                        >
                                             <FontAwesomeIcon icon={byPrefixAndName.fal["gear"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                            {t("templates.createTemplateForm.tabs.basics")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem role="presentation">
                                        <NavLink href="#"
                                            className={classnames({ active: activeTab === 2, done: activeTab <= 3 && activeTab > 1 }, "p-3 fs-15")}
                                        >
                                             <FontAwesomeIcon icon={byPrefixAndName.fal["hard-drive"]} className='fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2'/>
                                            {t("templates.createTemplateForm.tabs.disks")}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            {children}
                        </CardBody>
                    </Card>
    );
};

export default CreateTemplateFrame