import React from 'react';

import {
    Card,
    CardBody,
    Row,
} from "reactstrap"

import { map } from "lodash"
import NetworkAddButton from './NetworkAddButton';
import { HostDetail, VmNetworkType } from 'generated';
import NetworkEditButton from './NetworkEditButton';
import NetworkChild from './NetworkChild';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface HypervisorNetworkProps {
    hypervisor: HostDetail
}

const HypervisorNetwork: React.FC<HypervisorNetworkProps> = ({hypervisor}) => {
    const {t} = useTranslation()

    return (
        <GalliumPageColumn>       
            {map(hypervisor.availableNetworks, (adaptor) => (
                <Card className="mb-2" key={adaptor.slug}>
                    <CardBody>
                        <Row className="gy-3">
                            <div className="col-auto">
                                {adaptor.networkType === VmNetworkType.NAT ? 
                                    (<div className="avatar-xs">
                                        <div className="avatar-title rounded bg-primary text-white fs-4">
                                            <FontAwesomeIcon icon={byPrefixAndName.fas["shield"]} className='fa-fw'/>
                                        </div>
                                    </div>) : 
                                    adaptor.nicUp ? 
                                        (<div className="avatar-xs">
                                            <div className="avatar-title rounded bg-success text-white fs-4">
                                                <FontAwesomeIcon icon={byPrefixAndName.fas["ethernet"]} className='fa-fw'/>
                                            </div>
                                        </div>) : 
                                        (<div className="avatar-xs">
                                            <div className="avatar-title rounded bg-danger text-white fs-4">
                                                <FontAwesomeIcon icon={byPrefixAndName.fas["ethernet"]} className='fa-fw'/>
                                            </div>
                                        </div>)
                                }
                                


                                
                            </div>
                            <div className="col ps-0">
                                <h5 className="fs-14 text-truncate">
                                    {adaptor.name}
                                </h5>
                                <ul className="list-inline text-muted mb-0">
                                    <li className="list-inline-item">
                                        State:{" "}
                                        {adaptor.nicUp || adaptor.networkType === "NAT" ? (
                                            <span className="text-success fw-medium">Up</span>
                                        ):(
                                            <span className="text-danger fw-medium">Down</span>
                                        )}
                                    </li>
                                    {adaptor.networkType !== VmNetworkType.NAT && (
                                        <>
                                            <li className="list-inline-item">
                                                MAC:{" "}
                                                <span className="fw-medium">
                                                    {adaptor.macAddress}
                                                </span>
                                            </li>
                                            <li className="list-inline-item">
                                                MTU:{" "}
                                                <span className="fw-medium">{adaptor.currentMtu}</span>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div className="col-sm-auto">
                                {adaptor.networkType !== VmNetworkType.NAT && hypervisor.availableActions.guestStateChange ? (
                                    <div className="d-flex align-items-center gap-2 text-muted">
                                        <NetworkEditButton hypervisor={hypervisor} adaptor={adaptor} />
                                        <NetworkAddButton hypervisor={hypervisor} adaptorSlug={adaptor.slug} />
                                    </div>
                                ):(null)}
                            </div>
                        </Row>
                    </CardBody>
                    {adaptor.children.map((childNetwork) => (                      
                        <NetworkChild childNetwork={childNetwork} hypervisor={hypervisor} key={childNetwork.slug} />
                    ))}
                </Card>
            ))}
        </GalliumPageColumn>
    )
};

export default HypervisorNetwork;




