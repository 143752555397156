/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { ListVmResponse } from '../models/ListVmResponse';
import type { ReconfigureVmRequest } from '../models/ReconfigureVmRequest';
import type { UpdateVmRequest } from '../models/UpdateVmRequest';
import type { VirtualMachineDetail } from '../models/VirtualMachineDetail';
import type { VmPowerStateTransition } from '../models/VmPowerStateTransition';
import type { VncUrlResponse } from '../models/VncUrlResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VirtualMachineService {

    /**
     * List VMs
     * @returns ListVmResponse OK
     * @throws ApiError
     */
    public static listVms(): CancelablePromise<ListVmResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vm',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a specific VM
     * @param vmSlug
     * @returns VirtualMachineDetail OK
     * @throws ApiError
     */
    public static getVirtualMachine(
        vmSlug: string,
    ): CancelablePromise<VirtualMachineDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vm/{vmSlug}',
            path: {
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update a specific VM
     * @param vmSlug
     * @param requestBody
     * @returns VirtualMachineDetail OK
     * @throws ApiError
     */
    public static updateVirtualMachine(
        vmSlug: string,
        requestBody: UpdateVmRequest,
    ): CancelablePromise<VirtualMachineDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/vm/{vmSlug}',
            path: {
                'vmSlug': vmSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Change virtual machine power state
     * @param vmSlug
     * @param newState
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static changeVmPowerState(
        vmSlug: string,
        newState: VmPowerStateTransition,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vm/{vmSlug}/changeState',
            path: {
                'vmSlug': vmSlug,
            },
            query: {
                'newState': newState,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Modify virtual machine configuration
     * @param vmSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static modifyVmConfig(
        vmSlug: string,
        requestBody: ReconfigureVmRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vm/{vmSlug}/config',
            path: {
                'vmSlug': vmSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Destroy a VM
     * Remove a stopped VM from the host. All datasets the VM uses will be marked detached and must be destroyed separately.
     * @param vmSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static destroyVirtualMachine(
        vmSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vm/{vmSlug}/destroy',
            path: {
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * @deprecated
     * Start a VM
     * @param vmSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static startVirtualMachine(
        vmSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vm/{vmSlug}/start',
            path: {
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * @deprecated
     * Stop a VM
     * @param vmSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static stopVirtualMachine(
        vmSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vm/{vmSlug}/stop',
            path: {
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get the vnc websocket URL for a VM
     * @param vmSlug
     * @returns VncUrlResponse OK
     * @throws ApiError
     */
    public static getVncUrlForVm(
        vmSlug: string,
    ): CancelablePromise<VncUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vm/{vmSlug}/vnc',
            path: {
                'vmSlug': vmSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get Websocket URL to a service on the VM (only supported for NAT networks)
     * @param port The port number to connect to on the VM
     * @param host The name or slug of the VM to connect to
     * @returns VncUrlResponse OK
     * @throws ApiError
     */
    public static getWsUrlForVmService(
        port: string,
        host: string,
    ): CancelablePromise<VncUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ws/ws_for_vm_service',
            query: {
                'port': port,
                'host': host,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
