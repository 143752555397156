import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import {
    Container,
    TabContent, 
} from "reactstrap"

import {HypervisorTasks, HypervisorTasksBadge} from './Tasks/Tasks';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import HypervisorStatusHeader from './Sidebar/StatusHeader';
import { useTranslation } from 'react-i18next';
import HypervisorDashboard from './Dashboard/Dashboard';
import { HostState } from 'generated';
import HypervisorJoining from './Joining';
import HypervisorActions from './Actions/Actions';
import HypervisorPhysicalDisk from './PhysicalDisk/PhysicalDisk';
import HypervisorStorage from './StoragePools/Storage';
import HypervisorNetwork from './Networks/Network';
import VirtualMachineList from './VirtualMachineList';
import HypervisorSettings from './Settings';
import GalliumTabPane from 'Components/Gallium/GalliumTabPane';
import HypervisorIncidents from './Incidents/Incidents';
import HypervisorSensors from './Sensors/Sensors';
import HypervisorVolumes from './Volumes/Volumes';
import HypervisorInfo from './Info';

const HypervisorDetailReady = ({data}) => {
    const {t} = useTranslation()
    const hypervisorDetail = data
    const hypervisorId = data.slug

    document.title = `${hypervisorDetail.name} | Gallium`

    ///Nav Bar Settings
    const [searchParams, setSearchParams] = useSearchParams()
    const [galliumNavTab, setgalliumNavTab] = useState(searchParams.get("tab") || "DASHBOARD");
    const galliumNavToggle = (tab: string) => {
        if (galliumNavTab !== tab) {
            //TODO Fix thi
            setgalliumNavTab(tab);
        }
    };

    const verticalNav = [
        {
            "tab": "DASHBOARD", 
            "type": "Tab",
            "title": t("hypervisor.nav.DASHBOARD.title"), 
            "icon": t("hypervisor.nav.DASHBOARD.icon"),
            "content": HypervisorDashboard({hypervisorSlug: hypervisorId})
        },
        { 
            "type":"Divider", 
            "title":"Workloads"
        },
        {
            "tab":"VIRTUALMACHINES",
            "type":"Tab", 
            "title": t("hypervisor.nav.VIRTUALMACHINES.title"), 
            "icon": t("hypervisor.nav.VIRTUALMACHINES.icon"),
            "content": VirtualMachineList({hypervisorDetail: hypervisorDetail})
        },
        { 
            "type":"Divider", 
            "title":"Resources"
        },
        {
            "tab":"VOLUMES",
            "type":"Tab", 
            "title": t("hypervisor.nav.VOLUMES.title"), 
            "icon": t("hypervisor.nav.VOLUMES.icon"),
            "content": HypervisorVolumes({hypervisorDetail: hypervisorDetail})
        },
        {
            "tab":"NETWORKS",
            "type":"Tab", 
            "title": t("hypervisor.nav.NETWORKS.title"), 
            "icon": t("hypervisor.nav.NETWORKS.icon"),
            "content": HypervisorNetwork({hypervisor: hypervisorDetail})
        },
        {
            "tab":"STORAGEPOOLS",
            "type":"Tab", 
            "title": t("hypervisor.nav.STORAGEPOOLS.title"), 
            "icon": t("hypervisor.nav.STORAGEPOOLS.icon"),
            "content": HypervisorStorage({hypervisor: hypervisorDetail})
        },
        { 
            "type":"Divider", 
            "title":"Monitoring"
        },
        {
            "tab":"INCIDENTS",
            "type":"Tab", 
            "title": t("hypervisor.nav.INCIDENTS.title"), 
            "icon": t("hypervisor.nav.INCIDENTS.icon"),
            // "badge": HypervisorTasksBadge({hypervisorSlug: hypervisorId}),
            "content": HypervisorIncidents({hypervisorDetail: hypervisorDetail})
        },
        {
            "tab":"TASKS",
            "type":"Tab", 
            "title": t("hypervisor.nav.TASKS.title"), 
            "icon": t("hypervisor.nav.TASKS.icon"),
            "badge": HypervisorTasksBadge({hypervisorSlug: hypervisorId}),
            "content": HypervisorTasks({hypervisorSlug: hypervisorId})
        },
        {
            "tab":"SENSORS",
            "type":"Tab", 
            "title": t("hypervisor.nav.SENSORS.title"), 
            "icon": t("hypervisor.nav.SENSORS.icon"),
            // "badge": HypervisorTasksBadge({hypervisorSlug: hypervisorId}),
            "content": HypervisorSensors()
        },
        {
            "tab":"PHYSICALDISK",
            "type":"Tab", 
            "title": t("hypervisor.nav.PHYSICALDISK.title"), 
            "icon": t("hypervisor.nav.PHYSICALDISK.icon"),
            "content": HypervisorPhysicalDisk()
        },
        { 
            "type":"Divider", 
            "title":"Hypervisor"
        },
        {
            "tab":"INFO",
            "type":"Tab", 
            "title": t("hypervisor.nav.INFO.title"), 
            "icon": t("hypervisor.nav.INFO.icon"),
            "content": HypervisorInfo({hypervisor: hypervisorDetail})
        },
        {
            "tab":"SETTINGS",
            "type":"Tab", 
            "title": t("hypervisor.nav.SETTINGS.title"), 
            "icon": t("hypervisor.nav.SETTINGS.icon"),
            "content": HypervisorSettings({hypervisor: hypervisorDetail})
        },
        {
            "tab":"ACTIONS",
            "type":"Tab", 
            "title": t("hypervisor.nav.ACTIONS.title"), 
            "icon": t("hypervisor.nav.ACTIONS.icon"),
            "content": HypervisorActions({hypervisor: hypervisorDetail})
        },
    ]

    const VerticalNavTab = ({item}) => {
        return (
            <React.Fragment>
                <li className={galliumNavTab === item.tab ? "active" : ""}>
                    <Link to="#" onClick={() => galliumNavToggle(item.tab)}>
                        <div className="d-flex align-items-center">
                            <div className={`flex-shrink-0 align-self-center me-2 ms-0`}>
                                <FontAwesomeIcon icon={byPrefixAndName.fal[item.icon]} className='me-2 fa-fw'/>

                                
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-truncate mb-0">{item.title}</p>
                            </div>
                            {item.badge &&
                                <div className="flex-shrink-0" id={"badge" + item.id}>
                                    {item.badge}
                                </div>
                            }
                        </div>
                    </Link>
                </li>
            </React.Fragment>
        )
    }

    const Divider = ({item}) => {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center px-4 mb-2 mt-2">
                    <div className="flex-grow-1">
                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            {item.title}
                        </h4>
                    </div>             
                </div>
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
        
            <Container fluid>
                <div className="vertical-nav-wrapper d-md-flex gap-0 mx-n4 mt-n4 p-0">
                    <div className="nav-left-container border-end">
                        <HypervisorStatusHeader data={hypervisorDetail}/>
                        <div className="nav-left-items-container pt-0 flex-grow-1">
                            <ul className="list-unstyled left-nav-list" id="navList">
                                {(verticalNav).map((chat, index) => (
                                    chat.type === "Divider" ? (
                                        <Divider item={chat} key={index} />
                                    ):(
                                        <VerticalNavTab item={chat} key={index} />
                                    )
                                    
                                ))}
                            </ul>
                        </div>
                    </div>
                    <TabContent activeTab={galliumNavTab} className="w-100">
                        { verticalNav.map((tab,index)=>(
                            <GalliumTabPane 
                                tabId={tab.tab} 
                                key={"tab"+index}
                                heading={tab.title}
                                icon={tab.icon}
                                tabToggle={setgalliumNavTab}>
                                {tab.content}
                            </GalliumTabPane>                                
                        ))}
                    </TabContent>
                </div>
            </Container>
        </React.Fragment >
    )
}

const HypervisorDetail = () => {
    const hypervisorId = useParams().id;
    const {data: hypervisorDetail, error, isLoading } = useGetHost(hypervisorId, 10000)

    return (
        <React.Fragment>
            <div className="page-content">
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    hypervisorDetail.hostState === HostState.JOIN_PENDING ? 
                        (<HypervisorJoining/>):
                        (<HypervisorDetailReady data={hypervisorDetail}/>)
                )}
            </div>
        </React.Fragment >
    )
}

export default HypervisorDetail;