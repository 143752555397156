import ErrorNotice from 'Components/Gallium/ErrorNotice';
import Loader from 'Components/Gallium/Loader';
import { useGetDownloads } from 'GalliumAPIHooks/Downloads/DownloadHooks';
import React from 'react';

import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';

const JoinInstructions = () => {

    const {data, isLoading, error} = useGetDownloads()

    const trackDownload = () => {
        //@ts-expect-error
        window.Intercom('trackEvent', 'download-image');
    }

    return (    
        <React.Fragment>
            <Card>
                <CardBody>
                    <h5 className="fs-14 mb-3">Creating Boot Media</h5>
                    <p className='text-muted'>You will need to download the Gallium boot media and flash it to a suitable USB or SD Card storage device. Or, if using a hosting provide; you can use their imaging or flashing tools to apply the image.   </p>

                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <ListGroup className="list-group-fill-primary">
                            <ListGroupItem tag="a" href={data.baseImage.url} className="list-group-item-action" onClick={trackDownload}><i className="ri-download-2-fill align-middle me-2"></i>Gallium Boot Image {`(${data.baseImage.sizeMb}MB)`}</ListGroupItem>
                            <ListGroupItem tag="a" target="_blank" href="https://etcher.balena.io/#download-etcher" className="list-group-item-action"><i className="ri-external-link-line align-middle me-2"></i>Etcher (Third Party)</ListGroupItem>
                        </ListGroup>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default JoinInstructions;