import React, { useState } from 'react';

import {
    Button,
} from "reactstrap"

import NetworkAddModal from './NetworkAddModal';
import { HostDetail } from 'generated';
import { useTranslation } from 'react-i18next';

interface NetworkAddButtonProps {
    hypervisor: HostDetail
    adaptorSlug: string
}

const NetworkAddButton: React.FC<NetworkAddButtonProps> = ({ hypervisor, adaptorSlug }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>
            <NetworkAddModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                hypervisor={hypervisor}
                adaptorSlug={adaptorSlug}
            />

            <Button color="soft-primary" className='mt-1 btn-sm btn-block' onClick={() => setShowModal(true)}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                {t('translation:hypervisor:addVLAN')}
            </Button>


        </React.Fragment>
    )
};

export default NetworkAddButton;