import React from 'react';
import { Button, Container } from 'reactstrap';

import SSHKeys from './SSHKeyList';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const SSHKeysList = () => {
    const {t} = useTranslation()

    const crumbs = [
        {
            name: t("mainNav.inventory"),
        },
        {
            name: t("sshKey.title"),
        }
    ]
    document.title = t("sshKey.list") + " | Gallium";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("sshKey.list")} crumbs={crumbs}>
                        <Link to="/inventory/sshkeys/import">
                            <Button color="soft-primary" className="add-btn me-1">
                                <FontAwesomeIcon icon={byPrefixAndName.fab["github"]} className='me-2'/>
                                {t("sshKey.import")}
                            </Button>
                        </Link>
                        <Link to="/inventory/sshkeys/add">
                            <Button color="soft-primary"  className="add-btn me-1">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["circle-plus"]} className='me-2'/>
                                {t("sshKey.add")}
                            </Button>
                        </Link>
                    </GalliumBreadcrumb>

                    <SSHKeys />
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SSHKeysList;