// @ts-nocheck
import React from 'react';
import { Label } from 'reactstrap';
import { useFormikContext } from "formik";
import Select from "react-select";
import { useGetOrgInfo } from 'GalliumAPIHooks/Org/OrgHooks';
import { OrgUserRole } from 'generated';

const AlertProfilesExistingUserComponent = () => {
    const { setFieldValue, values } = useFormikContext();

    const { data } = useGetOrgInfo();

    const userOptions = data?.users
    .filter(user => user.role === OrgUserRole.OWNER || user.role === OrgUserRole.USER)
    .map(user => ({
        value: user.slug,
        label: user.name
    })) || [];

    // Ensure the selected options are in sync with Formik's state
    const slugSelect = values.userSlugs.map(slug => userOptions.find(option => option.value === slug));

    const handleMulti = (selectedOptions) => {
        // Update Formik's state
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFieldValue('userSlugs', selectedValues);
    };

    return (    
        <React.Fragment>
            <div className="mb-3">
                <Label htmlFor="choices-multiple-default" className="form-label">Organisation Users</Label>                                                        
                <Select
                    value={slugSelect}
                    isMulti={true}                                                            
                    onChange={handleMulti}
                    options={userOptions}
                />
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesExistingUserComponent;