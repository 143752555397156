import React from 'react';

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useGetBackupSetDetails } from 'GalliumAPIHooks/Backup/BackupHooks';
import BackupSetTaskListComponent from './BackupSetTaskList';
import { BackupProfile, VirtualMachineDetail } from 'generated';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { LocalTimeString } from 'Components/Gallium/GalliumTimeHelpers';
import { useTranslation } from 'react-i18next';

interface VirtualMachineBackupProfileProps {
    profile: BackupProfile
    setSlug: string
    virtualMachine: VirtualMachineDetail
}

const VirtualMachineBackupProfile: React.FC<VirtualMachineBackupProfileProps> = ({profile, setSlug, virtualMachine}) => {
    const {t} = useTranslation()
    const {data: backupSet, isLoading, error} = useGetBackupSetDetails(profile.slug, setSlug, 600)
    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <>
                    <Card className='mb-2'>
                        <CardBody className='p-0 ps-3'>
                            <Row>
                                <Col md={4} className='border-end p-2'>
                                    <Label>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["boxes-packing"]} className='fa-fw me-2'/>
                                        {t("virtualMachine.backup.labels.backupProfile")}
                                    </Label>
                                    <Link to={`/backups/${profile.slug}`} className="d-block">
                                        {profile.name}   
                                    </Link>
                                </Col>
                                <Col md={4} className='border-end p-2'>
                                    <Label>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["clock"]} className='fa-fw me-1'/> 
                                        {t("virtualMachine.backup.labels.nextRun")}
                                    </Label>
                                    <span className='d-block'>
                                    {LocalTimeString(profile.nextRunTime)}
                                    </span>
                                </Col>
                                <Col md={4} className='p-2'>
                                    <Label>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["server"]} className='fa-fw me-2'/>
                                        {t("virtualMachine.backup.labels.backupTarget")}              
                                    </Label>
                                <Link to={`/hypervisors/${profile.targetHost.slug}`} className="d-block">
                                    {profile.targetHost.name}   
                                </Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <BackupSetTaskListComponent 
                        profileSlug={profile.slug} 
                        setSlug={setSlug} 
                        backupTasks={backupSet.backupTasks} 
                        virtualMachine={virtualMachine}
                    />
                </>
            )}
        </React.Fragment>
    )
}

export default VirtualMachineBackupProfile;