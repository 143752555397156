import React from 'react';

import { Field } from "formik";

import {
    Label,
} from "reactstrap"

import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import { useTranslation } from 'react-i18next';

const Startup = () => {
    const {t} = useTranslation()
    return (
        <GalliumFormHeader icon="sunrise" title="Startup">

        <div className="mt-0 mb-3">
            <div className="form-check form-switch form-switch-lg" dir="ltr">
                <Field
                    id="autoStartField" 
                    name="autoStart" 
                    type="checkbox" 
                    className="form-check-input"
                />
                <Label className="form-check-label" htmlFor="autoStart">{t("virtualMachine.create.autostartText")}</Label>
            </div>
        </div>
        <div className="mt-0 mb-3">
            <div className="form-check form-switch form-switch-lg" dir="ltr">
                <Field
                    id="createStartedField" 
                    name="createStarted" 
                    type="checkbox" 
                    className="form-check-input"
                />
                <Label className="form-check-label" htmlFor="autoStart">{t("virtualMachine.create.startOnComplete")}</Label>
            </div>
        </div>
                    
        </GalliumFormHeader>
    )
};

export default Startup;
