import DeleteModal from "Components/Common/DeleteModal";
import { useDeleteBackupSet } from "GalliumAPIHooks/Backup/BackupHooks";
import { BackupSetState } from "generated";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const Actions = (cell) => {
    const {t} = useTranslation()
    const profileSlug = useParams().profileSlug
    const archiveAvailable = cell.row.original.state === BackupSetState.ENABLED

    const { trigger, isMutating } = useDeleteBackupSet(profileSlug, cell.getValue())

    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);
    
    const handleDelete = () => {
        trigger();
        setShowDeleteModal(false);
    };

    if (!archiveAvailable) return (null)

    return (
        <React.Fragment>
            <DeleteModal    
                show={showDeleteModal}
                onDeleteClick={handleDelete}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={cell.row.original.vmReference.name}
                isWorking={isMutating}
                message={t("backupSet.archiveMessage")}
                deleteButtonText={t("common.archive")}
            />
            <Link to="#" className="btn btn-soft-danger btn-sm" onClick={() => {setShowDeleteModal(true) }}>
                {t("common.archive")}
            </Link>
        </React.Fragment>
    );
};


export {Actions}