/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVmRequest } from '../models/CreateVmRequest';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { HardwareInfoResponse } from '../models/HardwareInfoResponse';
import type { HostDetail } from '../models/HostDetail';
import type { HostMetricResponse } from '../models/HostMetricResponse';
import type { HostPowerStateRequest } from '../models/HostPowerStateRequest';
import type { HostSummary } from '../models/HostSummary';
import type { HostUpdateRequest } from '../models/HostUpdateRequest';
import type { JoinHostRequest } from '../models/JoinHostRequest';
import type { JoinHostResponse } from '../models/JoinHostResponse';
import type { ServiceGatewayHostInfoResponse } from '../models/ServiceGatewayHostInfoResponse';
import type { UpdateRequestType } from '../models/UpdateRequestType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HostService {

    /**
     * Get all hosts
     * @returns HostSummary OK
     * @throws ApiError
     */
    public static getAllHosts(): CancelablePromise<Array<HostSummary>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Add a host
     * @param requestBody
     * @returns JoinHostResponse The host with the specified join code was added successfully,
     * @throws ApiError
     */
    public static joinHost(
        requestBody: JoinHostRequest,
    ): CancelablePromise<JoinHostResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/join',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The host with the specified join code was not found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a specific host
     * @param hostSlug
     * @returns HostDetail OK
     * @throws ApiError
     */
    public static getHost(
        hostSlug: string,
    ): CancelablePromise<HostDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update a specific host
     * @param hostSlug
     * @param requestBody
     * @returns HostDetail OK
     * @throws ApiError
     */
    public static updateHost(
        hostSlug: string,
        requestBody: HostUpdateRequest,
    ): CancelablePromise<HostDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hosts/{hostSlug}',
            path: {
                'hostSlug': hostSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Archive a host
     * Mark a host as archived. After calling this, it will no longer be visible in the UI. It will be automatically re-added if it connects again in future.
     * @param hostSlug
     * @returns GalliumApiSuccessResponse Archive a specific host
     * @throws ApiError
     */
    public static archiveHost(
        hostSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/hosts/{hostSlug}',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get a host's hardware details
     * @param hostSlug
     * @returns HardwareInfoResponse OK
     * @throws ApiError
     */
    public static getHostHardware(
        hostSlug: string,
    ): CancelablePromise<HardwareInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/hardware',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get Host Metrics
     * @param hostSlug
     * @returns HostMetricResponse OK
     * @throws ApiError
     */
    public static getHostMetrics(
        hostSlug: string,
    ): CancelablePromise<HostMetricResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/metrics',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * create a new vm on the specified host
     * @param hostSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse Create a new vm on the specific host
     * @throws ApiError
     */
    public static newVm(
        hostSlug: string,
        requestBody: CreateVmRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/newvm',
            path: {
                'hostSlug': hostSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Issue host power state command (shutdown/reboot)
     * @param hostSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static hostPowerStateChange(
        hostSlug: string,
        requestBody: HostPowerStateRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/power',
            path: {
                'hostSlug': hostSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get information about service gateways related to a host. Configured service gateways, consumed ports, eligible VMs,
     * @param hostSlug
     * @returns ServiceGatewayHostInfoResponse OK
     * @throws ApiError
     */
    public static getHostServiceGatewayInfo(
        hostSlug: string,
    ): CancelablePromise<ServiceGatewayHostInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/sg',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Trigger an OS update on the specified host
     * @param hostSlug
     * @param requestType
     * @param reboot
     * @returns GalliumApiSuccessResponse OS update command sent to the host.
     * @throws ApiError
     */
    public static updateHostOs(
        hostSlug: string,
        requestType: UpdateRequestType,
        reboot: boolean,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/update_host_os',
            path: {
                'hostSlug': hostSlug,
            },
            query: {
                'requestType': requestType,
                'reboot': reboot,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
