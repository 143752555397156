import React, {useState} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

import logoLight from "assets/images/logo-light.png";
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';

import ErrorAlert from 'Components/Gallium/ErrorHelper';

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';

//formik
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { usePasswordResetConfirm, usePasswordResetValidate } from '../../GalliumAPIHooks/User/UserHooks';
import { ConfirmPasswordResetRequest } from '../../generated/models/ConfirmPasswordResetRequest';
import { GalliumApiErrorResponse } from 'generated';

const SetPassword = () => {

    document.title = "Create New Password | Gallium";

    const resetToken = useParams().token;
    
    const {data, error, isLoading} = usePasswordResetValidate(resetToken)
    const {trigger, isMutating} = usePasswordResetConfirm()

    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleSetPasswordFail = (error) => {
        setPasswordFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleSetPasswordSuccess = () => {
        navigate("/login")
    }

    const handleSetPasswordRequest = (values: ConfirmPasswordResetRequest) => {
        const options = {
            onError(error: object) {
                handleSetPasswordFail(error)
            },
            onSuccess() {
                handleSetPasswordSuccess()
            },
            revalidate: false
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const setPasswordFormik = useFormik({
        initialValues: {
            password: "",
            confirm_password: "",  // Corrected typo here
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .required('Password is required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        }),
        onSubmit: (values) => {
            const requestBody: ConfirmPasswordResetRequest = {
                passwordNew: values["password"],
                token: resetToken
            }
            handleSetPasswordRequest(requestBody);
        }
    });


    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="45" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Reset password</h5>
                                            <p className="text-muted">Reset access to your account by setting a new password</p>
                                        </div>

                                        <ErrorAlert errorObj={errorObject} />

                                        <div className="p-2 mt-4">
                                            <FormikProvider value={setPasswordFormik}>

                                                {data?.nameRequired ? (
                                                    <Field
                                                        label="Your Name"
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        className="pe-5"
                                                        placeholder="Enter your Name"
                                                        component={GalliumInput}
                                                    />
                                                ):(null)}
                                            
                                                <Field
                                                    label="Password"
                                                    name="password"
                                                    id="password"
                                                    type="password"
                                                    className="mb-3"
                                                    placeholder="Enter New Password"
                                                    component={GalliumInput}
                                                />

                                                <Field
                                                    label="Confirm Password"
                                                    name="confirm_password"
                                                    id="confirm_password"
                                                    type="password"
                                                    className="mb-3"
                                                    placeholder="Confirm New Password"
                                                    component={GalliumInput}
                                                />

                                                {data?.mfaRequired ? (
                                                    <Field
                                                        label="Multi Factor Code"
                                                        name="otp"
                                                        id="otp"
                                                        type="text"
                                                        className="pe-5"
                                                        placeholder="Enter the code from your Authenticator"
                                                        component={GalliumInput}
                                                    />
                                                ):(null)}

                                                <div className="mt-3 text-end">
                                                    <GalliumSubmitButton formik={setPasswordFormik} color="success" spinner={isMutating}>
                                                        Reset Password
                                                    </GalliumSubmitButton>
                                                </div>

                                            </FormikProvider>
                                        </div>
                                                    
                                    </CardBody>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default SetPassword;