import React, { useMemo } from 'react';
import TableContainer from '../../../Components/Gallium/TableContainerNew';

import { Card, CardBody, Col } from 'reactstrap';

import {
    Name,
    VirtualMachines
} from "./HypervisorListCol";

import 'react-toastify/dist/ReactToastify.css';
import { useGetAllHosts } from 'GalliumAPIHooks/Host/HostHooks';
import GettingStarted from './GettingStarted';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { ColStatusBadge } from 'Components/Gallium/Columns';

const AllHypervisors = () => {

    const {data: hypervisorsList, isLoading, error} = useGetAllHosts()

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-6",
                cell: (cellProps) => {
                    return <Name {...cellProps} />;
                },
            },
            {
                header: "Virtual Machines",
                accessorKey: "vmCountTotal",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <VirtualMachines {...cellProps} />;
                },
            },
            {
                header: "Status",
                accessorKey: "hostState",
                enableColumnFilter: false,
                className: "col-3 text-end",
                cell: (cellProps) => {
                    return <ColStatusBadge {...cellProps} />;
                },
            },
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'hostState',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            
            <GettingStarted />
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <div className="row">
                    <Col lg={12}>
                        <Card>
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={hypervisorsList || []}
                                    isGlobalFilter={true}
                                    customPageSize={8}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for a Hypervisor'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            )}

        </React.Fragment>
    );
};

export default AllHypervisors;