import React, { useState } from 'react';

import {
    Modal,
    ModalBody,
    ModalHeader,
    Col,
    Row,
    Label,
} from "reactstrap"

import { useUpdateSSHKey } from 'GalliumAPIHooks/SSH/SSHHooks';
import { GalliumApiErrorResponse, SshKey } from 'generated';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { toast } from 'react-toastify';
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';

interface EditSSHKeyModalProps {
    onCloseClick?: () => void;
    onDeleteClick?: () => void;
    show: boolean;
    selectedKey?: SshKey;
}

const EditSSHKeyModal: React.FC<EditSSHKeyModalProps> = ({ show, onCloseClick, selectedKey }) => {
    const { trigger, isMutating } = useUpdateSSHKey()

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleEditSSHKeyFail = (error: GalliumApiErrorResponse) => {
        editKeyFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleEditSSHKeySuccess = () => {
        toast.success("SSH Key Updated")
    }

    const handleEditSSHKeyRequest = (values: SshKey) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleEditSSHKeyFail(error)
            },
            onSuccess() {
                handleEditSSHKeySuccess()
                onCloseClick()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const editKeyFormik = useFormik({
        enableReinitialize: true, // This will update the form when initialValues change
        initialValues: {
            name: selectedKey?.name || '',
            slug: selectedKey?.slug || '',
            autoAdd: selectedKey?.autoAdd || false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            autoAdd: Yup.boolean()
        }),
        onSubmit: (values) => {
            handleEditSSHKeyRequest(values)
        },
    });

    return (
        <React.Fragment>
            <Modal
                isOpen={show}
                toggle={onCloseClick}
                centered
                size="md"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-info-subtle" toggle={onCloseClick}>
                Edit SSH Key
                </ModalHeader>
                <ModalBody className="modal-body">
                    <FormikProvider value={editKeyFormik}>
                        <Row className="g-3">
                            <Col lg={12}>
                                <ErrorAlert errorObj={errorObject} />
                                <div className="mb-3">
                                    <Field
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="A friendly name for this key"
                                        type="text"
                                        label="Name"
                                        component={GalliumInput}
                                    />
                                </div>
                                <div className="form-check form-switch form-switch-lg" dir="ltr">
                                    <Field
                                        id="autoAdd" 
                                        name="autoAdd" 
                                        type="checkbox" 
                                        className="form-check-input"  
                                    />
                                    <Label className="form-check-label" htmlFor="autoAdd">Automatically add to new Virtual Machines</Label>
                                </div>
                                <div className="text-end pe-0">   
                                    <GalliumSubmitButton formik={editKeyFormik} spinner={isMutating} color="success">
                                        Update
                                    </GalliumSubmitButton>
                                </div>
                            </Col>
                        </Row>
                    </FormikProvider>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default EditSSHKeyModal;