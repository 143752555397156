import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import ServiceGatewayListComponent from './List';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useGetOrgInfo } from 'GalliumAPIHooks/Org/OrgHooks';

const ServiceProviderOrgsList = () => {
    const {t} = useTranslation()

    const {data, error, isLoading} = useGetOrgInfo()

    document.title = "Service Gateways | Gallium";
    const crumbs = [
        {
            name: t("serviceProvider.title"),
        },
        {
            name: t("serviceProvider.orgs.title"),
        }
    ]
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title={t("serviceProvider.orgs.list")} crumbs={crumbs}>
                    {!isLoading && !error && data.availableActions.createChildOrg ? (
                        <Link to="/serviceprovider/org/create">
                            <Button color="soft-primary" className="add-btn">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["circle-plus"]} className='me-2'/>
                                {t("serviceProvider.orgs.add")}
                            </Button>
                        </Link>
                    ):(null)}
                </GalliumBreadcrumb>
                <ServiceGatewayListComponent />
            </Container>
        </div>
    );
};

export default ServiceProviderOrgsList;
                    