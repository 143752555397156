import React from 'react';
import {
    CardBody,
    Spinner,
} from 'reactstrap';
import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import StoragePoolCard from './StoragePoolCard';
import AddStoragePoolCard from './AddStoragePoolCard';
import AddBackupStoragePoolCard from './AddBackupStoragePoolCard';

type HypervisorStorageProps = {
  hypervisor: HostDetail;
};

const HypervisorStorage: React.FC<HypervisorStorageProps> = ({ hypervisor }) => {
    const { t } = useTranslation();
    const storagePools = hypervisor.storagePools;

    if (hypervisor.pendingStorageCommand) return (
        <GalliumPageColumn>
            <CardBody className="p-5 mt-5 mb-5 text-center">
                <Spinner color="primary" type="grow" />
                <p>{t("hypervisor.storagePool.commandInProgress")}</p>
            </CardBody> 
        </GalliumPageColumn> 
    )

    return (
        <GalliumPageColumn>
            {storagePools.map((pool, index) => (
                <StoragePoolCard pool={pool} key={`pool_${index}`} />
            ))}
            <AddStoragePoolCard hypervisor={hypervisor} />
            <AddBackupStoragePoolCard hypervisor={hypervisor} />
        </GalliumPageColumn>
    );
};

export default HypervisorStorage;
