import React from 'react';

import { HostDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

type HypervisorInfoCardProps = {
  hypervisor: HostDetail;
};

const HypervisorInfoCard: React.FC<HypervisorInfoCardProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (
        <Card className='mb-2'>
            <CardHeader >
                <h4 className="card-title mb-0">
                    <FontAwesomeIcon icon={byPrefixAndName.fal["life-ring"]} className='fa-fw me-2'/>
                    {t("hypervisor.hypervisorInfo.cardTitle")}
                </h4>
            </CardHeader>
            <CardBody className='pb-0 pt-2'>
                <dl className="row mb-0">
                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{t("hypervisor.hypervisorInfo.identification")}</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">{t("hypervisor.hypervisorInfo.hypervisorSlug")}</dt>
                            <dd className="col-sm-8 text-end user-select-all">{hypervisor.slug}</dd>
                        </dl>
                    </dd>
                    <dt className="col-sm-3">{t("hypervisor.hypervisorInfo.version")}</dt>
                    <dd className="col-sm-9">
                        <dl className="row mb-0">
                            <dt className="col-sm-6 text-truncate">{t("hypervisor.hypervisorInfo.updateRequired")}</dt>
                            <dd className="col-sm-6 text-end">{hypervisor.hostOsUpdateAvailable ? "Yes" : "No"}</dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-6 text-truncate">{t("hypervisor.hypervisorInfo.versionRunning")}</dt>
                            <dd className="col-sm-6 text-end">{hypervisor.hostOsVersionCurrent || '-'}</dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-6 text-truncate">{t("hypervisor.hypervisorInfo.versionAvailable")}</dt>
                            <dd className="col-sm-6 text-end">{hypervisor.hostOsVersionAvailable}</dd>
                        </dl>
                    </dd>
                </dl>
            </CardBody>
        </Card>

                
    )
};

export default HypervisorInfoCard;