import React, { useMemo } from 'react';
import TableContainer from '../../../Components/Gallium/TableContainerNew';
import { Link } from "react-router-dom";

import { Card, CardBody } from 'reactstrap';

import {
    Autostart,
    Name,
    Resources,
    Status,
} from "Components/Gallium/VM/VirtualMachineListCol";

import 'react-toastify/dist/ReactToastify.css';
import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import ColVmControls from 'Components/Gallium/Columns/ColControls';

interface VirtualMachineListProps {
    hypervisorDetail: HostDetail;
}
const VirtualMachineList: React.FC<VirtualMachineListProps> = ({hypervisorDetail}) => {
    const {t} = useTranslation()

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-7",
                cell: (cellProps) => {
                    return <Name {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "vcpus",
                enableColumnFilter: false,
                className: "col-1",
                cell: (cellProps) => {
                    return <Resources {...cellProps} />;
                },
            },
            {
                header: "Auto On",
                accessorKey: "autoStart",
                enableColumnFilter: false,
                id: "autoStart",
                className: "col-1 text-center",
                cell: (cellProps) => {
                    return <Autostart {...cellProps} />;
                },
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-1",
                cell: (cellProps) => {
                    return <Status {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-2 text-end",
                cell: (cellProps) => {
                    return <ColVmControls {...cellProps} />;
                },
            },
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'name',
                desc: true, 
            },
        ],[]
    );

    const addVMButton = () => {
        return (
            <Link 
                className='btn btn-primary font-16 btn-block btn-soft-primary'
                to={`/vm/create/${hypervisorDetail.slug}`}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New VM
            </Link>
        )
    }

    return (
        <React.Fragment>
            <GalliumPageColumn>
                <Card id="vmlist">
                    <CardBody className="pt-0">
                        <TableContainer
                            columns={columns}
                            data={hypervisorDetail?.vms || []}
                            isGlobalFilter={true}
                            customPageSize={8}
                            sorting={sorting}
                            button={hypervisorDetail.availableActions.createVm ?(addVMButton()):(null)}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a VM'
                        />
                    </CardBody>
                </Card>
            </GalliumPageColumn>
        </React.Fragment>
    );
};

export default VirtualMachineList;