import TableContainer from 'Components/Gallium/TableContainerNew';
import { ColBoolean, ColString, ColStatusBadge } from 'Components/Gallium/Columns';
import ColHeaderCenter from 'Components/Gallium/Columns/ColHeaderCenter';
import React, { useMemo } from 'react';


import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"
import ColTimeAgo from 'Components/Gallium/Columns/ColTimeAgo';

const NotificationsList = ({data}) => {

    const columns = useMemo(
        () => [
            {
                header: "Generated",
                accessorKey: "issuedAt",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />;
                },
            },
            {
                header: "Target",
                accessorKey: "target",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <ColString {...cellProps} />;
                },
            },
            {
                header: ColHeaderCenter("Delivered"),
                accessorKey: "deliverySuccess",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <ColBoolean {...cellProps} />;
                },
            },
            {
                header: "Delivered At",
                accessorKey: "deliveredAt",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />;
                },
            },
            {
                header: "Response",
                accessorKey: "responseCode",
                enableColumnFilter: false,
                className: "col-2 text-end",
                cell: (cellProps) => {
                    return <ColStatusBadge {...cellProps} />;
                },
            },
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'issuedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="serviceGatewayList">
                        <CardBody className="pt-0">
                            <TableContainer
                                columns={columns}
                                data={(data || [])}
                                isGlobalFilter={true}
                                customPageSize={20}
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap mb-0"
                                theadClass="table-light table-nowrap"
                                thClass="table-light text-muted"
                                SearchPlaceholder='Search for an Notification...'
                                sorting={sorting}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>  
        </React.Fragment>
    );
};

export default NotificationsList;