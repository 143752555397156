import React from 'react';

import { Row, Label, Card, CardBody, Col, Button } from 'reactstrap';

import { Field, FieldArray, FormikProps } from "formik";
import { ServiceGatewayCreateRequest, ServiceGatewayHostInfoResponse, ServiceGatewayMappingType } from 'generated';
import { GalliumArrayInput } from 'Components/Gallium/GalliumForms'; 

const ServiceGatewayMappingsComponent = ({hostData, formik} : {hostData: ServiceGatewayHostInfoResponse, formik: FormikProps<ServiceGatewayCreateRequest>}) => {
    
    const newMappingObject = {
        type: ServiceGatewayMappingType.TCP, 
        ingresses: [
            {
                hostSlug: formik.values["hypervisor"],
                port: '',
                type: "HOST"
            }
        ],
        targets: [
            {
                vmSlug: '',
                targetPort: ''
            }
        ]
    }

    return (    
        <React.Fragment>
            <Card>
                <CardBody className="pb-1 mb-3 border-bottom border-bottom-dashed">
                    <div className="d-flex align-items-center">

                        <div className="flex-grow-1 overflow-hidden">
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label">Target</Label>
                                    
                                </Col>

                                <Col sm={2}>
                                    <Label className="form-label">Type</Label>
                                </Col>

                                <Col sm={3}>
                                    <Label className="form-label">Source</Label>

                                </Col>

                                <Col sm={3}>
                                    <Label className="form-label">Destination</Label>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-shrink-0 ms-2 ps-1">
                            <div className="d-flex gap-1">
                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"
                                >
                                    {/* <i className="lab  las la-trash-alt"></i> */}
                                </button>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <FieldArray name="mappings" render={arrayHelpers => (
                    <React.Fragment>
                        {formik.values["mappings"].map((item, key) =>(
                        
                            <CardBody key={key} className="pb-1 pt-1">
                                <div className="d-flex align-items-center">

                                    <div className="flex-grow-1 overflow-hidden pe-2">
                                    
                                        <Row>
                                            <Col sm={4} className="pe-1">
                                                <Field
                                                    id={`mappings[${key}].targets[${0}].vmSlugField`}
                                                    name={`mappings[${key}].targets[${0}].vmSlug`}
                                                    as="select"
                                                    className="mb-1 form-select"
                                                >
                                                    <option value="">Select Virtual Machine</option>
                                                    {hostData?.eligibleVms?.map((item) =>(
                                                        <option value={item.slug} key={item.slug}>{item.name}</option>
                                                    ))}
                                                </Field>
                                            </Col>

                                            <Col sm={2} className="pe-1">
                                                <Field
                                                    id={`mappings[${key}].typeField`}
                                                    name={`mappings[${key}].type`}
                                                    as="select"
                                                    className="mb-1 form-select"
                                                >
                                                    {Object.values(ServiceGatewayMappingType).map((item, index) =>(
                                                        <option value={item} key={index}>{item}</option>
                                                    ))}
                                                </Field>
                                            </Col>

                                            <Col sm={3} className="pe-1">
                                                <Field
                                                    id={`mappings[${key}].ingresses[${0}].portField`}
                                                    name={`mappings[${key}].ingresses[${0}].port`}
                                                    type="number"
                                                    className="mb-1 form-control"
                                                    placeholder="Source"
                                                    component={GalliumArrayInput}
                                                />

                                            </Col>

                                            <Col sm={3} className="pe-1">
                                                <Field
                                                    id={`mappings[${key}].targets[${0}].targetPortField`}
                                                    name={`mappings[${key}].targets[${0}].targetPort`}
                                                    type="number"
                                                    placeholder="Destination"
                                                    className="mb-1 form-control"
                                                    component={GalliumArrayInput}
                                                />
                                            </Col>
                                        </Row>  
                                    </div>

                                    <div className="flex-shrink-0 ms-2">
                                        <div className="d-flex gap-1 mb-1 ps-1">
                                            <Button color="light" className="btn btn-icon text-muted btn-sm fs-19"
                                                onClick={() => arrayHelpers.remove(key)}
                                            >
                                                <i className="lab las la-trash-alt"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        ))}
                        <CardBody>
                            <div className="d-flex flex-column h-100">
                                <Button color="soft-primary" className="mb-0" onClick={() => arrayHelpers.push(newMappingObject)}>Add Mapping</Button>
                            </div>
                        </CardBody>
                    </React.Fragment>
                )} />
            </Card>
        </React.Fragment>
    );
};

export default ServiceGatewayMappingsComponent;