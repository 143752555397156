import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import React, { SetStateAction, useState } from "react";
import { Button, Modal, ModalBody, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import { GalliumApiErrorResponse, ReconfigureVmRequest, VirtualMachineDetail, VmInterface, ConfigureVmNicAction } from "generated";
import { useTranslation } from "react-i18next";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FormikProvider, useFormik } from "formik";
import useAsyncCommand from "Components/Hooks/useAsyncCommand";


interface RemoveAdaptorModalProps {
    showModal: boolean,
    setShowModal: React.Dispatch<SetStateAction<boolean>>,
    adaptor: VmInterface,
    virtualMachine: VirtualMachineDetail
}

const RemoveAdaptorModal: React.FC<RemoveAdaptorModalProps> = ({ showModal, setShowModal, adaptor, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const closeModal = () => {
        removeNetworkAdaptorFormik.resetForm()
        setShowModal(false)
    }

    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const { isUpdating, startCommand } = useAsyncCommand({
        onError: (error: GalliumApiErrorResponse) => {
            removeNetworkAdaptorFormik.setErrors(error.errors || {});
            setErrorObject(error || {});
        },
        onComplete: () => {
            removeNetworkAdaptorFormik.resetForm()
            closeModal()
        }
    });

    const removeNetworkAdaptorFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
        },
        onSubmit: () => {
            const requestObj: ReconfigureVmRequest = {
                "networkInterfaces": [
                    {
                        action: ConfigureVmNicAction.DESTROY,
                        interfaceSlug: adaptor.interfaceSlug
                    }
                ]
            };
            startCommand(trigger, requestObj);
        }
    });

    return (
        <FormikProvider value={removeNetworkAdaptorFormik}>
            <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-ethernet-slash"]} className='mt-3 fs-36 text-danger'/>
                        <ErrorAlert errorObj={errorObject}/>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>{t('virtualMachine.network.removeAdaptorModalHeader')}</h4>
                            <p className="text-muted mx-4 mb-0">
                                {t('virtualMachine.network.removeAdaptorModalText')}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={closeModal}
                        >
                            {t('translation:common:cancel')}
                        </button>
                        <GalliumSubmitButton formik={removeNetworkAdaptorFormik} color="danger" waiting={isUpdating} spinner={isMutating}>
                            {t('virtualMachine.network.removeAdaptorButton')}
                        </GalliumSubmitButton>
                    </div>
                </ModalBody>
            </Modal>
        </FormikProvider>
    );
};

interface RemoveAdaptorButtonProps {
    adaptor: VmInterface
    virtualMachine: VirtualMachineDetail
}

const RemoveAdaptorButton: React.FC<RemoveAdaptorButtonProps> = ({ adaptor, virtualMachine }) => {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false);

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    return (
        <React.Fragment>
            <RemoveAdaptorModal
                showModal={showModal}
                setShowModal={setShowModal}
                adaptor={adaptor}
                virtualMachine={virtualMachine}
            />
            {virtualMachine.availableActions.modifyHardware ?(
                <Button color="soft-danger" className='mt-1 btn-sm btn-block' onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-ethernet-slash"]} className='me-1'/>
                    {t('virtualMachine.network.removeAdaptorButton')}
                </Button>
            ):(
                <React.Fragment>
                    <Button color="soft-secondary" id={`expandDetachButton-${adaptor.interfaceSlug}`} className='mt-1 btn-sm btn-block'>
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-ethernet-slash"]} className='me-1'/>
                        {t('virtualMachine.network.removeAdaptorButton')}
                    </Button>
                    <Tooltip
                        isOpen={isBtntooltipOpen}
                        placement="left"
                        target={`expandDetachButton-${adaptor.interfaceSlug}`}
                        toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                        <div>{t('translation:common:warningActionUnavailable')}</div>
                    </Tooltip>
                </React.Fragment>
            )}


        </React.Fragment>
    )
};

export default RemoveAdaptorButton;