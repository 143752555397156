import { GalliumApiErrorResponse, GalliumApiSuccessResponse, HostVolumeDetailsResponse, HostVolumeResponse, HostVolumeService, HostVolumeUpdateRequest } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useGetHostVolumes = (hostSlug: string) => {
    const getHostVolumes = () => HostVolumeService.getHostVolumes(hostSlug)

    const { data, error, isLoading } = useSWR<HostVolumeResponse, GalliumApiErrorResponse>(`volumes/${hostSlug}`, getHostVolumes);

    return { data, error, isLoading };
}

export const useGetHostVolumeDetails = (hostSlug: string, volumeSlug: string) => {
    const getHostVolumeDetails = () => HostVolumeService.getHostVolumeDetails(hostSlug, volumeSlug)

    const { data, error, isLoading } = useSWR<HostVolumeDetailsResponse, GalliumApiErrorResponse>(`volumes/${hostSlug}/${volumeSlug}`, getHostVolumeDetails);

    return { data, error, isLoading };
}

export const useDestroyHostVolume = (hostSlug: string, volumeSlug: string) => {
    const destroyHostVolume = () => HostVolumeService.destroyHostVolume(hostSlug, volumeSlug)

    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse>(`volumes/${hostSlug}`, destroyHostVolume);

    return { trigger, error, isMutating };
}

export const useUpdateHostVolumeDetails = (hostSlug: string, volumeSlug: string) => {
    const updateHostVolumeDetails = (key:null, {arg}:{arg: HostVolumeUpdateRequest}) => HostVolumeService.updateHostVolumeDetails(hostSlug, volumeSlug, arg)

    const { trigger, error, isMutating } = useSWRMutation<HostVolumeDetailsResponse, GalliumApiErrorResponse, string, HostVolumeUpdateRequest>(`volumes/${hostSlug}`, updateHostVolumeDetails);

    return { trigger, error, isMutating };
}