/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GalliumApiMessageResponse } from '../models/GalliumApiMessageResponse';
import type { SensorListResponse } from '../models/SensorListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HostSensorService {

    /**
     * Exclude a sensor from display on a host
     * @param hostSlug
     * @param sensorSlug
     * @returns GalliumApiMessageResponse OK
     * @throws ApiError
     */
    public static excludeSensor(
        hostSlug: string,
        sensorSlug: string,
    ): CancelablePromise<GalliumApiMessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/sensor/{sensorSlug}/exclude',
            path: {
                'hostSlug': hostSlug,
                'sensorSlug': sensorSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get information about sensors on a host.
     * @param hostSlug
     * @returns SensorListResponse OK
     * @throws ApiError
     */
    public static getHostSensorList(
        hostSlug: string,
    ): CancelablePromise<SensorListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/sensors',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
