import React from "react";

const KeyPreview = (cell) => {
    return (
        <React.Fragment>
            <span className="d-inline-block text-truncate" style={{ maxWidth: "320px" }}>
                <code> {cell.getValue()}</code>
            </span>
        </React.Fragment>
    );
};

const AutoAddEnabled = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue() === true ?
                <span className="text-success"><i className="ri-checkbox-circle-line fs-17 align-middle"></i></span>
                :
                <span className="text-muted"><i className="ri-close-circle-line fs-17 align-middle"></i></span>
            }
        </React.Fragment>
    );
};

const DateAdded = (cell) => {
    const date = new Date(cell.getValue());

    const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric"
    });
      
    return (
        <React.Fragment>
            <span className="text-muted">{formattedDate}</span>
        </React.Fragment>
    );
};


export {AutoAddEnabled, KeyPreview, DateAdded}