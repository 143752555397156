import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import ServiceGatewayListComponent from './List';
import { useTranslation } from 'react-i18next';

const ServiceGatewayList = () => {
    const {t} = useTranslation()
    document.title = "Service Gateways | Gallium";
    const crumbs = [
        {
            name: t("serviceGateways.title"),
            link: "/servicegateway"
        },
        {
            name: "View All",
        }
    ]
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title="Service Gateways" crumbs={crumbs}>
                    <Link to="/servicegateway/add">
                        <Button color="soft-primary" className="add-btn"><i className="mdi mdi-plus-circle-outline me-1"></i> New Service Gateway</Button>
                    </Link>
                </GalliumBreadcrumb>
                <ServiceGatewayListComponent />
            </Container>
        </div>
    );
};

export default ServiceGatewayList;
                    