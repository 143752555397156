import React, { ReactNode, useState } from 'react';
import Dropzone from 'react-dropzone-uploader'

import {
    Button,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap"

import Preview from './UploadPreview';
import DeleteModal from 'Components/Common/DeleteModal';
import { TemplateDisk, TemplateDiskType } from 'generated';
import { useDeleteTemplateDisk, useGetTemplateDisk } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { mutate } from 'swr';
import StatusBadge from 'Components/Gallium/StatusBadge';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { useTranslation } from 'react-i18next';


interface RenderDiskProps {
  disk: TemplateDisk;
}

const DeleteDiskButton: React.FC<RenderDiskProps> = ({ disk }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const { trigger } = useDeleteTemplateDisk(disk);
    const { t } = useTranslation();

    const handleDeleteDisk = () => {
        trigger();
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
        <DeleteModal
            show={deleteModal}
            onDeleteClick={() => handleDeleteDisk()}
            onCloseClick={() => setDeleteModal(false)}
            recordName={t('templates.uploadDiskForm.thisDisk')}
        />
        <Button
            className="btn btn-icon btn-soft-danger btn-sm btn btn-secondary"
            onClick={() => setDeleteModal(true)}
        >
            <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} />
        </Button>
        </React.Fragment>
    );
};

interface UploadDiskProps {
  disk: TemplateDisk;
}

const UploadDisk: React.FC<UploadDiskProps> = ({ disk }) => {
  const { t } = useTranslation();

  const DiskUploader = ({ disk }) => {
    const getUploadParams = () => { return { url: disk.uploadUrl } };

    const handleChangeStatus = (data) => {
      if (data.meta.status === 'done') {
        mutate(`templates/${disk.templateSlug}/${disk.slug}`);
      }
    };

    const classNames = {
      dropzone: "card card-body border-dashed mb-0",
      inputLabel: "text-muted text-center mb-2 p-2",
      preview: "",
    };

    const styles = {
      input: {
        display: "none",
      },
    };

    const accept = (diskType) => {
      return diskType === "DISK" ? ".qcow2" : ".iso";
    };

    const inputContent = () => {
      return (
        <React.Fragment>
          <FontAwesomeIcon icon={byPrefixAndName.fal["upload"]} /> {t('templates.uploadDiskForm.uploadDisk')} ({accept(disk.diskType)})
        </React.Fragment>
      );
    };

    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept={accept(disk.diskType)}
        maxFiles={1}
        multiple={false}
        classNames={classNames}
        inputContent={inputContent}
        PreviewComponent={Preview as any}
        styles={styles}
      />
    );
  };

  return (
    <DiskCard disk={disk}>
      <DiskUploader disk={disk} />
    </DiskCard>
  );
};

interface DiskCardProps {
  disk: TemplateDisk;
  children: ReactNode
}

const DiskCard: React.FC<DiskCardProps> = ({ children, disk }) => {
  const { t } = useTranslation();

  const getDiskLabel = (disk: TemplateDisk): string => {
    if (disk.blank) return t('templates.uploadDiskForm.blankDisk');
    if (!disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? t('templates.uploadDiskForm.uploadDisk')
        : t('templates.uploadDiskForm.uploadISO');
    }
    if (disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? t('templates.uploadDiskForm.uploadedDisk')
        : t('templates.uploadDiskForm.uploadedISO');
    }
    return "";
  };

  const getIcon = (disk: TemplateDisk) => {
    if (disk.blank) return byPrefixAndName.fal["hard-drive"];
    if (!disk.filename) {
      return disk.diskType === TemplateDiskType.DISK
        ? byPrefixAndName.fal["upload"]
        : byPrefixAndName.fal["compact-disc"];
    } else {
      return disk.diskType === TemplateDiskType.DISK
        ? byPrefixAndName.fal["hard-drive"]
        : byPrefixAndName.fal["compact-disc"];
    }
  };

  return (
    <Card className='mb-2'>
      <CardHeader className='border-0 pb-0'>
        <div className="d-flex">
          <div className="flex-grow-1">
            <span className="card-title">
              <FontAwesomeIcon icon={getIcon(disk)} className='me-2'/> {getDiskLabel(disk)}
            </span>
          </div>
          <div className="flex-shrink-0 text-end align-middle">
            <DeleteDiskButton disk={disk} />
          </div>
        </div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

const UploadedDisk: React.FC<RenderDiskProps> = ({ disk }) => {
    const { t } = useTranslation();
    return (
        <DiskCard disk={disk}>
            <dl className="row mb-0">
                <dt className="col-sm-3">{t('templates.uploadDiskForm.fileName')}</dt>
                <dd className="col-sm-9">{disk.filename}</dd>

                <dt className="col-sm-3">{t('templates.uploadDiskForm.sha256')}</dt>
                <dd className="col-sm-9"><code>{disk.sha256sum}</code> </dd>
            </dl>      
        </DiskCard>
    );
};

const BlankDisk: React.FC<RenderDiskProps> = ({ disk }) => {
    const { t } = useTranslation();
    return (
        <DiskCard disk={disk}>
            <dl className="row mb-0">
                <dt className="col-sm-3">{t('templates.uploadDiskForm.minimumSize')}</dt>
                <dd className="col-sm-9">{formatDiskSize(disk.minSizeMb)}</dd>
            </dl>
        </DiskCard>
    );
};

const RenderTemplateDiskComponent: React.FC<{ templateSlug: string; diskSlug: string }> = ({ templateSlug, diskSlug }) => {
    const { data, isLoading, error } = useGetTemplateDisk(templateSlug, diskSlug);

    if (isLoading || error) {
        return null;
    } else if (data.blank === false && data.filename === null) {
        return <UploadDisk disk={data} />;
    } else if (data.blank === false && data.filename) {
        return <UploadedDisk disk={data} />;
    } else {
        return <BlankDisk disk={data} />;
    }
};

export default React.memo(RenderTemplateDiskComponent);
