import React from 'react';


import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import HypervisorLicenseCard from './License';
import HypervisorInfoCard from './Info';

type HypervisorInfoProps = {
  hypervisor: HostDetail;
};

const HypervisorInfo: React.FC<HypervisorInfoProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (

        <GalliumPageColumn>
            <HypervisorInfoCard hypervisor={hypervisor} />
            <HypervisorLicenseCard hypervisor={hypervisor} />
        </GalliumPageColumn>
    )
};

export default HypervisorInfo;