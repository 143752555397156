import React from 'react';

import { toast } from "react-toastify";

import {
    Button,
    Badge
} from "reactstrap"

import { useUserProfileRequest, useUserProfileUpdateRequest } from "GalliumAPIHooks/User/UserProfileHooks";
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';

const MFAState = () => {
    const { data, isLoading } = useUserProfileRequest();
    const { trigger, isMutating } = useUserProfileUpdateRequest();

    const handleMFAEnableFail = (error) => {
        toast.error(error.response.data.error || "MFA could not be enabled", { autoClose: 3000 });
    }

    const handleMFAEnableSuccess = () => {
        toast.success("MFA Enabled", { autoClose: 3000 });
    }

    const handleMFAEnableRequest = () => {
        const values = {
            mfaEnabled: true
        }   
        const options = {
            onError(error: object) {
                handleMFAEnableFail(error)
            },
            onSuccess() {
                handleMFAEnableSuccess()
            },
        }
        trigger(values, options);
    }

    return (
        <React.Fragment>
            {!isLoading && data.mfaEnabled ? (
                <div className="mb-2 pb-2">
                    <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-3">Multi-factor Authentication</h5>
                            <p className="text-muted">Your account is protected. </p>
                        </div>
                        <div className="flex-shrink-0 ms-sm-3">
                            <h5><Badge color="success">Enabled</Badge></h5>
                        </div>
                    </div>
                </div>
            ):(
                <div className="mb-2 pb-2">    
                    <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-3">Multi-factor Authentication</h5>
                            <p className="text-muted">To enable first add two MFA Methods to your account. </p>
                        </div>
                        <div className="flex-shrink-0 ms-sm-3">
                            {isMutating ? (
                                <GalliumSpinnerButton color="success"/>
                            ):(
                                <Button disabled={data.mfaMethods.length <= 1 ? true: false} color="primary" className="add-btn me-1" onClick={() => {handleMFAEnableRequest()}}>Enable MFA</Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default MFAState;