
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import {
    Alert,
} from "reactstrap"

type HypervisorStorageWarningProps = {
  show: boolean;
};

const HypervisorStorageWarning: React.FC<HypervisorStorageWarningProps> = ({show}) => {

    return (
        <React.Fragment>
            {show ? (
                <Alert color="info" className='mt-0 mb-2'>
                    <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["container-storage"]}/>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="alert-heading">Storage Configuration Required</h5>
                            <span className="mb-0">You will not be able to provision virtual machines until a storage pool is configured. </span>
                        </div>
                    </div>
                </Alert>
            ):(null)}
        </React.Fragment>
    )
};

export default HypervisorStorageWarning;