import React, { useEffect, useMemo } from 'react';
import { Alert, Card, CardBody, Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import Loader from 'Components/Gallium/Loader';

import StringPretty from 'Components/Gallium/TextHelpers';
import { useGetTemplate } from 'GalliumAPIHooks/Templates/TemplateHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { TemplateSource, TemplateState } from 'generated';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import StatusBadge from 'Components/Gallium/StatusBadge';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import DeleteTemplateButton from './DeleteTemplateButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import RenderFinalisedTemplateDiskComponent from './Disk';

const LabelValuePair: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
  <>
    <label>{label}</label>
    <p>{value}</p>
  </>
);

const TemplateView = () => {
    const {t} = useTranslation();
    // Page Setup
    const crumbs = useMemo(() => [
        { name: t("mainNav.inventory") },
        { name: t("templates.title"), link: "/inventory/templates" },
        { name: t("templates.viewTitle") },
    ], [t]);

    useEffect(() => {
        document.title = `${t("templates.viewTitle")} | Gallium`;
    }, [t]);

    // Data
    const {templateSlug} = useParams();
    const {data: template, isLoading, error} = useGetTemplate(templateSlug);
    if (isLoading) return (<Loader />)
    if (error) return ((<ErrorNotice />))
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title={template.name} crumbs={crumbs}>
                    {template?.templateSource !== TemplateSource.GALLIUM && (
                        <DeleteTemplateButton template={template} />
                    )}
                </GalliumBreadcrumb>
                {template?.state === TemplateState.DRAFT && (
                    <Alert color="info">
                        <strong>{t("templates.draftTemplate")}</strong>
                        <p>{t("templates.draftMessage")}</p>
                        <Link to={`/inventory/templates/${templateSlug}/upload`}>{t("templates.continue")}</Link>
                    </Alert>
                )}
                <Card>
                    <CardBody className="pb-0">
                        <GalliumFormHeader title={t("templates.overview")} icon="info">
                        
                        <LabelValuePair label={t("templates.nameLabel")} value={template?.name} />
                        <LabelValuePair
                            label={t("templates.descriptionLabel")}
                            value={template?.description || t("templates.noDescription")}
                        />
                        <LabelValuePair
                            label={t("templates.sourceLabel")}
                            value={t(`TemplateSource.${template?.templateSource}`)}
                        />
                        <LabelValuePair
                            label={t("templates.stateLabel")}
                            value={<StatusBadge status={template?.state} />}
                        />
                        </GalliumFormHeader>

                        <GalliumFormHeader title={t("templates.settings")} icon="settings">
                            <LabelValuePair label={t("templates.osTypeLabel")} value={<StringPretty string={template?.osType} />} />
                            <LabelValuePair label={t("templates.minMemoryLabel")} value={formatDiskSize(template?.minMemoryMb)} />
                            <LabelValuePair
                                label={t("templates.cloudInitEnabledLabel")}
                                value={<StatusBadge status={template?.supportsCloudInit} />}
                            />
                        </GalliumFormHeader>

                        <GalliumFormHeader title={t("templates.disks")} icon="upload">
                        {template.disks.length > 0 ? 
                            (template.disks.map((disk, index) =>(
                                <RenderFinalisedTemplateDiskComponent disk={disk} key={index} />
                            ))
                            ):(
                                <p className="text-muted">{t("templates.noDisks")}</p> 
                            )}
                        </GalliumFormHeader>
                    </CardBody>
                </Card>
                <Link to="/inventory/templates" className='ps-0 mb-3 btn btn-link text-decoration-none'>
                    <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-left"]} className='fa-fw me-2'/>
                    {t("templates.backToTemplates")}
                </Link>    
            </Container>
        </div>
        )
};

export default TemplateView;
