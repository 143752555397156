import StatusBadge from "Components/Gallium/StatusBadge";
import { TemplateOsType } from "generated";
import React from "react";
import { Link } from "react-router-dom";

const Name = (cell) => {
    return (
        <React.Fragment>
            <Link to={cell.row.original.slug} className="fw-medium link-primary">{cell.getValue()}</Link>
            <p className="text-muted text-truncate mb-0">{cell.row.original.description}</p>
        </React.Fragment>
    );
};

const OperatingSystem = (cell) => {
    return (
        <React.Fragment>
            {
                (() => {
                    switch(cell.getValue()) {
                    case TemplateOsType.LINUX:
                        return "Linux";
                    case TemplateOsType.WINDOWS:
                        return "Windows";
                    case TemplateOsType.OTHER:
                        return "Other";
                    default:
                        return null;
                    }
                })()
            }
        </React.Fragment>
    );
};

const Status = (cell) => {
    return (
        <StatusBadge status={cell.getValue()}/>
    );
};

export { Name, Status, OperatingSystem };