import React from 'react';
import { Button, Container } from 'reactstrap';

import AllHypervisors from './AllHypervisors';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { Link } from 'react-router-dom';


const HypervisorList = () => {
    const crumbs = [
        {
            name: "Hypervisors",
            link: "/hypervisors"
        },
        {
            name: "All Hypervisors",
        }
    ]
    document.title = "All Hypervisors | Gallium";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="All Hypervisors" crumbs={crumbs}>
                        <Link to="/hypervisors/add">
                            <Button color="soft-primary" className="add-btn me-1"><i className="ri-add-line align-bottom me-1"></i> Add New Hypervisor</Button>
                        </Link>
                    </GalliumBreadcrumb>

                    <AllHypervisors />
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HypervisorList;