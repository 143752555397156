import React from 'react';

import { Row, Label, Card, CardBody, Col, Button } from 'reactstrap';

import { Field, FieldArray, useFormikContext } from "formik";
import { GalliumArrayInput } from 'Components/Gallium/GalliumForms'; 

const AlertProfilesEmailComponent = () => {
    const formik = useFormikContext();

    return (    
        <React.Fragment>
            <Label htmlFor="roleField">Additional Email Destinations</Label>
            <Card className='mb-2 pt-3'>
                <FieldArray name="emails" render={arrayHelpers => (
                    <React.Fragment>
                        {formik.values["emails"].map((item, key) =>(
                        
                            <CardBody key={key} className="pb-1 pt-1">
                                <div className="d-flex align-items-center">

                                    <div className="flex-grow-1 overflow-hidden pe-2">
                                    
                                        <Row>
                                            <Col sm={12} className="pe-1">
                                                <Field
                                                    id={`emails[${key}]`}
                                                    name={`emails[${key}]`}
                                                    type="text"
                                                    className="mb-1 form-control"
                                                    placeholder="Email Address"
                                                    component={GalliumArrayInput}
                                                />

                                            </Col>
                                        </Row>  
                                    </div>

                                    <div className="flex-shrink-0 ms-2">
                                        <div className="d-flex gap-1 mb-1 ps-1">
                                            <Button color="light" className="btn btn-icon text-muted btn-sm fs-19"
                                                onClick={() => arrayHelpers.remove(key)}
                                            >
                                                <i className="lab las la-trash-alt"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        ))}
                        <CardBody className="pt-0">
                            <Button color="soft-primary" className="mb-0 w-100" onClick={() => arrayHelpers.push(null)}>Add Email Destination</Button>
                        </CardBody>
                    </React.Fragment>
                )} />
            </Card>
        </React.Fragment>
    );
};

export default AlertProfilesEmailComponent;