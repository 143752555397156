import FtmProvider from "GalliumFileTransferManager";
import { BeginRtcResponse, RtcService } from "generated";
import { useEffect, useState } from "react";

export const useFileTransferManager = () => {
    const [activeTransfers, setActiveTransfers] = useState([]);
    const [ftm, setFtm] = useState(null);
    const [ftmReady, setFtmReady] = useState(false);
    const [ftmError, setFtmError] = useState(null);

    useEffect(() => {
        const ftmInstance = FtmProvider.getInstance();
        if (!FtmProvider.getHostMessageFn()) {
            FtmProvider.setHostMessageFn(async (hostSlug: string, message: string) => {
                try {
                    const response: BeginRtcResponse = await RtcService.beginRtcSession({ hostSlug, rtcMessage: message });
                    return response.rtcMessage;
                } catch (error) {
                    console.error('Error starting RTC session:', error);
                    throw error;
                }
            });
        }

        
        setFtm(ftmInstance);
        setActiveTransfers(ftmInstance.allTransferTasks());
        setFtmReady(true);

        const updateActiveTransfers = () => {
            setActiveTransfers(ftmInstance.allTransferTasks());
        };

        ftmInstance.addListener(updateActiveTransfers);

        return () => {
            ftmInstance.removeListener(updateActiveTransfers);
        };
    }, []);

    const getActiveTransferTask = (fileId: string) => {
        return activeTransfers.find(
            task => task.file?.id === fileId && !task.completed && !task.failed
        );
    };

    const connectToHost = (hostSlug: string) => {
        if (ftmReady && ftm && !ftm.hostAvailable(hostSlug)) {
            ftm.connectToHost(hostSlug);
        } else {
            console.error('FTM instance is not initialized');
        }
    };

    const getHostFileList = (hostSlug: string) => {
        if (ftmReady && ftm) {
            return ftm.getHostFileList(hostSlug);
        } else {
            console.error('FTM instance is not initialized');
            return [];
        }
    };

    const beginDownload = (hostSlug: string, diskSlug: string) => {
        if (ftmReady && ftm) {
            ftm.beginDownload(hostSlug, diskSlug);
        } else {
            console.error('FTM instance is not initialized');
        }
    };

    const beginUpload = (hostSlug: string) => {
        if (ftmReady && ftm) {
            ftm.beginUpload(hostSlug);
        } else {
            console.error('FTM instance is not initialized');
        }
    };

    return {ftm, ftmReady, ftmError, activeTransfers, getActiveTransferTask, connectToHost, getHostFileList, beginDownload, beginUpload}

}
