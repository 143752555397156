import React from 'react';
import { useFormikContext } from 'formik';
import { Row, Col, Label } from 'reactstrap'; // Assuming you use Reactstrap for layout/styling
import { useTranslation } from 'react-i18next';

interface GalliumCpuInputProps {
    name: string;
    availableCpus: number; // Maximum number of CPUs the hypervisor allows
    isDisabled?: boolean;  // Option to disable CPU selection
}

const GalliumCpuInput: React.FC<GalliumCpuInputProps> = ({ name, availableCpus, isDisabled = false }) => {
    const {t} = useTranslation()
    const { setFieldValue, values } = useFormikContext();

    // Generate CPU options dynamically: 1 vCPU and all even numbers up to availableCpus
    const generateCpuOptions = (maxCpus: number) => {
        const options = [1];
        for (let i = 2; i <= maxCpus; i += 2) {
            options.push(i);
        }
        return options;
    };

    const cpuOptions = generateCpuOptions(availableCpus);

    const handleCpuChange = (cpuValue: number) => {
        setFieldValue(name, cpuValue); // Manually set integer value in Formik
    };

    return (
        <div className="mt-0 mb-3">
            <Label className="form-label" htmlFor={name}>{t("virtualMachine.hardwareCPU")}</Label>
            <Row className="gy-3">
                {cpuOptions.map((cpuValue) => (
                    <Col lg={4} md={6} sm={12} key={cpuValue}>
                        <div className="form-check card-radio">
                            <input
                                id={`cpus${cpuValue}`}
                                name={name}
                                type="radio"
                                className="form-check-input"
                                checked={values[name] === cpuValue}
                                onChange={() => handleCpuChange(cpuValue)}
                                disabled={isDisabled}
                            />
                            <Label
                                className="form-check-label"
                                htmlFor={`cpus${cpuValue}`}
                            >
                                <span className="fs-14 mb-1 text-wrap d-block">
                                    {cpuValue}x vCPU
                                </span>
                            </Label>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default GalliumCpuInput;
