import { useChangeVmPowerState } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { ApiVirtualMachine, ApiVmStatus, GalliumApiSuccessResponse, VirtualMachineDetail, VmPowerStateTransition } from 'generated';
import React, { useEffect, useState } from 'react';

//redux
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

import {GalliumApiErrorResponse} from 'generated'
import { GalliumSpinnerButton } from '../GalliumForms';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { useGetCommandDetails } from 'GalliumAPIHooks/Commands/CommandsHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


interface VMListControlsProps {
    vm: ApiVirtualMachine | VirtualMachineDetail;
}
const VMListControls: React.FC<VMListControlsProps> = ({vm}) => {

    const {trigger} = useChangeVmPowerState(vm.slug);

    const [isUpdating, setIsUpdating] = useState(false)

    const [commandUuid, setCommandUuid] = useState<string | null>(null);
    
    const { data: command } = useGetCommandDetails(commandUuid, 1000);

    const handleVirtualMachinePowerStateFail = (error: GalliumApiErrorResponse) => {
        toast.error(error?.error || "Power State Transition Failed")
        setIsUpdating(false)
    }

    const handleVirtualMachinePowerStateSuccess = (response: GalliumApiSuccessResponse) => {
        setCommandUuid(response?.command.commandUuid);
    }

    const handleVirtualMachinePowerStateRequest = (values: VmPowerStateTransition) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachinePowerStateFail(error)
            },
            onSuccess(response: GalliumApiSuccessResponse) {
                handleVirtualMachinePowerStateSuccess(response)
            }
        }
        setIsUpdating(true)
        trigger(values, options);
    }

    useEffect(() => {
        if (command?.summary.status === "COMPLETE") {
            mutate('vm')
            mutate(`hosts/${command?.summary.hostSlug}`)
            mutate(`vm/${command?.summary.vmSlug}`)
            setTimeout(() => {
                setCommandUuid(null)
                setIsUpdating(false)    
            }, 1000);
        }
    }, [command]);

    return (
        <React.Fragment>
            {!isUpdating ? (
                <ButtonGroup>
                    {vm.availableActions.changePowerState ? (
                        <React.Fragment>
                            {vm.status === ApiVmStatus.RUNNING ? (
                                <React.Fragment>
                                    <Button id="restartBtn" color="success" aria-label="Restart" title="Restart" className="btn btn-icon btn-soft-success" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["arrows-rotate"]} className='fa-fw'/> 
                                    </Button>
                                    <Button color="danger" aria-label="Shutdown" title="Shutdown" className="btn btn-icon btn-soft-danger" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["stop"]} className='fa-fw'/> 
                                    </Button>
                                    <UncontrolledButtonDropdown id="btnGroupVerticalDrop1">
                                        <DropdownToggle color="light" className='btn-icon dropdown-toggle'>
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["chevron-down"]} className='fa-fw'/> 
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.HALT)}>
                                                <FontAwesomeIcon icon={byPrefixAndName.fal["triangle-exclamation"]} className='me-1 fa-fw'/>  
                                                Stop 
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.RESET)}>
                                                <FontAwesomeIcon icon={byPrefixAndName.fal["rotate-exclamation"]} className='me-1 fa-fw'/> 
                                                Reset 
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </React.Fragment>
                            ) : vm.status === ApiVmStatus.STOPPED ? (
                                <Button color="soft-success" className="btn-icon" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.START)}>
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["play"]} className=''/> 
                                </Button>
                            ) : (
                                null
                            )}
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <Button className="btn btn-icon btn-outline-secondary" aria-label="Hypervisor Offline" disabled>
                                <FontAwesomeIcon icon={byPrefixAndName.fak["light-server-circle-arrow-down"]} className='fa-fw'/>
                            </Button>
                        </React.Fragment>
                    )}
                </ButtonGroup>
            ):(
                <GalliumSpinnerButton color='soft-primary'/>
            )}
        </React.Fragment>
    )
}

export default VMListControls;