import React from 'react';
import { Label } from 'reactstrap';
import { GalliumArrayCheckbox } from 'Components/Gallium/GalliumArrayCheckbox';
import { AlertNotifyEventType } from 'generated';
import { map } from "lodash";
import StringPretty from 'Components/Gallium/TextHelpers';

const AlertProfilesNotificationEventsComponent = () => {

    return (    
        <React.Fragment>
            <div className="mb-3">
                <Label>Events to Include</Label>
                {map(Object.keys(AlertNotifyEventType), key => (
                    <GalliumArrayCheckbox
                        name="notifyEvent"
                        value={key}
                        label={StringPretty({string: key})}
                        key={key}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};

export default AlertProfilesNotificationEventsComponent;