import React, { useState } from "react";
import { Button, Label,  Modal,  ModalBody, ModalHeader } from "reactstrap";
import { Col } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { GalliumApiErrorResponse, VirtualMachineDetail, BackupProfile } from "generated";
import { useTranslation } from "react-i18next";
import Loader from "Components/Gallium/Loader";
import ErrorNotice from "Components/Gallium/ErrorNotice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import { useAddVirtualMachineToBackupProfile, useListBackupProfiles } from "GalliumAPIHooks/Backup/BackupHooks";

interface AddBackupProfileModalFormProps {
    closeModal: () => void,
    candidateBackupProfiles: Array<BackupProfile>,
    virtualMachine: VirtualMachineDetail
}

const AddBackupProfileModalForm:React.FC<AddBackupProfileModalFormProps> =({candidateBackupProfiles, virtualMachine, closeModal}) => {
    const {t} = useTranslation()   
    const { trigger, isMutating } = useAddVirtualMachineToBackupProfile(virtualMachine.slug);


    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleAddVMBackupProfileFail = (error: GalliumApiErrorResponse) => {
        addAdaptorFormik.setErrors(error.errors || {});
        setErrorObject(error || {});
    }

    const handleAddVMBackupProfileSuccess = () => {
        addAdaptorFormik.resetForm();
        closeModal()
    }

    const handleAddVMBackupProfileRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddVMBackupProfileFail(error)
            },
            onSuccess() {
                handleAddVMBackupProfileSuccess()
            }
        }
        setErrorObject(null)
        trigger(values, options);
    }

    const addAdaptorFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            vmSlug: virtualMachine.slug,
            profileSlug: null
        },
        validationSchema: Yup.object().shape({
            profileSlug: Yup.string().required("Select a Backup Profile")
        }),
        onSubmit: (values) => {
            handleAddVMBackupProfileRequest(values)
        }
    });


    return (
        <React.Fragment>
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={addAdaptorFormik}>

                    <Label>{t('virtualMachine.backup.addProfileField')}</Label>
                    <Field
                        id="addInterfaceField"
                        name="profileSlug"
                        as="select"
                        className="form-select mb-3"
                        
                    >
                        <option>{t("common.pleaseSelect")}</option>
                        {candidateBackupProfiles?.map((profile, key) => (
                            <option value={profile.slug} key={key}>{profile.name}</option>
                        ))
                        }
                    </Field>

                

                    <Col lg={12} className="text-end mt-3">
                        <GalliumSubmitButton formik={addAdaptorFormik} color="success" spinner={isMutating}>
                            {t('virtualMachine.backup.AddBackupProfileButton')}
                        </GalliumSubmitButton>
                    </Col>

                </FormikProvider>
            </ModalBody>
        </React.Fragment>
    )
}

interface AddBackupProfileModalProps {
    showModal: boolean,
    closeModal: () => void,
    virtualMachine: VirtualMachineDetail
}

const AddBackupProfileModal: React.FC<AddBackupProfileModalProps> = ({ showModal, closeModal, virtualMachine }) => {
    const {t} = useTranslation()
    const {data: backupProfiles, error, isLoading} = useListBackupProfiles()

    const candidateBackupProfiles = backupProfiles?.profiles?.filter(profile => profile.targetHost.slug != virtualMachine.host.slug);

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('virtualMachine.backup.AddBackupProfileButton')}
            </ModalHeader>      
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                
                candidateBackupProfiles.length !== 0 ? (
                    
                    <AddBackupProfileModalForm candidateBackupProfiles={candidateBackupProfiles} virtualMachine={virtualMachine} closeModal={closeModal}/>

                ):(
                    <ModalBody className="p-5 m-4 mt-3 text-center">
                        <Button disabled outline color="">
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-boxes-stacked-slash"]} className='me-2'/>
                            {t('virtualMachine.backup.noProfileAvailable')}
                        </Button>
                    </ModalBody>
                )
            )}
        </Modal>
    );
};

export default AddBackupProfileModal