

import React, { ReactNode } from 'react';
import { TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

type GalliumTabPaneProps = {
  tabId: string;
  children: ReactNode;
  tabToggle?: (id: string | null) => void;
  icon?: string;
  heading:string
};

const GalliumTabPane: React.FC<GalliumTabPaneProps> = ({ tabId, children, tabToggle, icon, heading }) => {

    return (
        <React.Fragment>  
            <TabPane tabId={tabId} className="third-column-content">
                <div className="p-2 border-bottom third-column-header">
                    <div className="d-flex">
                        <div className='flex-grow-1'>
                            <h1 className="mt-2 mb-2 fs-4 fw-light"> 
                                {icon ? (
                                    <FontAwesomeIcon icon={byPrefixAndName.fal[icon]} className='me-2 ms-1'/>
                                ):(null)}
                                {heading}
                            </h1>
                        </div>
                        <div className="flex-shrink-0 text-end d-md-none">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["square-chevron-down"]} className='me-3 fs-3 text-muted mt-2 mb-2' onClick={()=>tabToggle(null)}/>
                        </div>
                    </div>
                </div>
                <div className='p-2 pe-3'>
                    {children}
                </div>
            </TabPane>    
        </React.Fragment>
    )
};

export default GalliumTabPane;