import React from 'react';

import { Card, CardBody, CardHeader, Container, ListGroup, ListGroupItem } from 'reactstrap';

import { useGetOrgInfo } from 'GalliumAPIHooks/Org/OrgHooks';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import SupportAccessForm from './form';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import Loader from 'Components/Gallium/Loader';
import { useGetFlags } from 'GalliumAPIHooks/User/UserHooks';
import SetFlagsForm from './form';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';

const ManageFlags = () => {
    const {t} = useTranslation()
    const {data, error, isLoading} = useGetFlags()
  

    document.title = "Manage System Flags" + " | Gallium";

    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: "Manage System Flags",
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="Manage System Flags" crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <Card>
                            <CardBody>
                                {data.orgFlags.length > 0 ? (
                                <GalliumFormHeader icon="briefcase" title="Organization Flags">
                                <ListGroup className='mb-3'>
                                    {data.orgFlags.map((flag,index)=>(
                                        <ListGroupItem key={`user_${index}`}>{flag}</ListGroupItem>
                                    ))}
                                </ListGroup>
                                </GalliumFormHeader>
                                ):(null)}
                                {data.userFlags.length > 0 ? (
                                <GalliumFormHeader icon="user" title="User Flags">
                                <ListGroup>
                                    {data.userFlags.map((flag,index)=>(
                                        <ListGroupItem key={`user_${index}`}>{flag}</ListGroupItem>
                                    ))}
                                </ListGroup>
                                </GalliumFormHeader>
                                ):(null)}
                            </CardBody>
                        </Card>
                    )}
                    <SetFlagsForm />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageFlags;