import React, { useState } from 'react';

import { Label, Card, CardBody, Row } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { FlagCreateRequest, FlagType, GalliumApiErrorResponse, OrgInfoResponse } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import { LocalDataTimeString } from 'Components/Gallium/GalliumTimeHelpers';
import { useSetFlags } from 'GalliumAPIHooks/User/UserHooks';
import SingleSelectCard from 'Components/Gallium/Forms/SingleSelectCard';


const SetFlagsForm = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useSetFlags()

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleSetFlagsFail = (error: GalliumApiErrorResponse) => {
        setFlagFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleSetFlagsSuccess = () => {
        toast.success("Updated")
    }

    const handleSetFlagsRequest = (values: FlagCreateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleSetFlagsFail(error)
            },
            onSuccess() {
                handleSetFlagsSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const setFlagFormik = useFormik({
        initialValues: {
            flag: "",
            scope: FlagType.ORG
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            handleSetFlagsRequest(values)
        },
    });



    return (    
        <FormikProvider value={setFlagFormik}>
            <Card>
                <CardBody>
                    <ErrorAlert errorObj={errorObject} />
                    <GalliumFormHeader icon="flag" title="Set Flags">
                        <Field
                            id="flag" 
                            name="flag" 
                            type="text" 
                            label="Flag String"
                            component={GalliumInput}  
                        />
                        <div className="mt-3 mb-3">
                            <h5 className="fs-14 mb-3">Scope</h5>
                            <Row className="g-4">
                                <SingleSelectCard
                                    id="orgCard"
                                    name="scope"
                                    value={FlagType.ORG}
                                    labelTitle="Organization"
                                    labelText="Applies to the current organization only"
                                />
                                <SingleSelectCard
                                    id="userCard"
                                    name="scope"
                                    value={FlagType.USER}
                                    labelTitle="User"
                                    labelText="Applies to the current user across all organizations"
                                />
                            </Row>
                        </div>
                    </GalliumFormHeader>
                    <GalliumSubmitButton formik={setFlagFormik} spinner={isMutating} color="success" className="float-end">
                        {t("common.update")}
                    </GalliumSubmitButton>

                </CardBody>
            </Card>

        </FormikProvider>
    );
};

export default SetFlagsForm;