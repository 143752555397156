// @ts-nocheck
import React, { ReactNode } from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { useProfile } from "../Components/Hooks/UserHooks";

// Type for AuthProtected props
interface AuthProtectedProps {
    children: ReactNode;
    location?: { pathname: string }; // Adding location as optional prop
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children, location }) => {
    const { userProfile, loading, token } = useProfile();

    if (!userProfile && loading && !token) {
        return (
            <Navigate
                to={{ pathname: "/login", state: { from: location?.pathname || "/" } }}
            />
        );
    }

    return <>{children}</>;
};

// Type for AccessRoute props
interface AccessRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const AccessRoute: React.FC<AccessRouteProps> = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            element={<Component {...rest} />} // Updated for React Router v6
        />
    );
};

export { AuthProtected, AccessRoute };
