import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Label, Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, InviteUserRequest, OrgUserRole } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useInviteUserToOrg } from '../../../../GalliumAPIHooks/Org/OrgHooks';
import StringTidy from 'Components/Gallium/StringTidy';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';

const AddUser = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useInviteUserToOrg()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleAddUserFail = (error: GalliumApiErrorResponse) => {
        addUserFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddUserSuccess = () => {
        toast.success("User Invitation Sent")
        navigate("/settings/users");
    }

    const handleAddUserRequest = (values: InviteUserRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddUserFail(error)
            },
            onSuccess() {
                handleAddUserSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addUserFormik = useFormik({
        initialValues: {
            email: '',
            role: OrgUserRole.USER
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Please Enter Email")
                .email("Invalid email address")
        }),
        onSubmit: (values) => {
            handleAddUserRequest(values)
        },
    });

    const userRoles = [
        OrgUserRole.OWNER,OrgUserRole.USER
    ]


    document.title = "Add Users | Gallium";

    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("users.title"),
            link: "/settings/users"
        },
        {
            name: t("users.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("users.add")} crumbs={crumbs} />
                    <FormikProvider value={addUserFormik}>
                        <Card>
                            <CardBody>
                                <ErrorAlert errorObj={errorObject} />
                                <GalliumFormHeader icon="info" title="User Settings">
                                    <Col md={6} className="mb-3">
                                        <Field
                                            name="email"
                                            id="email"
                                            className="form-control"
                                            placeholder="Please enter the user's email address"
                                            type="email"
                                            label="Email"
                                            component={GalliumInput}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="roleField">Role</Label>
                                        <Field
                                            id="roleField"
                                            name="role"
                                            as="select"
                                            className="form-select mb-3"
                                        >
                                            {userRoles.map((item, index) =>(
                                                <option value={item} key={index}>{StringTidy(item)}</option>
                                            ))}
                                        </Field>
                                    </Col>
                                </GalliumFormHeader>
                                <GalliumSubmitButton formik={addUserFormik} spinner={isMutating} color="success" className="float-end">
                                    Add
                                </GalliumSubmitButton>
                            </CardBody>
                        </Card>

                        <Col md={6} className="ps-0">
                            <Link to="/settings/users">
                                <button className="btn btn-link text-decoration-none btn-label">
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                    Back to Users
                                </button>
                            </Link> 
                        </Col>
                            

                    </FormikProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddUser;