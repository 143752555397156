import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
} from "reactstrap";

import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useGetTemplate, useUpdateTemplate } from 'GalliumAPIHooks/Templates/TemplateHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import AddBlankDiskToTemplateButton from './BlankDiskButton';
import AddUploadDiskToTemplateButton from './AddUploadDiskToTemplateButton';
import DiskComponent from './RenderTemplateDiskComponent'
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import CreateTemplateFrame from '../Shared/CreateTemplateFrame';
import { GalliumApiErrorResponse, Template, TemplateDiskType, TemplateState } from 'generated';
import { toast } from 'react-toastify';
import { GalliumSpinnerButton } from 'Components/Gallium/GalliumForms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const UploadTemplateDisks = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    // Page Setup
    const crumbs = useMemo(() => [
        { name: t("mainNav.inventory") },
        { name: t("templates.title"), link: "/inventory/templates" },
        { name: t("templates.createTemplate") },
    ], [t]);

    useEffect(() => {
        document.title = `${t("templates.createTemplate")} | Gallium`;
    }, [t]);

    const {templateSlug} = useParams()
    const { data: template, isLoading, error } = useGetTemplate(templateSlug);

    // State to store only disk slugs
    const [diskSlugs, setDiskSlugs] = useState<string[]>([]);

    // Map to hold stable component instances by slug
    const diskComponentsMap = useRef(new Map<string, JSX.Element>());

    // Effect to update disk slugs and component map when template.disks changes
    useEffect(() => {
        if (template?.disks) {
            const newSlugs = template.disks.map((disk) => disk.slug);
            
            // Update diskSlugs state only if it has actually changed
            if (!areArraysEqual(diskSlugs, newSlugs)) {
                setDiskSlugs(newSlugs);

                // Update diskComponentsMap to include only new slugs
                newSlugs.forEach((slug) => {
                    if (!diskComponentsMap.current.has(slug)) {
                        diskComponentsMap.current.set(slug, <DiskComponent key={slug} templateSlug={templateSlug} diskSlug={slug} />);
                    }
                });

                // Remove components for slugs no longer in the list
                diskComponentsMap.current.forEach((_, slug) => {
                    if (!newSlugs.includes(slug)) {
                        diskComponentsMap.current.delete(slug);
                    }
                });
            }
        }
    }, [template?.disks]);

    // Helper function to compare arrays for equality
    const areArraysEqual = (a: string[], b: string[]) => {
        return a.length === b.length && a.every((value, index) => value === b[index]);
    };

    // Finalise logic
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)
    const {trigger, isMutating} = useUpdateTemplate(templateSlug)
    const handleFinaliseTemplateFail = useCallback((error: GalliumApiErrorResponse) => {
        toast.error(t("templates.uploadDiskForm.toasts.TemplateFinaliseFailed"));
        setErrorObject(error)
    }, [t]);

    const handleFinaliseTemplateSuccess = useCallback((result: Template) => {
        toast.success(t("templates.uploadDiskForm.toasts.TemplateFinaliseSuccess"));
        navigate(`/inventory/templates/${result.slug}`)
    }, [t]);

    const handleFinaliseTemplateRequest = useCallback(() => {
        trigger({state: TemplateState.READY}, {
            onError: handleFinaliseTemplateFail,
            onSuccess: handleFinaliseTemplateSuccess
        });
    }, [trigger, handleFinaliseTemplateFail, handleFinaliseTemplateSuccess]);


    if (isLoading) return (<Loader />)
    if (error) return ((<ErrorNotice />))
    return (    
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title={t("templates.createTemplate")} crumbs={crumbs} /> 
                <CreateTemplateFrame activeTab={2}>
                    <div>
                        <h5 className="mb-1">{t("templates.uploadDiskForm.titles.configureDisks")}</h5>
                        <p className="text-muted mb-4">
                                {t("templates.uploadDiskForm.formDescription")}
                        </p>
                        <ErrorAlert errorObj={errorObject} />
                    </div>
                    <Row>
                        <Col>
                            {diskSlugs.map((slug) => diskComponentsMap.current.get(slug))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            
                            <Card className="border border-dashed">
                                <CardBody>
                                    <div className='hstack gap-3'>
                                        <AddBlankDiskToTemplateButton template={template} />
                                        <AddUploadDiskToTemplateButton template={template} diskType={TemplateDiskType.DISK}/>
                                        <AddUploadDiskToTemplateButton template={template} diskType={TemplateDiskType.CDROM}/>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                    {isMutating ? (
                        <GalliumSpinnerButton color='success' className='float-end'/>
                    ):(
                        <Button
                            type="button"
                            className="btn btn-success btn-label right float-end"
                            onClick={() => {
                                handleFinaliseTemplateRequest()
                            }}
                        >
                            <i className="label-icon">
                                <FontAwesomeIcon icon={byPrefixAndName.far["arrow-right"]}/>
                            </i>
                            {t("templates.uploadDiskForm.buttons.finaliseTemplate")}
                        </Button>
                    )}
                </CreateTemplateFrame>
            </Container>
        </div>
    );
};

export default UploadTemplateDisks