import React, { useState, useEffect } from "react";

import { Label,  Modal,  ModalBody, ModalHeader } from "reactstrap";

import { Col, Row } from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { useCreateHostNetwork } from "GalliumAPIHooks/HostNetwork/HostNetworkHooks";
import { HostNetworkCreateRequest, GalliumApiErrorResponse, HostNetworkCreateType, HostDetail } from "generated";


interface NetworkAddModalProps {
    showModal: boolean,
    closeModal: () => void,
    adaptorSlug: string,
    hypervisor: HostDetail
}

const NetworkAddModal: React.FC<NetworkAddModalProps> = ({ showModal, closeModal, hypervisor, adaptorSlug }) => {
    const { trigger, isMutating } = useCreateHostNetwork(hypervisor.slug);

    const [errorObject, setErrorObject] = useState(null);

    const handleAddNetworkChangeFail = (error: GalliumApiErrorResponse) => {
        networkAddFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddNetworkChangeSuccess = () => {
        networkAddFormik.resetForm()
        closeModal()
    }

    const handleAddNetworkRequest = (values: HostNetworkCreateRequest) => {
        const options = {
            onError(error: object) {
                handleAddNetworkChangeFail(error)
            },
            onSuccess() {
                handleAddNetworkChangeSuccess()
            }
        }
        setErrorObject(null)
        trigger(values, options);
    }

    const networkAddFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            networkType: HostNetworkCreateType.VLAN,
            vlanId: undefined,
            parentSlug: adaptorSlug
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required('Name is required'),
            vlanId: Yup.number()
                .required('VLAN ID is required') // Make it required; adjust if it can be optional
                .min(2, 'VLAN ID must be between 2 and 4094')
                .max(4094, 'VLAN ID must be between 2 and 4094')
                .integer('VLAN ID must be an number'), // Ensuring it's an integer
        }),
        onSubmit: (values) => {
            handleAddNetworkRequest(values);
        }
    });

    useEffect(() => {
        networkAddFormik.resetForm()
        setErrorObject(null)
    }, [showModal]); 

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
            Add Network
            </ModalHeader>      
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={networkAddFormik}>
                    <Row className="g-2 pb-3">
                        <Label>Select Type</Label>
                        <Col md={12}>
                            <div className="form-check card-radio">
                                <Field
                                    id="VLAN"
                                    name="networkType"
                                    type="radio"
                                    value={HostNetworkCreateType.VLAN}
                                    className="form-check-input"
                                />
                                <Label
                                    className="form-check-label"
                                    htmlFor="VLAN"
                                    onClick={()=> networkAddFormik.setFieldValue("otp", undefined)}
                                >
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 text-center">
                                            <i className="mdi mdi-tag-outline mdi-24px me-3"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                            VLAN
                                            </span>
                                            <p className="small text-muted m-0 ">Bridged network with a 802.1q tag</p>
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </Col>
                    </Row>
                
                    <Row className="g-2">

                        <Col lg={12}>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                label="Name"
                                placeholder="Example: Data VLAN"
                                component={GalliumInput}
                            />
                        </Col>
                        <Col lg={6}>
                            <Field
                                type="number"
                                id="vlanId"
                                name="vlanId"
                                label="VLAN ID"
                                placeholder="2-4094"
                                component={GalliumInput}
                            />
                        </Col>
                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={networkAddFormik} color="success" spinner={isMutating}>
                            Create
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </FormikProvider>
            </ModalBody>
        </Modal>
    );
};

export default NetworkAddModal