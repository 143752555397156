import { ApiVmStatus } from "generated";

// Define a type for the icon and color
interface StatusIconColor {
  icon: string;
  color: string;
}

// Create a map of status groups to icon and color
const statusMap: Record<string, StatusIconColor> = {
    running: { icon: 'sharp-light-cube-circle-play', color: 'success' },
    stopped: { icon: 'sharp-light-cube-circle-arrow-down', color: 'secondary' },
    disconnected: { icon: 'sharp-light-cube-circle-xmark', color: 'secondary' },
    paused: { icon: 'sharp-light-cube-circle-pause', color: 'warning' },
    unknown: { icon: 'sharp-light-cube-circle-question', color: 'secondary' },
    error: { icon: 'sharp-light-cube-circle-exclamation', color: 'danger' },
    deleted: {icon: 'sharp-light-cube-slash', color: 'danger'}
};

// Helper function to get icon and color based on status
const getVirtualMachineStatusIcon = (status: ApiVmStatus): StatusIconColor => {
    if (
        [ApiVmStatus.RUNNING, ApiVmStatus.RESUMING, ApiVmStatus.STARTING].includes(status)
    ) {
        return statusMap.running;
    } else if (
        [ApiVmStatus.STOPPED, ApiVmStatus.STOPPING].includes(status)
    ) {
        return statusMap.stopped;
    } else if (
        [ApiVmStatus.PAUSED, ApiVmStatus.PAUSING].includes(status)
    ) {
        return statusMap.paused;
    } else if (
        [ApiVmStatus.UNKNOWN, ].includes(status)
    ) {
        return statusMap.unknown;
    } else if (
        [ApiVmStatus.HOST_OFFLINE, ].includes(status)
    ) {
        return statusMap.disconnected;
    } else if (
        [ApiVmStatus.CREATE_FAILED, ApiVmStatus.DELETE_REQUESTED, ApiVmStatus.MISSING, ApiVmStatus.HOST_ARCHIVED].includes(status)
    ) {
        return statusMap.error;
    } else if (
        [ApiVmStatus.DELETED].includes(status)
    ) {
        return statusMap.deleted;
    } else if (
        [ApiVmStatus.PENDING, ApiVmStatus.SUBMITTED].includes(status)
    ) {
        return statusMap.pending;
    } 
    // Default case
    return statusMap.unknown;
};

export default getVirtualMachineStatusIcon;