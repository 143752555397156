import React from 'react';


import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import EditBasics from './Basics';

type HypervisorSettingsProps = {
  hypervisor: HostDetail;
};

const HypervisorSettings: React.FC<HypervisorSettingsProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (

        <GalliumPageColumn>
            
            <EditBasics hypervisorDetail={hypervisor} />

        </GalliumPageColumn>
    )
};

export default HypervisorSettings;