import React, { useState } from 'react';
import {
    CardBody,
    Col,
    Button,
    Row,
} from 'reactstrap';
import { PoolDeviceDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import DiskInfoModal from './DiskInfoModal';

type HypervisorStoragePoolDiskProps = {
  disk: PoolDeviceDetail;
  index: number
};

const HypervisorStoragePoolDisk: React.FC<HypervisorStoragePoolDiskProps> = ({ disk, index }) => {
    const { t } = useTranslation();
    const [showDiskInfoModal, setShowDiskInfoModal] = useState<boolean>(false);

    const diskStateMap = {
        "HEALTHY": {
            color: "success",
            icon: "sharp-light-hard-drive-circle-check"
        },
        "UNHEALTHY": {
            color: "danger",
            icon: "sharp-light-hard-drive-circle-exclamation"
        },
        "OFFLINE": {
            color: "secondary",
            icon: "sharp-light-hard-drive-circle-arrow-down"
        }
    }

    const diskStateIcon = (state: string) => (
        <div className="avatar-sm">
            <div className={`avatar-title bg-${diskStateMap[state].color}-subtle text-${diskStateMap[state].color} fs-4`}>
                <FontAwesomeIcon icon={byPrefixAndName.fak[diskStateMap[state].icon]} className='fa-fw'/>
            </div>
        </div>
    )

    return (
        <React.Fragment>
            <DiskInfoModal
                    showModal={showDiskInfoModal}
                    closeModal={() => {
                        setShowDiskInfoModal(false)
                    }}
                    diskId={disk.id}
                />
            <CardBody key={disk.id} className='border-top p-0'>
                <Row>
                    <div className='col-auto'>
                        {disk.online ? (
                            disk.healthy ? (
                                diskStateIcon("HEALTHY")
                            ):(
                                diskStateIcon("UNHEALTHY")
                            )
                        ) : (
                            diskStateIcon("OFFLINE")
                        )}
                    </div>
                    <div className="col ps-0">
                        <h5 className="fs-14 text-truncate mb-0 mt-2">
                            {`${t("hypervisor.storagePool.disk.physicalDiskTitle")} ${index + 1}`}
                        </h5>
                        <ul className="list-inline text-muted mb-0 fs-12">
                            <li className="list-inline-item">
                                {disk.online ? (
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-up"]} className='me-1'/>
                                        {t("hypervisor.storagePool.disk.online")}
                                    </React.Fragment>
                                ):(
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-down"]} className='me-1'/>
                                        {t("hypervisor.storagePool.disk.offline")}
                                    </React.Fragment>
                                )}
                            </li>
                            {disk.online ? (
                                <li className="list-inline-item">
                                    {disk.online ? (
                                        <React.Fragment>
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["check"]} className='me-1'/>
                                            {t("hypervisor.storagePool.disk.healthy")}
                                        </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["xmark"]} className='me-1'/>
                                            {t("hypervisor.storagePool.disk.unhealthy")}
                                        </React.Fragment>
                                    )}
                                </li>
                            ):(null)}
                            {disk.hasIoErrors ? (
                            <li className="list-inline-item text-danger">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["circle-exclamation"]} className='me-1'/>
                                {t("hypervisor.storagePool.disk.ioError")}
                            </li>
                            ):(null)}
                            {disk.unhealthyReason ? (
                            <li className="list-inline-item text-danger">
                                <FontAwesomeIcon icon={byPrefixAndName.fal["circle-exclamation"]} className='me-1'/>
                                {disk.unhealthyReason}
                            </li>
                            ):(null)}


                        </ul>
                </div>
                    <Col className="text-end mt-2 me-3">

                        <Button color="soft-primary" className='btn-sm btn-block' onClick={() => setShowDiskInfoModal(true)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-magnifying-glass"]} className='me-1'/>
                            {t('hypervisor.storagePool.disk.infoButton')}
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </React.Fragment>
    );
};

export default HypervisorStoragePoolDisk;
