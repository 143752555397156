import React, { useMemo } from 'react';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from "reactstrap"
import { Link, useParams } from 'react-router-dom';
import { Actions } from './BackupSetCols';
import { BackupSet } from 'generated';
import { ColStatusBadge } from 'Components/Gallium/Columns';

interface BackupSetListProps {
    backupSets: BackupSet[];
}

const BackupSetListComponent: React.FC<BackupSetListProps> = ({ backupSets }) => {    
    const profileSlug = useParams().profileSlug
    const columns = useMemo(
        () => [
            {
                header: "Virtual Machine",
                accessorKey: "vmReference",
                enableColumnFilter: false,
                className: "col-8",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to={`/backups/${profileSlug}/${cellProps.row.original.slug}`}>
                                <span className="fw-medium">{cellProps.getValue().name}</span>
                            </Link>
                        </React.Fragment>
                    );
                },   
            },
            {
                header: "State",
                accessorKey: "state",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <ColStatusBadge {...cellProps} />;
                },   
            },
            {
                header: "",
                accessorKey: "slug",
                id: "slug",
                enableColumnFilter: false,
                className: "col-2 text-end",
                cell: (cellProps) => {
                    return <Actions {...cellProps} />;
                },
            },

        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'state',
                desc: true, 
            },
        ],[]
    );


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="BackupList">
                        <CardHeader>
                            <h4 className="card-title mb-0">Backup Sets</h4>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <TableContainer
                                columns={columns}
                                data={(backupSets || [])}
                                isGlobalFilter={true}
                                customPageSize={20}
                                sorting={sorting}
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap mb-0"
                                theadClass="table-light table-nowrap"
                                thClass="table-light text-muted"
                                SearchPlaceholder='Search for a Backup Set'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                
        </React.Fragment>
    );
};

export default BackupSetListComponent;