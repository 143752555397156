import React from 'react';
import { Button, Container } from 'reactstrap';

import TemplatesTable from './TemplatesTable';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TemplatesList = () => {
    const {t} = useTranslation()

    const crumbs = [
        {
            name: "Inventory",
        },
        {
            name: t("templates.title"),
        }
    ]
    document.title = t("templates.list") + " | Gallium";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("templates.list")} crumbs={crumbs}>
                        <Link to="/inventory/templates/create">
                            <Button color="soft-primary" className="add-btn me-1">
                                <i className="ri-add-line align-bottom me-1"></i>
                                {t("templates.create")}
                            </Button>
                        </Link>
                    </GalliumBreadcrumb>

                    <TemplatesTable />
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TemplatesList;