import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormFeedback, Label } from 'reactstrap';

interface GalliumSizeInputProps {
    name: string;
    id?: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    width?: number;
}

const GalliumSizeInput: React.FC<GalliumSizeInputProps> = ({ 
    name, 
    id = "SizeInput", 
    label, 
    width = 3, 
    disabled = false, 
    className = "", 
    ...props 
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const [multiplier, setMultiplier] = useState<number>(1024); // Default to GB
    const [prevMultiplier, setPrevMultiplier] = useState<number>(1024); // Track previous multiplier

    const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const size = parseFloat(e.target.value) || 0;
        setFieldValue(name, size * multiplier);
    };

    const handleMultiplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newMultiplier = parseFloat(e.target.value);
        // Convert the field value based on the ratio of new to previous multiplier
        const adjustedValue = (field.value as number) * (newMultiplier / prevMultiplier);
        setMultiplier(newMultiplier);
        setPrevMultiplier(newMultiplier); // Update the previous multiplier
        setFieldValue(name, adjustedValue);
    }; 

    const containerClass = `col-md-${width} ${className}`.trim();

    return (
        <div className={containerClass}>
            {label && <Label className="form-label" htmlFor={id}>{label}</Label>}
            <div className="input-group mb-2">
                
                <input
                    type="number"
                    className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                    placeholder="Size"
                    id={id}
                    onChange={handleSizeChange}
                    disabled={disabled}
                    value={field.value ? field.value / multiplier : ''}
                    {...props}
                />

                <select
                    className="input-group-text"
                    value={multiplier}
                    onChange={handleMultiplierChange}
                >
                    <option value={1}>MB</option>
                    <option value={1024}>GB</option>
                    <option value={1048576}>TB</option>
                </select>

                {meta.touched && meta.error && (
                    <FormFeedback type="invalid">{meta.error}</FormFeedback>
                )}
            </div>
        </div>
    );
};

export default GalliumSizeInput;
