import React, { useMemo } from "react";
import { BackupTask, BackupTaskState, BackupTaskType } from "generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import { useTranslation } from "react-i18next";

const ColBackupTask = (backupTask: BackupTask) => {
    const {t} = useTranslation()
    const typeIcon = backupTask.ty === BackupTaskType.FULL ? "solid-circle" : "regular-circle-half-stroke"
    const statusValue = backupTask.state;
    const statusIcon = useMemo(() => {
        switch (backupTask.state) {
            case BackupTaskState.COMPLETE: return "circle-check";
            case BackupTaskState.IN_PROGRESS: return "circle-play";
            case BackupTaskState.FAILED: return "circle-exclamation";
            case BackupTaskState.PENDING: return "clock";
            case BackupTaskState.DELETED: return "slash";
            case BackupTaskState.SKIPPED: return "circle-xmark";
            default: return "circle-exclamation";
        }
    }, [backupTask.state]);

    const statusColor = useMemo(() => {
        switch (backupTask.state) {
            case BackupTaskState.COMPLETE: return "success";
            case BackupTaskState.IN_PROGRESS: return "primary";
            case BackupTaskState.FAILED: return "danger";
            case BackupTaskState.PENDING: return "primary";
            case BackupTaskState.DELETED: return "secondary";
            case BackupTaskState.SKIPPED: return "warning";
            default: return "secondary";
        }
    }, [backupTask.state]);

    return (
        <React.Fragment>
            <div className={`d-flex`}>
                <div className="flex-shrink-0">
                    <FontAwesomeIcon icon={byPrefixAndName.fak[`sharp-${typeIcon}-${statusIcon}`]} className={`fs-24 mt-1 text-${statusColor}`}/>
                </div>

                <div className="ms-3 p-0">
                    <span className="fw-medium">{t(`BackupTaskType.${backupTask.ty}`)}</span>
                    <p className="text-muted mb-0 fs-12 fw-medium">{t(`BackupTaskState.${statusValue}`)}</p>
                </div>
            </div>  
        </React.Fragment>
    );
};

export default ColBackupTask