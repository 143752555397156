import { useState, useEffect, useCallback, useRef } from 'react';
import { ApiCmdStatus, CommandDetail, GalliumApiErrorResponse, GalliumApiSuccessResponse } from 'generated';
import { mutate } from 'swr';
import { useGetCommandDetails } from 'GalliumAPIHooks/Commands/CommandsHooks';

interface UseAsyncCommandOptions {
    onError: (error: GalliumApiErrorResponse) => void;
    onSuccess?: (response: GalliumApiSuccessResponse) => void;
    onComplete?: (command: CommandDetail) => void;
    pollInterval?: number;
}

const useAsyncCommand = ({ onError, onSuccess, onComplete, pollInterval = 1000 }: UseAsyncCommandOptions) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [commandUuid, setCommandUuid] = useState<string | null>(null);
    const { data: command } = useGetCommandDetails(commandUuid, pollInterval);
    const hasCompletedRef = useRef(false); // Ref to track completion

    const startCommand = useCallback((triggerFn: Function, values: any) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                onError(error);
                setIsUpdating(false);
            },
            onSuccess(response: GalliumApiSuccessResponse) {
                onSuccess?.(response);
                setCommandUuid(response?.command.commandUuid);
            },
        };
        setIsUpdating(true);
        triggerFn(values, options);
    }, [onError, onSuccess]);

    const resetCommand = useCallback(() => {
        setCommandUuid(null);
        setIsUpdating(false);
        hasCompletedRef.current = false; // Reset the ref
    }, []);

    useEffect(() => {
        if (command?.summary.status === ApiCmdStatus.COMPLETE && !hasCompletedRef.current) {
            hasCompletedRef.current = true; // Mark as completed
            onComplete?.(command);
            
            // Invalidate or refresh the necessary data
            mutate('vm');
            mutate(`hosts/${command?.summary.hostSlug}`);
            mutate(`vm/${command?.summary.vmSlug}`);

            // Reset command state after 1 second
            setTimeout(() => {
                resetCommand();
            }, 1000);
        }
    }, [command, onComplete, resetCommand]);

    return {
        isUpdating,
        startCommand,
        resetCommand,
    };
};

export default useAsyncCommand;
