import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Card,
    CardBody,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import BackupListComponent from './List';
import { OrgFlags } from 'Components/Gallium/Flags';

const BackupProfileList = () => {
    const {t} = useTranslation()
    document.title = t("backupProfiles.title") +" | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: "View All",
        }
    ]
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title={t("backupProfiles.list")} crumbs={crumbs}>
                    <OrgFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={true}>
                        <Link to="/backups/add">
                            <Button color="soft-primary" className="add-btn"><i className="mdi mdi-plus-circle-outline me-1"></i> {t("backupProfiles.add")}</Button>
                        </Link>
                    </OrgFlags>
                </GalliumBreadcrumb>
                <OrgFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={true}>
                    <BackupListComponent />
                </OrgFlags>
                <OrgFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={false}>
                    <Card>
                        <CardBody className='p-5 mt-5 mb-5 text-center'>

                            <Button disabled outline color="secondary">No Backup License</Button>

                        </CardBody>
                    </Card>
                </OrgFlags>
            </Container>
        </div>
    );
};

export default BackupProfileList;