import React from 'react';
import { useTranslation } from 'react-i18next';


interface TranslationBadgeProps {
    parentKey: string | boolean;
    childKey: string | boolean;
    className?: string
}

const TranslationBadge: React.FC<TranslationBadgeProps> = ({ parentKey, childKey, className }) => {
    const {t} = useTranslation()

    const color = t(`${parentKey}.${childKey}.color`)
    const text = t(`${parentKey}.${childKey}.title`)


    return (
        <React.Fragment>
            <span className={`${className} badge bg-${color}-subtle text-${color}`}>{text}</span>
        </React.Fragment>
    )
};

export default TranslationBadge;