import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';


interface CrumbProps {
  name: string;
  link?: string;
}

interface BreadCrumbProps {
  title: string;
  crumbs: CrumbProps[];
  tabs?: React.ReactNode; 
  children?: React.ReactNode; 
}

const GalliumBreadcrumb: React.FC<BreadCrumbProps> = ({ title, crumbs, tabs, children }) => {
    return (
        <React.Fragment>
            <div className={tabs ? "page-title-box pb-0": "page-title-box"}>
                <Row className=''>
                    <Col className='col-auto'>
                        <div>
                            <ol className="breadcrumb m-0">
                                {crumbs.map((item, index)=>(
                                    <li className={`breadcrumb-item${index === crumbs.length - 1 ? ' active' : ''}`} key={index}>
                                        {item.link ? (
                                            <Link to={item.link}>{item.name}</Link>
                                        ):(
                                            item.name
                                        )}
                                        
                                    </li>
                                ))}
                                
                            </ol>
                            <h1 className="mt-3 mb-2 fs-4 fw-medium">{title}</h1>
                            
                        </div>
                    </Col>
                    <Col>
                        <div className="page-title-right d-flex align-items-center justify-content-end" style={{ height: '100%' }}> 
                            {children}
                        </div>
                    </Col>
                    {tabs? (<Col xs={12}>{tabs}</Col>):(null)}
                </Row>
            </div>
        </React.Fragment>
    );
};

export default GalliumBreadcrumb;