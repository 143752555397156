import { CommandSummary } from 'generated';
import React from 'react';
import StringTidy from './StringTidy';
import { useTranslation } from 'react-i18next';

type FormatCommandProps = {
  command: CommandSummary;
};

const FormatCommand: React.FC<FormatCommandProps> = ({ command }) => {
    const { t } = useTranslation()
    let text: string

    switch(command.cmdType) {

    case "VM_CHANGE_STATE":
        text =  t(`VmPowerStateTransition.${command.newState}`) + " " + t('common.Virtual Machine')
        break;
    case "HOST_POWER_STATE":
        text =  t(`hostPowerAction.${command.newState}`) + " " + t('common.host')
        break;     
    default:
        text = t(`cmdType.${command.cmdType}`) || StringTidy(command.cmdType || 'Unknown Task');
    }

    return (
        <React.Fragment>
            {text}
        </React.Fragment>
    )
};

export default FormatCommand;

