import React from 'react';

import {
    Card,
    CardBody,
    Progress
} from "reactstrap"

import ServerIcon from 'assets/lordicon/wired-outline-57-server.json';
import LordIcon from 'Components/Gallium/LordIcon';
import { CommandSummary } from 'generated';

const VirtualMachineSubmitted = ({command}:{command: CommandSummary}) => {
    function extractPercentage(str) {
        if (!str) return null; // Check if the string is null or undefined
        const regex = /\((\d+)%\)/
        const match = str.match(regex);
        return match ? parseInt(match[1], 10) : null;
    }

    const ProgressBarComponent = ({ progressMessage }) => {
        const percentage = extractPercentage(progressMessage) || ""

        return (
            <>
                {percentage !== null && (
                    <>
                        <span>{progressMessage}</span>
                        <Progress multi className='progress-xl mt-1'>
                            <Progress bar color="primary" value={percentage} />
                        </Progress>
                    </>
                )}
                {percentage === null && (
                    <p>{progressMessage}</p> // Display the original message if no percentage
                )}
            </>
        );
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div>
                        <div className="text-center">
                            <div className="mb-4">
                                <LordIcon icon={ServerIcon} loop={true} />
                            </div>
                            <h5>This Virtual Machine is provisioning</h5>
                            <p className="text-muted">
                                This can take some time depending on the speed of the hypervisor&apos;s internet connection. 
                            </p>

                            <ProgressBarComponent progressMessage={command?.progressMessage} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default VirtualMachineSubmitted;