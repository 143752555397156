import { CreateTemplateDiskRequest, CreateTemplateRequest, GalliumApiErrorResponse, GalliumApiSuccessResponse, Template, TemplateDisk, TemplateResponse, TemplateService, UpdateTemplateRequest } from "generated";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export const useListTemplates = (includeDraft?: boolean) => {
    const listTemplates = () => TemplateService.listTemplates(includeDraft)
    
    const { data, error, isLoading } = useSWR<TemplateResponse>(`templates`, listTemplates, );

    return { data, error, isLoading };
};

export const useGetTemplate = (templateSlug: string) => {
    const getTemplate = () => TemplateService.getTemplate(templateSlug)
    
    const { data, error, isLoading } = useSWR<Template>(`templates/${templateSlug}`, getTemplate);

    return { data, error, isLoading };
};

export const useGetTemplateDisk = (templateSlug: string, diskSlug) => {
    const getTemplateDisk = () => TemplateService.getTemplateDisk(templateSlug, diskSlug)
    
    const { data, error, isLoading } = useSWR<TemplateDisk>(`templates/${templateSlug}/${diskSlug}`, 
        getTemplateDisk,
        {refreshInterval: 0,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
        }
        
    );

    return { data, error, isLoading };
};


export const useDeleteTemplate = (templateSlug: string) => {
    const deleteTemplate = () => TemplateService.deleteTemplate(templateSlug)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse>(`templates`, deleteTemplate);

    return { trigger, error, isMutating };
};

export const useDeleteTemplateDisk = (disk: TemplateDisk) => {
    const deleteTemplateDisk = () => TemplateService.deleteTemplateDisk(disk.templateSlug, disk.slug)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse>(`templates/${disk.templateSlug}`, deleteTemplateDisk);

    return { trigger, error, isMutating };
};

export const useUpdateTemplate = (templateSlug: string) => {
    const updateTemplate = (key: null, {arg}:{arg:UpdateTemplateRequest}) => TemplateService.updateTemplate(templateSlug ,arg)
    
    const { trigger, error, isMutating } = useSWRMutation<Template, GalliumApiErrorResponse, string, UpdateTemplateRequest>(`templates/${templateSlug}`, updateTemplate);

    return { trigger, error, isMutating };
};


export const useCreateTemplate = () => {
    const createTemplate = (key: null, {arg}:{arg:CreateTemplateRequest}) => TemplateService.createTemplate(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<Template, GalliumApiErrorResponse, string, CreateTemplateRequest>(`templates`, createTemplate);

    return { trigger, error, isMutating };
};

export const useCreateTemplateDisk = (templateSlug: string) => {
    const createTemplateDisk = (key: null, {arg}:{arg:TemplateDisk}) => TemplateService.createTemplateDisk(templateSlug, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<TemplateDisk, GalliumApiErrorResponse, string, CreateTemplateDiskRequest>(`templates/${templateSlug}`, createTemplateDisk);

    return { trigger, error, isMutating };
};

