import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

type GalliumThirdColumnProps = {
  children: any;
  icon?: string;
  heading?: string
};

const GalliumThirdColumn: React.FC<GalliumThirdColumnProps> = ({ children, icon, heading }) => {
    // TODO better handle mobile navigation
    const [active, setActive] = useState<boolean>(true)
    return (
        <React.Fragment>  
            <div className={`third-column-content ${active ? "active" : ""}`}>
                <div className="p-2 border-bottom third-column-header">
                    <div className="d-flex">
                        <div className='flex-grow-1'>
                            <h1 className="mt-2 mb-2 fs-4 fw-light"> 
                                {icon ? (
                                    <FontAwesomeIcon icon={byPrefixAndName.fal[icon]} className='me-2 ms-1'/>
                                ):(null)}
                                {heading}
                            </h1>
                        </div>
                        <div className="flex-shrink-0 text-end d-md-none">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["square-chevron-down"]} className='me-3 fs-3 text-muted mt-2 mb-2' onClick={()=> setActive(false)}/>
                        </div>
                    </div>
                </div>
                <div className='p-4 ps-3 pt-3'>
                    {children}
                </div>
            </div>    
        </React.Fragment>
    )
};

export default GalliumThirdColumn;