import { GalliumApiErrorResponse, GalliumApiSuccessResponse, SshImportSuccessResponse, SshKey, SshKeyAddRequest, SshKeyListResponse, SshService } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useGetListSSHKeys = () => {
    const getListSSHKeys = () => SshService.listSshKeys()
    
    const { data, error, isLoading } = useSWR<SshKeyListResponse>(`sshkeys`, getListSSHKeys);

    return { data, error, isLoading };
};

export const useAddSSHKey = () => {
    const addSSHKey = (key: null, {arg}:{arg:SshKeyAddRequest}) => SshService.addSshKey(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, SshKeyAddRequest>(`sshkeys`, addSSHKey);

    return { trigger, error, isMutating };
};

export const useImportSSHKey = () => {
    const importSSHKey = (key: null, {arg}:{arg:string}) => SshService.importSshKeys(arg)
    
    const { data, trigger, error, isMutating } = useSWRMutation<SshImportSuccessResponse, GalliumApiErrorResponse, string, string>(`sshkeys`, importSSHKey);

    return { data, trigger, error, isMutating };
};

export const useDeleteSSHKey = () => {
    const deleteSSHKey = (key: null, {arg}:{arg:SshKey}) => SshService.deleteSshKey(arg.slug)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, SshKey>(`sshkeys`, deleteSSHKey);

    return { trigger, error, isMutating };
};

export const useUpdateSSHKey = () => {
    const updateSshKey = (key: null, {arg}:{arg:SshKey}) => SshService.updateSshKey(arg.slug, {name: arg.name, autoAdd: arg.autoAdd})
    
    const { trigger, error, isMutating } = useSWRMutation<SshKey, GalliumApiErrorResponse, string, SshKey>(`sshkeys`, updateSshKey);

    return { trigger, error, isMutating };
};



