import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    Card,
    CardBody,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useGetIncidentDetail } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import StringTidy from 'Components/Gallium/StringTidy';
import moment from 'moment';
import { OffsetDateTime } from 'generated';
import IncidentControls from '../Common/IncidentControls';

const MonitoringIncidentView = () => {
    document.title = "View Incident | Gallium";
    const crumbs = [
        {
            name: "Monitoring",
            link: "/monitoring"
        },
        {
            name: "View Incident",
        }
    ]
    const incidentSlug = useParams().slug;
    const {data, isLoading, error} = useGetIncidentDetail(incidentSlug)

    const detailedTime = (input: OffsetDateTime): string => {
        const momentDate = moment.utc(input).local(); // Convert once, use multiple times
        return `${momentDate.fromNow()} at ${momentDate.format('h:mmA (UTCZ)')}`;
    };

    const HistoryDot = ({name, color, time}) => {
        if (time) {
            return (
                <p className="text-truncate fs-14 mb-2">
                    <i className={`mdi mdi-circle align-top text-${color} me-2`}></i>
                    {`${name} at `}
                    {detailedTime(time)}
                </p>
            )
        } else {
            return null
        }
    }

    return (
        <div className="page-content">
            <Container fluid>
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <React.Fragment>
                        <GalliumBreadcrumb title="View Incident" crumbs={crumbs}>
                            <IncidentControls incident={data.incident} />
                        </GalliumBreadcrumb>
                        <Card>
                            <CardBody>
                            
                                <GalliumFormHeader icon="alert-circle" title="Incident Detail">
                                    <div className='mb-3 mt-3'>
                                        <h6>Status</h6>
                                        {StringTidy(data.incident.status)}
                                    </div>
                                    <div className='mb-3 mt-3'>
                                        <h6>Type</h6>
                                        {StringTidy(data.incident.alertKey)}
                                    </div>
                                    <div className='mb-3 mt-3'>
                                        <h6>Host</h6>
                                        <Link to={"/hypervisors/" + data.incident.host.slug}>
                                            {data.incident.host.name}
                                        </Link>
                                    </div>
                                </GalliumFormHeader>


                                <GalliumFormHeader icon="clock" title="Incident History">
                                    <HistoryDot name="Opened" color="danger" time={data.incident.openedAt}/>
                                    <HistoryDot name="Acknowledged" color="warning" time={data.incident.acknowledgedAt}/>
                                    <HistoryDot name="Closed" color="success" time={data.incident.closedAt}/>
                                </GalliumFormHeader>
                            </CardBody>
                        </Card>
                        <Link to="/monitoring" className="btn btn-link text-decoration-none btn-label pt-0 mt-0">
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                            Back to Incidents
                        </Link> 
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
};

export default MonitoringIncidentView;
                    