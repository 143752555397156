import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

import StatusBadge from './StatusBadge';
import { useMonitoringInfo } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import StringTidy from './StringTidy';
import moment from 'moment';
import { Link } from 'react-router-dom';


const IncidentsDropdown = () => {

    const { data } = useMonitoringInfo();

    //Dropdown Toggle
    const [isCommandsDropdown, setIsCommandsDropdown] = useState(false);
    const toggleCommandsDropdown = () => {
        setIsCommandsDropdown(!isCommandsDropdown);
    };


    return (
        <React.Fragment>
            <Dropdown isOpen={isCommandsDropdown} toggle={toggleCommandsDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-error-circle fs-22'></i>
                    {data?.activeIncidents?.length > 0 ? (
                        <span
                            className="position-absolute topbar-badge translate-middle badge rounded-pill bg-danger">{data?.activeIncidents?.length}<span
                                className="visually-hidden">active tasks</span></span>
                    ):(null)}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-danger rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Incidents </h6>
                                </Col>
                            </Row>
                        </div>
                    </div>


                    <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                        {data?.activeIncidents?.length > 0 ? (
                            data?.activeIncidents?.map((incident, key) => (
                                <React.Fragment key={key}>
                                    <Link to={`/monitoring/${incident.slug}`}>
                                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                                            <div className="d-flex">
                                                <div className="flex-1">
                                                    <StatusBadge status={incident.status} />

                                                    <h6 className="mt-2 mb-0 lh-base">
                                                        {StringTidy(incident.alertKey)}
                                                    </h6>

                                                    {incident?.host?.name ? (
                                                        <p className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> {incident.host.name}</p>
                                                    ):(null)}

                                                    <p className="mb-0 mt-1 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i className="mdi mdi-clock-outline me-1"></i>{moment.utc(incident.openedAt).fromNow()}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </React.Fragment>
                            ))
                        ):(
                            <div className="my-3 p-5 text-center">
                                <p className='text-muted'>No Active Incidents</p>
                            </div>
                        )}
                    </SimpleBar>

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default IncidentsDropdown;