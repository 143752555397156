
import React from 'react';

import {
    Card,
    CardBody
} from "reactstrap"
import ServerIcon from 'assets/lordicon/wired-outline-57-server.json';
import LordIcon from 'Components/Gallium/LordIcon';

const HypervisorJoining = () => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div>
                        <div className="text-center">
                            <div className="mb-4">
                                <LordIcon icon={ServerIcon} loop={true} />
                            </div>
                            <h5>This Hypervisor is Pending</h5>
                            <p className="text-muted">
                                If the Hypervisor is running then this should only take a few moments. 
                            </p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default HypervisorJoining;