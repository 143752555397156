import React, { useEffect } from 'react';
import { useUserProfileRequest, useUserProfileUpdateRequest } from 'GalliumAPIHooks/User/UserProfileHooks';

const LightDark = () => {
    const {data: userProfile} = useUserProfileRequest()
    const {trigger} = useUserProfileUpdateRequest()

    const changeHTMLAttribute = (attribute, value) => {
        if (document.documentElement) document.documentElement.setAttribute(attribute, value);
        return true;
    }

    const toggleTheme = () => {
        const currentMode = userProfile.darkMode ? "dark" : "light"
        const newMode = currentMode === "dark" ? "light" : "dark";
        changeHTMLAttribute('data-bs-theme', newMode);
        changeHTMLAttribute('data-sidebar', newMode);
        trigger({darkMode: newMode === "dark" ? true : false})
    }

    // Sync dark mode setting on component mount or when userProfile.darkMode changes
    useEffect(() => {
        if (userProfile) {
            const currentMode = document.documentElement.getAttribute('data-bs-theme');
            const expectedMode = userProfile.darkMode ? "dark" : "light";
            if (currentMode !== expectedMode) {
                changeHTMLAttribute('data-bs-theme', expectedMode);
                changeHTMLAttribute('data-sidebar', expectedMode);
            }
        }
    }, [userProfile]);

    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <button
                onClick={toggleTheme}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className='bx bx-moon fs-22'></i>
            </button>
        </div>
    );
};

export default LightDark;