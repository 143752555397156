import React, { useState } from 'react';

import {
    Button,
} from "reactstrap"

import { VirtualMachineDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import AddBackupProfileModal from './AddBackupProfileModal';

interface AddBackupProfileButtonProps {
    virtualMachine: VirtualMachineDetail
}

const AddBackupProfileButton: React.FC<AddBackupProfileButtonProps> = ({ virtualMachine }) => {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true)
    }

    return (
        <React.Fragment>
            <AddBackupProfileModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                virtualMachine={virtualMachine}
            />

            <Button color="primary" className='mb-0' onClick={openModal}>
                <FontAwesomeIcon icon={byPrefixAndName.fak["light-boxes-stacked-circle-plus"]} className='ms-1 me-2'/>
                {t('virtualMachine.backup.AddBackupProfileButton')}
            </Button>


        </React.Fragment>
    )
};

export default AddBackupProfileButton;