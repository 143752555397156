import React, { useMemo } from 'react';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useMonitoringInfo } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import { IncidentActions, IncidentHost, IncidentStatus, IncidentType } from './IncidentListCol';
import ColTimeAgo from 'Components/Gallium/Columns/ColTimeAgo';

interface ActiveIncidentListProps {
  hypervisorSlug?: string; 
}

const ActiveIncidentList: React.FC<ActiveIncidentListProps> = ({hypervisorSlug}) => {
    const { data, error, isLoading } = useMonitoringInfo()

    const filteredIncidents = data && data.activeIncidents
        ? data.activeIncidents.filter(incident => 
            !hypervisorSlug || incident.host.slug === hypervisorSlug)
        : [];

    const columns = useMemo(
        () => [
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <IncidentStatus {...cellProps} />;
                },
            },
            {
                header: "Type",
                accessorKey: "alertKey",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <IncidentType {...cellProps} />;
                },
            },
            {
                header: "Opened",
                accessorKey: "openedAt",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />;
                },
            },
            {
                header: "Hypervisor",
                accessorKey: "host",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <IncidentHost {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return <IncidentActions {...cellProps} />;
                },
            }
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'openedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Row>
                    <Col lg={12}>
                        <Card id="serviceGatewayList">
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(filteredIncidents || [])}
                                    isGlobalFilter={true}
                                    customPageSize={20}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for an Incident'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}            
                
        </React.Fragment>
    );
};

export default ActiveIncidentList;