import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    Card,
    CardBody,
    Button
} from "reactstrap"

import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import VMListControls from 'Components/Gallium/VM/VirtualMachineControls';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import { useTranslation } from 'react-i18next';

const VirtualMachineDashboard = () => {
    const {t} = useTranslation()
    const {vmSlug} = useParams();
    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(vmSlug, 1500)

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.DASHBOARD.title")} icon={t("virtualMachine.nav.DASHBOARD.icon")}>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <React.Fragment>
                    {virtualMachine.availableActions.changePowerState ? (
                        <div className="text-end pb-3">
                            <VMListControls vm={virtualMachine}/>
                        </div>
                    ):(null)}
                    <Card className='card-dark'>
                        <CardBody className='p-5 mt-5 mb-5 text-center'>
                            {virtualMachine.availableActions.console ? (
                                <Link to="connect">
                                    <Button outline color="light" className="btn-label"><i className="bx bx-desktop label-icon"></i> Connect</Button>
                                </Link>
                            ):(
                                <Button disabled outline color="light" className="btn-label"><i className="bx bx-desktop label-icon"></i> Not Available</Button>
                            )}
                        </CardBody>

                    </Card>
                </React.Fragment>
            )}
        </GalliumThirdColumn>
    )
}

export default VirtualMachineDashboard;