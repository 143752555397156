import React from 'react';

import FeatherIcon from 'feather-icons-react';

import { Field, FieldArray, useFormikContext } from "formik";

import {
    Card,
    Alert,
    Button,
    CardBody,
    Col,
    Row
} from "reactstrap"
import { HostDetail } from 'generated';

const NetworkInterfaces = ({ hypervisor }: {hypervisor: HostDetail}) => {

    // Set up and manage the array of network adpators
    const formik = useFormikContext()

    const handleNetworkAdaptorAdd = () => {
        const networkInterfaces = [...formik.values["networkInterfaces"], hypervisor.defaultNetworkSlug || hypervisor.availableNetworks[0].slug];
        formik.setFieldValue('networkInterfaces', networkInterfaces);
    };

    return (
        <React.Fragment>
            <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="share-2" className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>Network</h5>

                    <div className="mt-3">

                        { formik.errors["networkInterfaces"] ? (
                            <Col sm="12">
                                <Alert color="warning">{formik.errors["networkInterfaces"]}</Alert>
                            </Col>
                        ) : null}
                        <Row>
                            <FieldArray
                                name="networkInterfaces"
                                render={arrayHelpers => (
                                    formik.values["networkInterfaces"].map((item, key) => (
                                        <Col md={6} lg={4} key={key}>
                                            <Card>
                                                <CardBody>
                                                    <div className="d-flex flex-column h-100">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-4"><i className="lab las la-ethernet"></i> Network Adaptor {key + 1}</p>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    {key=== 0 ?  null : <button type="button" className="btn-close float-end fs-11" aria-label="Close" onClick={() => arrayHelpers.remove(key)}></button>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            id={`networkInterfaces${key}`}
                                                            name={`networkInterfaces[${key}]`}
                                                            as="select"
                                                            className="form-select mb-3"
                                                        >
                                                            {hypervisor?.availableNetworks.map((item, key) => (
                                                                <React.Fragment key={key}>
                                                                    <option value={item.slug}>{item.name}</option>
                                                                    {item.children && item.children.map((child, childKey) => (
                                                                        <option value={child.slug} key={`child-${childKey}`}>-- {child.name}</option>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))
                                                            }
                                                        </Field>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )))} />


                            {formik.values["networkInterfaces"].length < 4 && (
                                <Col md={6} lg={4}>
                                    <Card className="border card-border-light">
                                        <CardBody>
                                            <div className="d-flex flex-column h-100">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted mb-4"><i className="lab las la-ethernet"></i> Network Adaptor</p>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div className="d-flex gap-1 align-items-center">

                                                        </div>
                                                    </div>
                                                </div>
                                                <Button color="soft-primary" className="mb-3" onClick={() => handleNetworkAdaptorAdd()}>Add</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default NetworkInterfaces;