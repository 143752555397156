import StatusBadge from "Components/Gallium/StatusBadge";
import StringTidy from "Components/Gallium/StringTidy";
import React from "react";
import { Link } from "react-router-dom";
import IncidentControls from "../Common/IncidentControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";

const IncidentStatus = (cell) => {
    return (
        <React.Fragment>
            <StatusBadge status={cell.getValue()}/>
        </React.Fragment>
    );
};

const IncidentType = (cell) => {
    return (
        <React.Fragment>
            <Link to={`/monitoring/${cell.row.original.slug}`} className="fw-medium link-primary">{StringTidy(cell.getValue())}</Link>
        </React.Fragment>
    );
};


const IncidentHost = (cell) => {
    return (
        <React.Fragment>
            <Link to={"/hypervisors/" + cell.getValue().slug} className="fs-6">
                <FontAwesomeIcon icon={byPrefixAndName.fal["server"]} className='fa-fw me-1'/>
                {cell.getValue().name}   
            </Link>
        </React.Fragment>
    );
};

const IncidentActions = (cell) => {
    return (
        <React.Fragment>
            <IncidentControls incident={cell.row.original} className="btn-sm"/>
        </React.Fragment>
    );
};

export {IncidentStatus, IncidentType, IncidentHost, IncidentActions}