import React from 'react';

import FeatherIcon from 'feather-icons-react';

import ServiceGatewayMappingsComponent from './MappingsComponent';
import { Card, CardBody, Label } from 'reactstrap';

const ServiceGatewayMappings = ({formik, hostData}) => {

    return (
        <React.Fragment>
            <div className="d-flex mb-0">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="shuffle" className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>Traffic Mapping</h5>
                    <Label className="form-label">Create mappings to direct traffic to Virtual Machines</Label>
                    { formik.values["hypervisor"] !== '' ? (
                        <ServiceGatewayMappingsComponent formik={formik} hostData={hostData} />
                    ) : (
                        <Card>
                            <CardBody className="text-muted p-5 text-center">Please first select a Hypervisor</CardBody>
                        </Card>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
};

export default ServiceGatewayMappings;
