import React from "react";

const ColBoolean = (cell) => {
    return (
        <React.Fragment>
            <div className="text-center">
                {cell.getValue() ?
                    <span className="text-success"><i className="ri-checkbox-circle-line fs-17 align-middle"></i></span>
                    :
                    <span className="text-muted"><i className="ri-close-circle-line fs-17 align-middle"></i></span>
                }
            </div>
        </React.Fragment>
    );
};

export default ColBoolean