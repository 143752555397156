import React, { useState } from 'react';

import { useDeleteAlertProfile} from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import { Button } from 'reactstrap';
import DeleteModal from 'Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DeleteAlertProfile = ({alertProfile}) => {
    const {trigger, isMutating} = useDeleteAlertProfile(alertProfile?.slug)
    const navigate = useNavigate()

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    
    const onClickDelete = () => {
        setShowDeleteModal(true);
    };

    const options = {
        onError() {
            toast.warning("Could not Delete Alert Profile")
        },
        onSuccess() {
            toast.success("Alert Profile Deleted")
            navigate("/settings/alerts")
        }
    }

    const handleDeleteAlertProfile = () => {
        trigger(null, options);
        setShowDeleteModal(false);
    };

    return (    
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteAlertProfile}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={alertProfile?.name || "Unknown"}
                isWorking={isMutating}
            />
            <Button color="danger" className='float-end me-2 btn-soft-danger' onClick={onClickDelete}>
                Delete Alert Profile
            </Button>
        </React.Fragment>
    );
};

export default DeleteAlertProfile;