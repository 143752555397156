/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VmNetworkType {
    BRIDGE = 'BRIDGE',
    NAT = 'NAT',
    VLAN = 'VLAN',
    OTHER = 'OTHER',
}
