import { combineReducers } from "redux"

// Front
import Layout from "./layouts/reducer";

const rootReducer = combineReducers({
    Layout,
})

export default rootReducer
