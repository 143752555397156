import React, { useState } from 'react';

import {
    Button
} from "reactstrap"

import { GalliumApiErrorResponse, HostDetail, VmNetwork } from 'generated';
import NetworkEditButton from './NetworkEditButton';
import DeleteModal from 'Components/Common/DeleteModal';
import { useDeleteHostNetwork } from 'GalliumAPIHooks/HostNetwork/HostNetworkHooks';
import NetworkEditModal from './NetworkEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

interface NetworkChildProps {
    hypervisor: HostDetail
    childNetwork: VmNetwork
}

const NetworkChild: React.FC<NetworkChildProps> = ({hypervisor, childNetwork}) => {
    const [showModal, setShowModal] = useState(false);
    const {trigger, isMutating} = useDeleteHostNetwork(hypervisor.slug, childNetwork.slug)

    const [deleteModal, setDeleteModal] = useState(false)

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleDeleteNetworkFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleDeleteNetworkSuccess = () => {
        setDeleteModal(false)
    }

    const handleDeleteNetwork = () => {
        const options = {
            onError(error: object) {
                handleDeleteNetworkFail(error)
            },
            onSuccess() {
                handleDeleteNetworkSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(null, options);
    }
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteNetwork()}
                onCloseClick={() => setDeleteModal(false)}
                isWorking={isMutating}
                recordName={childNetwork.name}
                errorObj={errorObject}
            />
            <NetworkEditModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                hypervisor={hypervisor}
                adaptor={childNetwork}
            />
            
                                        
            <div className="card-footer" key={childNetwork.slug}>
                <div className="row align-items-center gy-3">
                    <div className="col-auto mt-1">
                        <div className="avatar-xs">
                            <div className="avatar-title rounded bg-primary text-white fs-4">
                                <FontAwesomeIcon icon={byPrefixAndName.fas["tag"]} className='fa-fw'/>
                            </div>
                        </div>
                        


                        
                    </div>
                    <div className="col ps-0">
                        <h5 className="fs-14 text-truncate">
                            {childNetwork.name}
                        </h5>
                        
                        <span className="text-muted">VLAN:{" "}</span>
                        <span className="text-muted fw-medium">{childNetwork.vlanId}</span>
                    </div>

                    <div className="col-sm-auto text-end">
                        <div className="hstack gap-2 fs-15">
                                <Button color="primary" className="btn btn-icon btn-soft-primary btn-sm" aria-label="Edit" onClick={() => setShowModal(true)}>
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["pen"]} className='fa-fw'/>
                                </Button>
                                <Button className="btn btn-icon btn-soft-danger btn-sm" aria-label="Delete" onClick={() => {setDeleteModal(true)}}>
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["trash"]} className='fa-fw'/>
                                </Button>
                            </div>
                        
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};

export default NetworkChild;