import useSWR from "swr";

import { CommandDetail, CommandListResponse, CommandsService } from "generated";

export const useGetListCommands = (hostSlug?: string) => {
    const getListCommands = () => CommandsService.listCommands(hostSlug)

    const { data, error, isLoading } = useSWR<CommandListResponse>(`cmd/${hostSlug}`, getListCommands, {
        refreshInterval: 1000,
    });

    return { data, error, isLoading };
};

export const useGetCommandDetails = (commandId: string | null, pollInterval?: number) => {
    // Define a function to fetch command details
    const getCommandDetails = () => {
        if (commandId) {
            return CommandsService.getCommandDetails(commandId);
        }
        // Return a resolved promise with null if commandId is not valid
        return Promise.resolve(null);
    };

    // Use SWR hook with conditional fetching
    const { data, error, isLoading } = useSWR<CommandDetail>(
        commandId ? `cmd/${commandId}` : null, // This key will be null if commandId is not valid, preventing the fetch
        getCommandDetails,
        { refreshInterval: pollInterval || 0 }
    );

    return { data, error, isLoading };
};