import React, { useState } from 'react';

import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";

import {round} from "lodash"

const MetricChartMemory = ({ dataSeries }) => {

    const BasicAreaChartsColors = getChartColorsArray('["--vz-success"]');

    const initOptions = {
        chart: {
            id: 'memChart',
            group: 'performance',
            type: 'area',
            height: 150,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
        },
        title: {
            text: 'Memory Usage',
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2
        },

        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                format: 'h:mm tt',
            },
            range: 3600000
        },
        yaxis: {
            type: 'numeric',
            tickAmount: 2,
            labels: {
                formatter: function(value) {
                    return round(value/1024,1) + " GB"
                }
            }
        },
        legend: {
            horizontalAlign: 'left'
        },
        tooltip: {
            followCursor: false,
            x: {
                format: "dd MMM h:mm tt",
                show: false
            },
            y: {
                formatter: function(value) {
                    return round(value/1024,2) + " GB"
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.0,
                opacityTo: 0.0,
            }
        },
        colors: BasicAreaChartsColors
    };

    const [options] = useState(initOptions)


    return (
        <ReactApexChart
            dir="ltr"
            options={options}
            series={dataSeries || []}
            type="area"
            height="150"
            className="apex-charts"
        />
    );
};

export default MetricChartMemory