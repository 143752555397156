import React from 'react';

import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VirtualMachineDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import getVirtualMachineStatusIcon from '../Common/getVirtualMachineStatusIcon';
import { Link } from 'react-router-dom';

type VirtualMachineStatusHeaderProps = {
  data: VirtualMachineDetail;
};

const VirtualMachineStatusHeader: React.FC<VirtualMachineStatusHeaderProps> = ({ data }) => {
    const { t } = useTranslation()

    const {icon, color} = getVirtualMachineStatusIcon(data?.status)

    return (
        <React.Fragment>
            <div className='p-0'>
                <div className={`d-flex  border-${color} border-bottom`}>
                    <div className="avatar-md flex-shrink-0">
                        <span className={`avatar-title bg-${color}-subtle fs-2`}>
                            <FontAwesomeIcon icon={byPrefixAndName.fak[icon]} className={`fs-2 text-${color}`}/>
                        </span>
                    </div>

                    <div className="ms-2 p-0 col-8">
                        <h5 className="fs-16 mb-1 mt-3 text-truncate">{data.name}</h5>
                        <p className="text-muted mb-0 fs-12">{t(`virtualMachine.status.${data.status}`)}</p>
                    </div>
                </div>  
            </div>
             <div className='border-bottom p-0'>
                <div className={`d-flex  border-${color} border-bottom`}>
                    <ul className="list-unstyled left-nav-list" id="navList">
                        <li>
                            <Link to={`/hypervisors/${data?.host?.slug}`}>
                                <div className="d-flex align-items-center">
                                    <div className={`flex-shrink-0 align-self-center me-2 ms-0 text-${color}`}>
                                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-server-circle-arrow-right"]} className='me-2 fa-fw'/>
                                    </div>
                                    <div className="flex-grow-1">
                                        <p className="text-truncate mb-0">{data.host.name}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>  
            </div>
        </React.Fragment>
    )
};

export default VirtualMachineStatusHeader;