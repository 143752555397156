import { useEffect, useRef } from 'react';
import { Player } from '@lordicon/react';
import { CSSProperties } from 'react';

interface PlayOnceProps {
  icon: any;
  size?: number;
  loop?: boolean
}

const LordIcon: React.FC<PlayOnceProps> = ({ icon, size, loop }) => {
    const playerRef = useRef<Player>(null);
  
    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])

    const loopIfEnabled = () => {
        if (loop) (playerRef.current?.playFromBeginning())
    }

    const styles: CSSProperties = {
        display: 'flex',  // This needs to be set to enable Flexbox
        flex: 1,
        gap: 20,
        alignItems: 'center',  // This centers items vertically in the flex container
        justifyContent: 'center', // This centers items horizontally in the flex container
        flexDirection: 'column',
    }

    return (
        <div style={styles}>
            <Player 
                ref={playerRef} 
                icon={ icon }
                size={ size | 130}
                onComplete={() => loopIfEnabled()}
            />
        </div>
    );
}

export default LordIcon