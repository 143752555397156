import React from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from "Components/Gallium/Loader";

import {
    Row,
} from "reactstrap";

import * as Yup from "yup";

import { useFormik, FormikProvider } from "formik";
import { useGetAllHosts } from 'GalliumAPIHooks/Host/HostHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { useTranslation } from 'react-i18next';
import SingleSelectCard from 'Components/Gallium/Forms/SingleSelectCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


const CreateVMSelectHost = () => {
    const {t} = useTranslation()    
    const {data:hypervisorsList, isLoading: isHypervisorListLoading, error: hypervisorListError} = useGetAllHosts()
    const navigate = useNavigate()

    const selectHostFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            hypervisorSlug: null
        },
        validationSchema: Yup.object({
            hypervisorSlug: Yup.string().required("Please Select A Hypervisor"),
        }),
        onSubmit: (values) => {
            navigate(`/vm/create/${values["hypervisorSlug"]}`)
        },
    });

    return (    
        <FormikProvider value={selectHostFormik}>
            {isHypervisorListLoading ? (<Loader />) : hypervisorListError ? (<ErrorNotice />) : (
                <React.Fragment>
                    <div>
                        <h5 className="mb-1">{t("virtualMachine.create.hostSelectionHeader")}</h5>
                        <p className="text-muted mb-4">
                        {t("virtualMachine.create.hostSelectionText")}
                        </p>
                    </div>

                    <div>

                        <Row className="g-4">
                            {hypervisorsList.filter(item => item.availableActions.createVm).map((hypervisor) => (
                            <SingleSelectCard
                                id={`selectHypervisorCard$-${hypervisor.slug}`}
                                name="hypervisorSlug"
                                value={hypervisor.slug}
                                labelTitle={hypervisor.name}
                                labelText={hypervisor.description}
                                key={hypervisor.slug}
                                width={3}
                            />
                            ))}
                        </Row>

                        <div className="float-end mt-3">
                            <GalliumSubmitButton formik={selectHostFormik} color="primary" spinner={false}> 
                                {t("virtualMachine.create.proceedToTemplate")}
                                <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-right"]} className='ms-2' />
                            </GalliumSubmitButton>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </FormikProvider>
    );
};

export default CreateVMSelectHost;