import React from "react";
import { Card, CardBody, CardHeader, Col, Progress, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { PoolDetail, PoolRole } from "generated";
import HypervisorStoragePoolDisk from "./StoragePoolDisk";
import {round} from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";

interface DiskInfoModalProps {
    pool: PoolDetail,
}

const StoragePoolCard: React.FC<DiskInfoModalProps> = ({ pool }) => {
    const { t } = useTranslation();
    const storageCalculations = {
            storageAllocatedPercent: (pool.allocatedMb / pool.totalMb) * 100,
            storageAvailablePercent: (pool.freeMb / pool.totalMb) * 100,
            storageAllocatedGigabytes: round(pool.allocatedMb / 1024, 0),
            storageAvailableGigabytes: round(pool.freeMb / 1024, 0),
        };
    return ( 
        <Card className="mb-2">
            <CardHeader>
                <Row>
                    <Col>
                        {pool.role === PoolRole.BACKUP? (
                                <h4 className="card-title mb-0">
                                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-shield"]} className='fa-fw me-2'/>
                                    {t("hypervisor.storagePool.PoolRole.BACKUP")}
                                </h4>
                            ):(
                                <h4 className="card-title mb-0">
                                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-sparkle"]} className='fa-fw me-2'/>
                                    {t("hypervisor.storagePool.PoolRole.PRIMARY")}
                                </h4>
                            ) 
                            
                        }
                    </Col>
                    <Col className="text-end">
                        <span className="badge border border-primary text-primary fs-6">
                            {t(`hypervisor.storagePool.DiskGroupType.${pool.groupType}`)}
                        </span>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Progress multi className="progress-xl mt-2">
                    <Progress bar color="primary" value={storageCalculations.storageAllocatedPercent} />
                    <Progress bar color="secondary-subtle" value={storageCalculations.storageAvailablePercent} />
                </Progress>

                <div className="mt-1 pt-2">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-primary me-2"></i>{t("hypervisor.storagePool.allocated")}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{storageCalculations.storageAllocatedGigabytes} GB</p>
                        </div>
                    </div>
                    <div className="d-flex mb-0">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-secondary me-2"></i>{t("hypervisor.storagePool.available")}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{storageCalculations.storageAvailableGigabytes} GB</p>
                        </div>
                    </div>
                </div>
            </CardBody>
            {pool.groupDetail[0].devices.map((disk, index)=>(
                <HypervisorStoragePoolDisk disk={disk} key={disk.id} index={index}/>
            ))}
        </Card>
    );
}

export default StoragePoolCard