import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { BackupProfile, DayOfWeek, GalliumApiErrorResponse, UpdateBackupProfileRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import { useUpdateBackupProfile } from 'GalliumAPIHooks/Backup/BackupHooks';
import BackupProfileSettings from '../Common/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { Link } from 'react-router-dom';


interface BackupProfileUpdateProps {
    backupProfile: BackupProfile;
}

const BackupProfileUpdate: React.FC<BackupProfileUpdateProps> = ({backupProfile}) => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useUpdateBackupProfile(backupProfile.slug)

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)

    const handleupdateBackupProfileFail = (error: GalliumApiErrorResponse) => {
        updateBackupProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleupdateBackupProfileSuccess = () => {
        toast.success("Backup Profile Updated")
    }

    const handleUpdateBackupProfileRequest = (values: UpdateBackupProfileRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleupdateBackupProfileFail(error)
            },
            onSuccess() {
                handleupdateBackupProfileSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }


    const updateBackupProfileFormik = useFormik({
        initialValues: {
            name: backupProfile.name,
            fullBackupDay: backupProfile.fullBackupDay,
            expiryDays: backupProfile.expiryDays,
            backupTime: {
                hour: backupProfile.backupTime.hour,
                minute: backupProfile.backupTime.minute,
                timezoneOffsetMinutes: backupProfile.backupTime.timezoneOffsetMinutes
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            fullBackupDay: Yup.mixed<DayOfWeek>().oneOf(Object.values(DayOfWeek)).required('Full backup day is required'),
            backupTime: Yup.object({
                hour: Yup.number()
                    .integer('Hour must be an integer')
                    .min(0, 'Hour must be at least 0')
                    .max(23, 'Hour cannot be more than 23')
                    .required('Hour is required'),
                minute: Yup.number()
                    .integer('Minute must be an integer')
                    .min(0, 'Minute must be at least 0')
                    .max(55, 'Minute cannot be more than 55')
                    .required('Minute is required'),
                timezoneOffsetMinutes: Yup.number()
                    .integer('Timezone offset must be an integer')
                    .required('Timezone offset is required'),
            }).required(),
            expiryDays: Yup.number()
                .integer('Retention must be an integer')
                .min(1, 'Retention must be at least 1 day')
                .max(2555, 'Retention cannot be more than 2555 days')
                .required('Retention is required'),
        }),
        onSubmit: (values) => {
            const processedValues = {
                ...values,
                backupTime: {
                    ...values.backupTime,
                    hour: Number(values.backupTime.hour),
                    minute: Number(values.backupTime.minute),
                    timezoneOffsetMinutes: Number(values.backupTime.timezoneOffsetMinutes),
                },
            };
            handleUpdateBackupProfileRequest(processedValues)
        },
    });


    return (    
        <React.Fragment>
            <FormikProvider value={updateBackupProfileFormik}>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">{t("backupProfiles.settingsForm.profileSettings")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ErrorAlert errorObj={errorObject} />
                        <GalliumFormHeader icon="target" title={t("backupProfiles.settingsForm.target")}>
                            <div className="mt-0 mb-3">
                                <Label className="form-label d-block" htmlFor="hypervisorField">
                                    {t("backupProfiles.settingsForm.destHypervisor")}
                                </Label>
                                <Link to={`/hypervisors/${backupProfile.targetHost.slug}`} className="btn btn-outline-primary" id="hypervisorField">
                                    <span className="fw-semibold">
                                        <FontAwesomeIcon icon={byPrefixAndName.fasl["server"]} className="ms-0 me-3" />
                                        {backupProfile.targetHost.name}
                                    </span>
                                </Link>
                            </div>
                        </GalliumFormHeader>

                        <BackupProfileSettings/>
                        
                        <div className="float-end">
                            <GalliumSubmitButton formik={updateBackupProfileFormik} spinner={isMutating} color="success">
                                Update
                            </GalliumSubmitButton>
                        </div>
                    </CardBody>
                </Card>

            </FormikProvider>
        </React.Fragment>
    );
};

export default BackupProfileUpdate;