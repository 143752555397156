/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { HostVolumeDetailsResponse } from '../models/HostVolumeDetailsResponse';
import type { HostVolumeResponse } from '../models/HostVolumeResponse';
import type { HostVolumeUpdateRequest } from '../models/HostVolumeUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HostVolumeService {

    /**
     * Get disk volumes available on a host
     * @param hostSlug
     * @returns HostVolumeResponse OK
     * @throws ApiError
     */
    public static getHostVolumes(
        hostSlug: string,
    ): CancelablePromise<HostVolumeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/volumes',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Get details about a host volume
     * @param hostSlug
     * @param volumeSlug
     * @returns HostVolumeDetailsResponse OK
     * @throws ApiError
     */
    public static getHostVolumeDetails(
        hostSlug: string,
        volumeSlug: string,
    ): CancelablePromise<HostVolumeDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/volumes/{volumeSlug}',
            path: {
                'hostSlug': hostSlug,
                'volumeSlug': volumeSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update fields on a host volume
     * @param hostSlug
     * @param volumeSlug
     * @param requestBody
     * @returns HostVolumeDetailsResponse OK
     * @throws ApiError
     */
    public static updateHostVolumeDetails(
        hostSlug: string,
        volumeSlug: string,
        requestBody: HostVolumeUpdateRequest,
    ): CancelablePromise<HostVolumeDetailsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hosts/{hostSlug}/volumes/{volumeSlug}',
            path: {
                'hostSlug': hostSlug,
                'volumeSlug': volumeSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Permanently destroy a volume on a host. The volume must be detached.
     * @param hostSlug
     * @param volumeSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static destroyHostVolume(
        hostSlug: string,
        volumeSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/hosts/{hostSlug}/volumes/{volumeSlug}',
            path: {
                'hostSlug': hostSlug,
                'volumeSlug': volumeSlug,
            },
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
