import React, { useMemo } from 'react';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from "reactstrap"

import { BackupTask, BackupTaskState } from 'generated';
import { LocalDataTimeString } from 'Components/Gallium/GalliumTimeHelpers';
import { useTranslation } from 'react-i18next';
import ColBackupTask from 'Components/Gallium/Columns/ColBackupTask';
import ColTimeAgo from 'Components/Gallium/Columns/ColTimeAgo';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import { Link, useParams } from 'react-router-dom';
import ColBackupStatus from 'Components/Gallium/Columns/ColBackupStatus';

interface BackupSetTaskListComponentProps {
    backupTasks: Array<BackupTask>;
}

const BackupSetTaskListComponent: React.FC<BackupSetTaskListComponentProps> = ({ backupTasks }) => {
    const {t} = useTranslation()
    const {profileSlug, setSlug} = useParams()
    const columns = useMemo(
        () => [
            {
                header: "Type",
                accessorKey: "ty",
                enableColumnFilter: false,
                sortable: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <ColBackupTask {...cellProps.row.original} />
                },
            },
            {
                header: "Started",
                accessorKey: "startedAt",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />
                },
            },{
                header: "Finished",
                accessorKey: "finishedAt",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    if (cellProps.row.original.state === BackupTaskState.IN_PROGRESS)
                    return (
                        <ColBackupStatus {...cellProps.row.original.progress} />
                    );
                    return <ColTimeAgo {...cellProps} />
                },
            },{
                header: "Size",
                accessorKey: "sizeMb",
                enableColumnFilter: false,
                className: "col-1",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            {formatDiskSize(cellProps.getValue())}
                        </React.Fragment>
                    );
                },
            },{
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-2 text-end",
                cell: (cellProps) => {
                    if (cellProps.row.original.state === BackupTaskState.COMPLETE)
                    return (
                        <Link to={`/backups/${profileSlug}/${setSlug}/restore/${cellProps.getValue()}`} className='btn btn-sm btn-soft-primary'>
                            {t("virtualMachine.backup.RestoreButton")}
                        </Link>
                    );
                    return (null)
                },
            }
            
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'startedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Card id="BackupList">
                        <CardHeader>
                            <h4 className="card-title mb-0">Backup Tasks</h4>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <TableContainer
                                columns={columns}
                                data={(backupTasks || [])}
                                isGlobalFilter={true}
                                customPageSize={20}
                                sorting={sorting}
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap mb-0"
                                theadClass="table-light table-nowrap"
                                thClass="table-light text-muted"
                                SearchPlaceholder='Search for a Backup Task'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                
        </React.Fragment>
    );
};

export default BackupSetTaskListComponent;