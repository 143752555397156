import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

const Hypervisor = (cell) => {
    const hypervisor = cell.getValue()[0].ingresses[0]
    return (
        <Link to={"/hypervisors/" + hypervisor.hostSlug} className="fs-6">
            <FontAwesomeIcon icon={byPrefixAndName.fal["server"]} className='fa-fw me-1'/>
            {hypervisor.hostName} 
        </Link>
    );
};

const Mappings = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue().map((item, key)=>(
                <p key={key} className="mb-1">
                    <Badge color="light" className="text-body">{item.type} {item.ingresses[0].port}</Badge>
                    <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-right"]} className='me-1 ms-1'/>
                    <Badge color="light" className="text-body">{item.targets[0].vmName}:{item.targets[0].targetPort}</Badge>
                </p>
            ))}
        </React.Fragment>
    );
};

export {Mappings, Hypervisor}