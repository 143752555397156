import React from 'react';
import { Field, FieldAttributes } from 'formik';
import { Col } from 'reactstrap';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface SingleSelectCardProps extends FieldAttributes<any> {
    id: string;
    name: string;
    value: string;
    labelTitle: string;
    labelText: string;
    width?: number
}

const SingleSelectCard: React.FC<SingleSelectCardProps> = ({
    id,
    name,
    value,
    labelTitle,
    labelText,
    width = 6,
    ...rest
}) => {
    return (
        <Col lg={width}>
            <div className="form-check card-radio">
                <Field
                    id={id}
                    name={name}
                    value={value}
                    type="radio"
                    className="form-check-input"
                    {...rest}
                />
                <Label className="form-check-label" htmlFor={id}>
                    <span className="fs-14 mb-1 text-wrap d-block">
                        {labelTitle}
                    </span>
                    <span className="text-muted fw-normal text-truncate d-block">
                        {labelText || '\u00A0'} {/* Render a non-breaking space when labelText is empty */}
                    </span>
                </Label>
            </div>
        </Col>
    );
};

export default SingleSelectCard;
