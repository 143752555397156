import React, { useState } from "react";

import { Modal,  ModalBody, ModalHeader } from "reactstrap";

import { Col, Row } from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { useUpdateHostNetwork } from "GalliumAPIHooks/HostNetwork/HostNetworkHooks";
import { GalliumApiSuccessResponse, GalliumApiErrorResponse, HostDetail, VmNetwork, HostNetworkUpdateRequest, VmNetworkType } from "generated";
import { useTranslation } from 'react-i18next';

interface NetworkEditModalProps {
    showModal: boolean,
    closeModal: () => void,
    adaptor: VmNetwork
    hypervisor: HostDetail
}

const NetworkEditModal: React.FC<NetworkEditModalProps> = ({ showModal, closeModal, hypervisor, adaptor }) => {
    const { trigger, isMutating } = useUpdateHostNetwork(hypervisor.slug, adaptor.slug);

    const { t } = useTranslation(['translation'])

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleAddNetworkChangeFail = (error: GalliumApiErrorResponse) => {
        networkEditFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddNetworkChangeSuccess = (data: GalliumApiSuccessResponse) => {
        networkEditFormik.resetForm()
        closeModal()
    }

    const handleAddNetworkRequest = (values: HostNetworkUpdateRequest) => {
        const options = {
            onError(error: object) {
                handleAddNetworkChangeFail(error)
            },
            onSuccess(data: GalliumApiSuccessResponse) {
                handleAddNetworkChangeSuccess(data)
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const networkEditFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: adaptor.name,
            mtu: adaptor.currentMtu
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required('Name is required'),
            ...(adaptor.networkType === VmNetworkType.BRIDGE && {
                mtu: Yup.number()
                    .required('MTU is required')
                    .min(1500, 'MTU must be between 1500 and 9000')
                    .max(9000, 'MTU must be between 1500 and 9000')
                    .integer('MTU must be an integer'),
            }),
        }),
        onSubmit: (values) => {
            handleAddNetworkRequest(values);
        }
    });

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('translation:hypervisor:editNetwork')}
            </ModalHeader>      
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={networkEditFormik}>
                    <Row className="g-2">
                        <Col lg={12}>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                label={t('translation:common:name')}
                                placeholder="Example: Data VLAN"
                                component={GalliumInput}
                            />
                        </Col>
                        {adaptor.networkType === VmNetworkType.BRIDGE && (
                            <Col lg={6}>
                                <Field
                                    type="number"
                                    id="mtu"
                                    name="mtu"
                                    label="MTU"
                                    placeholder="1500-9000"
                                    component={GalliumInput}
                                />
                            </Col>
                        )}
                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={networkEditFormik} color="success" spinner={isMutating}>
                                {t('translation:common:update')}
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </FormikProvider>
            </ModalBody>
        </Modal>
    );
};

export default NetworkEditModal




    


                