import React from 'react';
import { Field } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';
import { useTranslation } from 'react-i18next';

const TemplateBasics = () => {
    const {t} = useTranslation();

    return (
        <GalliumFormHeader title={t("templates.createTemplateForm.title.identification")} icon="info">
            <Field
                name="name"
                label={t("templates.createTemplateForm.labels.name")}
                id="name"
                className="form-control"
                placeholder={t("templates.createTemplateForm.placeholders.name")}
                type="text"
                width={4}
                component={GalliumInput}
            />

            <Field
                name="description"
                id="description"
                className="form-control"
                placeholder={t("templates.createTemplateForm.placeholders.description")}
                type="textarea"
                label={t("templates.createTemplateForm.labels.description")}
                component={GalliumInput}
            />
        </GalliumFormHeader>
    );
};

export default TemplateBasics;