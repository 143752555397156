import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import TableContainer from 'Components/Gallium/TableContainerNew';

import { toast } from "react-toastify";

import { useAcceptOrgInvite, useGetPendingOrgInvitations } from '../../../GalliumAPIHooks/User/UserHooks';
import { GalliumOrg } from '../../../generated/models/GalliumOrg';

const PendingInvitesTable = () => {
    const { data } = useGetPendingOrgInvitations();

    const { trigger: userAcceptInviteTrigger } = useAcceptOrgInvite()

    const handleAcceptInviteFail = (error) => {
        toast.error(error.error || "Accept Invite Failed", { autoClose: 3000 });
    }

    const handleAcceptInviteSuccess = () => {
        toast.success("Invitation Accepted", { autoClose: 3000 });
    }

    const handleAcceptInviteRequest = (orgObj: GalliumOrg) => {
        const options = {
            onError(error: object) {
                handleAcceptInviteFail(error)
            },
            onSuccess() {
                handleAcceptInviteSuccess()
            },
        }
        userAcceptInviteTrigger(orgObj.slug, options);
    }


    const columns = useMemo(
        () => [
            {
                header: "Organisation Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-8",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue()}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-4 text-end",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <div className="fs-15">
                                <Link to="#" className="remove-item-btn" onClick={() => {handleAcceptInviteRequest(cellProps.row.original) }}>
                                    <span className='btn btn-success'>
                                        Accept
                                    </span>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                },
            }
        ],
        [] // The dependencies array
    );

    return (
        <React.Fragment>

            <div className="mb-1">
                <h5 className="card-title mb-3">Pending Invites</h5>
                <p className="text-muted mb-0">Invitation to join other Organisations will show here. </p>
            </div>                    

            <div style={{"margin": -8}}>
                <TableContainer
                    columns={columns}
                    data={(data?.invites || [])}
                    isGlobalFilter={true}
                    customPageSize={20}
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap mb-0"
                    theadClass="table-light table-nowrap"
                    thClass="table-light text-muted"
                    SearchPlaceholder='Search for an Invite...'
                    searchDisabled={true}
                />
            </div>
      
        </React.Fragment>
    );
};

export default PendingInvitesTable;