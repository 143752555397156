import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import Loader from 'Components/Gallium/Loader';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import { useIgnoreStorageSafety } from 'GalliumAPIHooks/Storage/StorageHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { GalliumApiErrorResponse } from 'generated';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';


const HypervisorDisableStorageSafety = () => {
    const {t} = useTranslation()
    const hypervisorId = useParams().id;
    const {data: hypervisorDetail, isLoading, error} = useGetHost(hypervisorId)
    const {trigger, isMutating} = useIgnoreStorageSafety(hypervisorId)
    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null)
    const navigate = useNavigate()

    const handleDisableStorageSafetyRequest = () => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                disableStorageSafetyFormik.setErrors(error.errors || {})
                setErrorObject(error || {})
            },
            onSuccess() {
                navigate(`/hypervisors/${hypervisorDetail.slug}?tab=STORAGEPOOLS`);
            }
        }
        setErrorObject(undefined)
        trigger(null, options);
    }

    // Formik Instance for Storage Destruction
    const disableStorageSafetyFormik = useFormik({
        initialValues: {
            confirmation: false
        },
        validationSchema: Yup.object({
            confirmation: Yup.boolean().oneOf([true], 'You must confirm to proceed'),
        }),
        onSubmit: () => {
            handleDisableStorageSafetyRequest()
        },
    });
    
    document.title = t("hypervisor.disableStorageSafety.pageTitle") + " | Gallium";
    const crumbs = [
        {
            name: t("mainNav.hypervisors"),
            link: "/hypervisors"
        },
        {
            name: hypervisorDetail?.name,
            link: `/hypervisors/${hypervisorDetail?.slug}`
        },
        {
            name: t("hypervisor.disableStorageSafety.pageTitle"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <React.Fragment>
                            <GalliumBreadcrumb title={t("hypervisor.disableStorageSafety.pageTitle")} crumbs={crumbs} />
                            <Row>
                                <Col>
                                    <FormikProvider value={disableStorageSafetyFormik}>
                                        <Card>
                                            <ErrorAlert errorObj={errorObject} />
                                            <CardBody className="checkout-tab">
                                                <div className="mb-4">
                                                    <h5 className="mb-1">{t("hypervisor.disableStorageSafety.confirmActionTitle")}</h5>
                                                    <p className="mb-4">
                                                        {t("hypervisor.disableStorageSafety.confirmActionText")}

                                                    </p>
                                                </div>

                                                
                                                <div className='form-check pt-1 pb-2'>
                                                    <Field
                                                        label={t("hypervisor.disableStorageSafety.confirmCheckbox")}
                                                        name="confirmation"
                                                        id="confirmationCheck"
                                                        className="form-check-input mb-3"
                                                        type="checkbox"
                                                        component={GalliumInput}
                                                    />
                                                </div>
    

                                                <div className="text-end">
                                                    <GalliumSubmitButton spinner={isMutating} formik={disableStorageSafetyFormik} color="danger">
                                                        {t("hypervisor.disableStorageSafety.submit")}
                                                    </GalliumSubmitButton>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </FormikProvider>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default HypervisorDisableStorageSafety