import React, { useState } from "react";
import { Button, Label,  Modal,  ModalBody, ModalHeader } from "reactstrap";
import { Col } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { GalliumApiErrorResponse, VirtualMachineDetail, ConfigureVmNicAction, VmNetwork } from "generated";
import { useTranslation } from "react-i18next";
import Loader from "Components/Gallium/Loader";
import ErrorNotice from "Components/Gallium/ErrorNotice";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";
import { useGetHost } from "GalliumAPIHooks/Host/HostHooks";
import useAsyncCommand from "Components/Hooks/useAsyncCommand";

interface AddAdaptorModalFormProps {
    closeModal: () => void,
    candidateNetworks: Array<VmNetwork>,
    virtualMachine: VirtualMachineDetail
}

const AddAdaptorModalForm:React.FC<AddAdaptorModalFormProps> =({candidateNetworks, virtualMachine, closeModal}) => {
    const {t} = useTranslation()   
    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState(null);

    const { isUpdating, startCommand } = useAsyncCommand({
        onError: (error: GalliumApiErrorResponse) => {
            addAdaptorFormik.setErrors(error.errors || {});
            setErrorObject(error || {});
        },
        onSuccess: () => {
            addAdaptorFormik.resetForm();
            closeModal()
        },
    });

    const addAdaptorFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            networkInterfaces: [
                {
                    hostNetworkSlug: null,
                    action: ConfigureVmNicAction.CREATE
                }
            ]
        },
        validationSchema: Yup.object().shape({
            networkInterfaces: Yup.array().of(
                Yup.object().shape({
                    hostNetworkSlug: Yup.string()
                        .required('Network selection is required'), // Ensures it's required and not null/empty
                    action: Yup.string().required(),
                })
            )
        }),
        onSubmit: (values) => {
            startCommand(trigger, values);
        }
    });

    return (
        <React.Fragment>
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={addAdaptorFormik}>

                    <Label>{t('virtualMachine.network.addInterfaceField')}</Label>
                    <Field
                        id="addInterfaceField"
                        name={`networkInterfaces[${0}].hostNetworkSlug`}
                        as="select"
                        className="form-select mb-3"
                        
                    >
                        <option>{t("common.pleaseSelect")}</option>
                        {candidateNetworks?.map((network, key) => (
                            <option value={network.slug} key={key}>{network.name}</option>
                        ))
                        }
                    </Field>

                

                    <Col lg={12} className="text-end mt-3">
                        <GalliumSubmitButton formik={addAdaptorFormik} color="success" waiiting={isUpdating} spinner={isMutating}>
                            {t('virtualMachine.network.addAdaptorButton')}
                        </GalliumSubmitButton>
                    </Col>

                </FormikProvider>
            </ModalBody>
        </React.Fragment>
    )
}

interface AddAdaptorModalProps {
    showModal: boolean,
    closeModal: () => void,
    virtualMachine: VirtualMachineDetail
}

const AddAdaptorModal: React.FC<AddAdaptorModalProps> = ({ showModal, closeModal, virtualMachine }) => {
    const {t} = useTranslation()
    const {data: hypervisor, error, isLoading} = useGetHost(virtualMachine.host.slug)
    const candidateNetworks = hypervisor?.availableNetworks.filter(
        (network) => !virtualMachine.networkInterfaces.some(
            (vmInterface) => vmInterface.hostNetworkSlug === network.slug
        )
    );

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('virtualMachine.network.addAdaptorButton')}
            </ModalHeader>      
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                
                candidateNetworks.length !== 0 ? (
                    
                    <AddAdaptorModalForm candidateNetworks={candidateNetworks} virtualMachine={virtualMachine} closeModal={closeModal}/>

                ):(
                    <ModalBody className="p-5 m-4 mt-3 text-center">
                        <Button disabled outline color="">
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-ethernet-slash"]} className='me-2'/>
                            {t('virtualMachine.network.addInterfaceNoCandidate')}
                        </Button>
                    </ModalBody>
                )
            )}
        </Modal>
    );
};

export default AddAdaptorModal


