/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { SshImportSuccessResponse } from '../models/SshImportSuccessResponse';
import type { SshKey } from '../models/SshKey';
import type { SshKeyAddRequest } from '../models/SshKeyAddRequest';
import type { SshKeyEditRequest } from '../models/SshKeyEditRequest';
import type { SshKeyListResponse } from '../models/SshKeyListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SshService {

    /**
     * List SSH keys
     * @returns SshKeyListResponse OK
     * @throws ApiError
     */
    public static listSshKeys(): CancelablePromise<SshKeyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sshkeys',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Add SSH key
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static addSshKey(
        requestBody: SshKeyAddRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sshkeys',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Import SSH keys
     * @param ghUsername The github username to import SSH keys from
     * @returns SshImportSuccessResponse The keys were imported
     * @throws ApiError
     */
    public static importSshKeys(
        ghUsername: string,
    ): CancelablePromise<SshImportSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sshkeys/import',
            query: {
                'gh_username': ghUsername,
            },
            errors: {
                400: `Bad Request`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Update the settings on an ssh key
     * @param slug
     * @param requestBody
     * @returns SshKey The key was updated
     * @throws ApiError
     */
    public static updateSshKey(
        slug: string,
        requestBody: SshKeyEditRequest,
    ): CancelablePromise<SshKey> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/sshkeys/{slug}',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Delete an SSH key
     * @param slug
     * @returns GalliumApiSuccessResponse The key was deleted successfully
     * @throws ApiError
     */
    public static deleteSshKey(
        slug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sshkeys/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                404: `The key to delete could not be found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
