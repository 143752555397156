import React, { useMemo } from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Table,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import StatusBadge from 'Components/Gallium/StatusBadge';
import FormatCommand from 'Components/Gallium/FormatCommand';
import { useGetListCommands } from 'GalliumAPIHooks/Commands/CommandsHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { ApiCmdStatus, HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableContainer from 'Components/Gallium/TableContainerNew';
import { ColStatusBadge } from 'Components/Gallium/Columns';
import ColTimeAgo from 'Components/Gallium/Columns/ColTimeAgo';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type HypervisorTasksProps = {
  hypervisorSlug: string;
};

const HypervisorTasksBadge: React.FC<HypervisorTasksProps> = ({ hypervisorSlug }) => {
    const { data, error, isLoading } = useGetListCommands();

    const hostCommands = data?.commands?.filter(command => command.hostSlug === hypervisorSlug);
    // Compute the number of in-progress commands, handling cases where data or commands may not be loaded yet
    const numInProgressCommands = hostCommands?.filter(command => command.status === ApiCmdStatus.IN_PROGRESS).length ?? 0;

    if (isLoading || error || numInProgressCommands === 0) {
        // Return null to render nothing if loading, error, or no commands in progress
        return null;
    }

    // Render the badge only if there are in-progress commands
    return (
        <span className="badge bg-primary text-white rounded p-1">{numInProgressCommands}</span>
    );
};

const HypervisorTasks: React.FC<HypervisorTasksProps> = ({ hypervisorSlug }) => {
    const { t } = useTranslation()
    const { data, error, isLoading } = useGetListCommands();

    const hostCommands = data?.commands?.filter(command => command.hostSlug === hypervisorSlug)

    const columns = useMemo(
        () => [
            {
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    const command = cellProps.row.original
                    return (
                        <React.Fragment>
                            <FormatCommand command={command}/> 
                            <p className='text-muted m-0 small'>{command?.progressMessage || ""}</p>
                            {command?.vmSlug ? (
                                <Link to={`/vm/${command.vmSlug}`} className="text-muted fs-12 mb-0">
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["arrow-turn-down-right"]} className='ms-1 me-2'/>
                                    {command.entityName}
                                </Link>
                            ):(null)}
                        </React.Fragment>
                    )
                },
            },
            
            {
                header: "Time",
                accessorKey: "issuedAt",
                enableColumnFilter: false,
                id: "attached",
                className: "col-3",
                cell: (cellProps) => {
                    return <ColTimeAgo {...cellProps} />;
                },
            },
            {
                header: "User",
                accessorKey: "requestingUser",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    if (!cellProps.getValue()) return ("System")
                    return cellProps.getValue().name
                },
            },
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-3 text-end",
                cell: (cellProps) => {
                    return <ColStatusBadge {...cellProps} />;
                },
            },
            
            
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'issuedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <GalliumPageColumn>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardHeader className=''>
                        <h4 className="card-title mb-0">{t("tasks.allTasks")}</h4>
                    </CardHeader>
                    <CardBody className="pt-0">
                        
                        <TableContainer
                            columns={columns}
                            data={hostCommands}
                            isGlobalFilter={true}
                            customPageSize={50}
                            sorting={sorting}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a Task'
                        />
                    </CardBody>
                </Card>    
            )}
        </GalliumPageColumn>
    )
};

export {HypervisorTasks, HypervisorTasksBadge}