import React from 'react';

import { Card, CardBody, CardHeader } from 'reactstrap';
import VirtualMachineActionDelete from './ActionDelete';
import { useParams } from 'react-router-dom';
import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const VirtualMachineActions = () => {
    const {t} = useTranslation()
    const {vmSlug} = useParams();
    const {data: virtualMachine, isLoading: isVirtualMachineLoading, error: virtualMachineError} = useGetVirtualMachine(vmSlug)

    if (isVirtualMachineLoading) return <Loader />;
    if (virtualMachineError) return <ErrorNotice />;

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.ACTIONS.title")} icon={t("virtualMachine.nav.ACTIONS.icon")}> 
            <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">
                        <FontAwesomeIcon icon={byPrefixAndName.fal["wrench"]} className="me-2"/>
                        {t("virtualMachine.actions.vmActions")}
                    </h4>       
                </CardHeader>
                <CardBody>
                    <VirtualMachineActionDelete virtualMachine={virtualMachine} />
                </CardBody>
            </Card>
        </GalliumThirdColumn>
    )
};

export default VirtualMachineActions;